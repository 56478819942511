import React, { useState, useEffect, useContext } from "react";
import { MediatorContext, ServerContext } from "../../../../../../App";
import { TCrew } from "../../../../../../services/server/types";
import { useGetUser } from "../../../../../../hooks";
import Crew from "./Crew/Crew";

import './SearchWindow.scss';

interface ISearchWindow {
    closeSearcherHandler: () => void;
}

type TCrewsList = {
    crews: TCrew[];
}

const SearchWindow: React.FC<ISearchWindow> = (props: ISearchWindow) => {
    const { closeSearcherHandler } = props;

    const [crews, setCrews] = useState<TCrew[]>(null!);
    const mediator = useContext(MediatorContext);
    const server = useContext(ServerContext);
    const { CREWS_LIST } = mediator.getEventTypes();
    const getUser = useGetUser(mediator);
    const user = getUser();

    let captainList: JSX.Element|JSX.Element[] = <></>;
    let tipInfo:JSX.Element = <></>;

    useEffect(() => {
        if (!crews) { server.crewsList(); } // подсосать список команд на спавн компоненты

        const crewsListHandler = (data: TCrewsList) => {
            const { crews } = data;
            setCrews(crews);
        }

        mediator.subscribe(CREWS_LIST, crewsListHandler);

        return () => {
            mediator.unsubscribe(CREWS_LIST, crewsListHandler);
        }
    });

    if (crews) {
        const otherCrews = crews.filter(crew => crew.captain.guid !== user?.guid);
        if (otherCrews.length > 0) {
            captainList = otherCrews.map((crew, index) => <Crew closeHandler={closeSearcherHandler} key={index} crew={crew}/>);
            tipInfo = <div className="popup-window__info">
                <span>Капитан</span>
                <span>Команда</span>
                <span>Вступить</span>
            </div>
        } else {
            captainList = <h3 className="crew-manager__empty-search">Никто пока не создал команду.</h3>
        }
    }

    return (
        <div className="crew-manager__popup-window">
            <h2>Поиск команды</h2>
            <button className="crew-manager__close-button" onClick={closeSearcherHandler} />
            <div className="popup-window__list">
                { tipInfo }
                { captainList }
            </div>
        </div>
    );
}

export default SearchWindow;