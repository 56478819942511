import React, { ReactNode } from "react";

import './InteractiveButton.scss';

export interface IInteractiveButton {
    classes?: string;
    svg: ReactNode;
    onClick: () => void;
    disabled?: boolean;
}

const InteractiveButton: React.FC<IInteractiveButton> = (props: IInteractiveButton) => {
    const { svg, classes = '', onClick } = props;

    return (
        <button
            className={`interactive-button ${classes}`}
            onClick={onClick}
        >
            {svg}
        </button>
    )
}

export default InteractiveButton;