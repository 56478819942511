import React from "react";
import UserInfo from "./UserInfo/UserInfo";
import CrewManager from "./CrewManager/CrewManager";

import './CrewInfo.scss';

const CrewInfo: React.FC = () => {
    return (
        <div className="lobby__party-list">
            <UserInfo />
            <CrewManager />
        </div>
    );
}

export default CrewInfo;