import React, { useContext, useState, useEffect } from "react";
import { MediatorContext } from "../../../App";
import { TSailor } from "../../../services/server/types";
import { useGetRank, useGetRating } from "../../../hooks";
import Popup from "../../Popup/Popup";

const LeaveSailorPopup: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const [message, setMessage] = useState<TSailor | null>(null);
    const { LEAVE_SAILOR_POPUP } = mediator.getEventTypes();
    const getRank = useGetRank(mediator);
    const getRating = useGetRating(mediator);

    useEffect(() => {
        const messagePopupHandler = (data: TSailor) => {
            setMessage(data);
        }

        mediator.subscribe(LEAVE_SAILOR_POPUP, messagePopupHandler);

        return () => {
            mediator.unsubscribe(LEAVE_SAILOR_POPUP, messagePopupHandler);
        }
    });

    // не показываем ничего
    if (!message) {
        return (<></>);
    }

    const { name, rank, rating } = message;

    return (<>
        <Popup 
            title={`Нас покинули!`}
            text={`${name} ${getRank(rank).name} ${getRating(rating).name} больше не часть корабля.`}
            classes="neutral neutral__sailor"
            timeout={5000}
            cb={() => setMessage(null)}
        />
    </>)
}

export default LeaveSailorPopup;