import CONFIG from "../config";
import { Mediator } from "../services/mediator";
import { TUser } from "../services/server/types";

const useGetUser = (mediator: Mediator): () => TUser | null => {
    const { USER } = CONFIG.STORAGE;
    const { GET_STORAGE } = mediator.getTriggerTypes();

    return () => mediator.get<TUser>(GET_STORAGE, USER);
}

export default useGetUser;