import React, { useEffect, useContext } from "react";
import CONFIG, { ECOMPARTMENT_TYPE, TCompartment } from "../../../config";
import { MediatorContext } from "../../../App";
import { EDAMAGE, TGameSubmarine, TSailor } from "../../../services/server/types";
import { useCanvas, Canvas } from "../../../services/canvas";
import { useGetRank, useGetUser } from "../../../hooks";
import { useSprites, useGetPersonSprite } from "../hooks";
import { UNSET_COLOR, EQUIPMENT_FREE_COLOR, EQUIPMENT_USED_COLOR, WATER_COLOR, } from "./consts";

const CANVAS_FIELD = 'interior-field';

const InteriorCanvas: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const { GET_SUBMARINE } = mediator.getTriggerTypes();
    const getRank = useGetRank(mediator);
    const getUser = useGetUser(mediator);
    const { SPRITE_SIZE, WINDOW, SUBMARINES, PERSON_WIDTH, COMPARTMENT_VOLUME, INTERIOR_HEIGHT } = CONFIG.GAME;

    const [
        img, // спрайты персонажей
        interior, // спрайты интерьеров
        [
            comp,
            compEmpty,
            react1,
            react2,
            ladder,
            middleDeckReact1,
            middleDeckReact2,
            middleDeckReact1Hole,
            openHatchLeft,
            openHatchRight,
            closeHatchLeft,
            closeHatchRight,
            middleHatch,
            controlPanel,
            firePanel2,
            controlPanel2,
            torpedoTube1,
            torpedoTube2,
            torpedoes1,
            torpedoes2,
            submarine1,
            submarine2,
        ], // координаты спрайтов интерьера
        [sailor, foreman, midshipman, lieutenant, captain, admiral, trusov], // координаты спрайтов персонажей
        [smallHole, mediumHole, bigHole, smallFire, mediumFire, bigFire], // координаты спрайтов дырок и пожаров
    ] = useSprites(SPRITE_SIZE); // инициализация карты спрайтов
    const getPersonSprite = useGetPersonSprite(getRank, sailor, foreman, midshipman, lieutenant, captain, admiral, trusov); // получение спрайтов персонажей по уровня юзера
    let canvas: Canvas | null = null;
    const getCanvas = useCanvas(render);

    // нарисовать отдельного персонажа
    function printPerson(canvas: Canvas, user: TSailor): void {
        const HALF = PERSON_WIDTH / 2;
        const X = user.x - HALF; // смещение задаётся, потому что Y перевёрнут, а по Х отсчёт от левой границы спрайта
        const Y = user.y + PERSON_WIDTH * 2; // PERSON_WIDTH * 2 = 1
        //canvas.rect(X, Y); // границы спрайта морячка
        //canvas.rectangle(X + HALF, Y - HALF, 32, 48); // размер спрайта 64 пикселя
        try {
            const userLocal = getUser(); // пользователь на ЭТОМ клиенте
            const sprite = getPersonSprite(user.guid === userLocal?.guid ? userLocal : user); // это чтобы обновлялась шкурка игрока в лодке динамически
            const [x, y, size] = sprite(user.guid, user.direction, user.status); // x,y это НЕ координаты персонажа
            canvas.sprite(img, X, Y, size, x, y, size);
        } catch (e) { 
            console.log(e);
        }
    }

    // отсентровать картинку по персонажу игрока
    function centerToUser(submarine: TGameSubmarine): void {
        const user = getUser();
        if (user) {
            const { guid } = user;
            if (submarine.captain.guid === guid) {
                const { x, y } = submarine.captain;
                WINDOW.LEFT = x - WINDOW.WIDTH / 2;
                WINDOW.BOTTOM = y - WINDOW.HEIGHT / 2;
                return;
            }
            for (let i = 0; i < submarine.sailors.length; i++) {
                if (submarine.sailors[i].guid === guid) {
                    const { x, y } = submarine.sailors[i];
                    WINDOW.LEFT = x - WINDOW.WIDTH / 2;
                    WINDOW.BOTTOM = y - WINDOW.HEIGHT / 2;
                    return;
                }
            }
        }
    }

    function printFillSprite(canvas: Canvas, { x = 0, y = 0 }, points: number[], dx = 0, dy = 0): void {
        canvas.spriteFull(interior, x + dx, y + dy, points[0], points[1], points[2], points[3]);
    }

    // 1. нарисовать совсем задний фон отсеков без палубы
    function printCompartments(canvas: Canvas, compartments: TCompartment[], submarine: TGameSubmarine): void {
        compartments.forEach(compartment => {
            if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT) {
                if (
                    (submarine.level === 1 && compartment.slot === 2) ||
                    (submarine.level === 2 && compartment.slot === 3)
                ) {
                    printFillSprite(canvas, compartment.points[0][3], compEmpty, -0.25, 0.25);
                } else {
                    printFillSprite(canvas, compartment.points[0][3], comp, -0.25, 0.25);
                }
            }
        });
    }

    // 2. нарисовать оборудование 
    function printEquipments(canvas: Canvas, compartments: TCompartment[], submarine: TGameSubmarine): void {
        compartments.forEach(compartment => {
            // рисуем оборудование
            if (compartment.equipment) {
                const equipment = submarine.equipments.find(equipment => equipment.slot === compartment.slot);
                if (equipment?.type === 'reactor' && submarine.level === 1) { // рисуем реактор 1 уровня
                    printFillSprite(canvas, compartment.equipment[3], react1);
                } else if (equipment?.type === 'reactor' && submarine.level === 2) { // рисуем реактор 2 уровня
                    printFillSprite(canvas, compartment.equipment[3], react2);
                } else if (equipment?.type === 'weapon' && submarine.level === 1) { // рисуем контрольную панель 1 уровня
                    printFillSprite(canvas, compartment.equipment[3], controlPanel);
                } else if (equipment?.type === 'weapon' && submarine.level === 2) { // рисуем оружейную панель 2 уровня
                    printFillSprite(canvas, compartment.equipment[3], firePanel2);
                } else if (equipment?.type === 'control' && submarine.level === 2) { // рисуем панель управления 2 уровня
                    printFillSprite(canvas, compartment.equipment[3], controlPanel2);
                } else { // рисуем кубик с оборудованием, если он есть
                    let color = UNSET_COLOR;
                    if (equipment?.isUsed) {
                        color = EQUIPMENT_USED_COLOR;
                    } else {
                        color = EQUIPMENT_FREE_COLOR;
                    }
                    canvas && canvas.polygon(compartment.equipment, color);
                }
            }
        });
    }

    // 2.1 нарисовать торпеды на стеллажах, чтобы лестницы их перекрывали 
    function printTorpedoes(canvas: Canvas, compartments: TCompartment[], submarine: TGameSubmarine): void {
        if (submarine.level === 1) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 1) {
                    printFillSprite(canvas, compartment.points[0][3], torpedoes1, 2.9, -1.32);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes1, 2.95, -1.37);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes1, 3.0, -1.42);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes1, 1.75, -1.32);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes1, 1.8, -1.37);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes1, 1.85, -1.42);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes1, 0.6, -1.32);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes1, 0.65, -1.37);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes1, 0.7, -1.42);
                }
            });
        }
        if (submarine.level === 2) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 1) {
                    printFillSprite(canvas, compartment.points[0][3], torpedoes2, 2.6, -1.4);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes2, 2.65, -1.45);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes2, 2.7, -1.5);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes2, 1.15, -1.4);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes2, 1.2, -1.45);
                    printFillSprite(canvas, compartment.points[0][3], torpedoes2, 1.25, -1.5);
                }
            });
        }
    }

    // 3. нарисовать лестницы
    function printLadders(canvas: Canvas, compartments: TCompartment[]): void {
        compartments.forEach(compartment => {
            if (compartment.type === ECOMPARTMENT_TYPE.LADDER) {
                compartment.points.forEach(points => {
                    printFillSprite(canvas, points[3], ladder, 0.06, -0.1);
                });
            }
        });
    }

    // 4. нарисовать палубу, которая между этажами отсека
    function printMiddleDeck(canvas: Canvas, compartments: TCompartment[], submarine: TGameSubmarine): void {
        if (submarine.level === 1) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 2) {
                    printFillSprite(canvas, compartment.points[0][3], middleDeckReact1, -0.05, -0.56);
                }
            });
        }
        if (submarine.level === 2) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 3) {
                    printFillSprite(canvas, compartment.points[0][3], middleDeckReact2, -0.05, -0.56);
                }
            });
        }
    }

    // 5. нарисовать персонажей, чтобы они ходили по ней
    function printCrew(canvas: Canvas, submarine: TGameSubmarine): void {
        // нарисовать остальную команду
        submarine.sailors.forEach(sailor => canvas && printPerson(canvas, sailor));
        // нарисовать капитана
        printPerson(canvas, submarine.captain);
    }

    // 6. нарисовать дырку в палубе, чтобы перекрывали персонажа
    function printMiddleDeckHole(canvas: Canvas, compartments: TCompartment[], submarine: TGameSubmarine): void {
        if (submarine.level === 1) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 2) {
                    printFillSprite(canvas, compartment.points[0][3], middleDeckReact1Hole, 3, -0.78);
                }
            });
        }
        if (submarine.level === 2) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 3) {
                    printFillSprite(canvas, compartment.points[0][3], middleDeckReact1Hole, 3, -0.78);
                }
            });
        }
    }

    // 4.1 нарисовать люки, чтобы персонаж их перекрывал
    function printHatches(canvas: Canvas, compartments: TCompartment[]): void {
        compartments.forEach(compartment => {
            if (compartment.type === ECOMPARTMENT_TYPE.HATCH) {
                const left = compartment.open ? openHatchLeft : closeHatchLeft;
                const right = compartment.open ? openHatchRight : closeHatchRight;
                printFillSprite(canvas, compartment.points[0][3], left, -0.65, 0.1);
                printFillSprite(canvas, compartment.points[0][3], right, 0.5, 0.1);
            }
        });
    }

    // 7. нарисовать перегородку люков, чтобы она перекрывала персонажа
    function printMiddleHatches(canvas: Canvas, compartments: TCompartment[]): void {
        compartments.forEach(compartment => {
            if (compartment.type === ECOMPARTMENT_TYPE.HATCH) {
                printFillSprite(canvas, compartment.points[0][3], middleHatch, 0.05, 0.39);
            }
        });
    }

    // 7.1 нарисовать торпедные аппараты, чтобы они перекрывали персонажа
    function printTorpedoTubes(canvas: Canvas, compartments: TCompartment[], submarine: TGameSubmarine): void {
        if (submarine.level === 1) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 1) {
                    printFillSprite(canvas, compartment.points[0][3], torpedoTube1, 3.25, -1.45);
                }
            });
        }
        if (submarine.level === 2) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 1) {
                    printFillSprite(canvas, compartment.points[0][3], torpedoTube2, 2.7, -1.52);
                }
            });
        }
    }

    // 8. нарисовать повреждения (и водичку)
    function printDamages(canvas: Canvas, compartments: TCompartment[], submarine: TGameSubmarine): void {
        // рисуем повреждения
        submarine.damages.forEach(damage => {
            const { id, x, y, value, type } = damage;
            if (canvas && value > 0) {
                if (type === EDAMAGE.HOLE) {
                    if (value < 50) {
                        printFillSprite(canvas, { x, y }, smallHole(String(id)), -0.25, 0.15);
                    } else if (value < 100) {
                        printFillSprite(canvas, { x, y }, mediumHole(String(id)), -0.25, 0.15);
                    } else {
                        printFillSprite(canvas, { x, y }, bigHole(String(id)), -0.5, 0.52);
                    }
                }
                if (type === EDAMAGE.FIRE) {
                    if (value < 50) {
                        printFillSprite(canvas, { x, y }, smallFire(String(id)), -0.35, 0.65);
                    } else if (value < 80) {
                        printFillSprite(canvas, { x, y }, mediumFire(String(id)), -0.55, 0.65);
                    } else {
                        printFillSprite(canvas, { x, y }, bigFire(String(id)), -0.6, 1.25);
                    }
                }
            }
        });
        compartments.forEach(compartment => {
            // если в отсеке водичка
            if (compartment.water) {
                const x1 = compartment.points[0][0].x;
                const x2 = compartment.points[0][1].x;
                const y1 = compartment.points[1][0].y;
                const y = INTERIOR_HEIGHT;
                const y2 = y1 + y * compartment.water / COMPARTMENT_VOLUME;
                const points = [
                    { x: x1, y: y1 },
                    { x: x1, y: y2 },
                    { x: x2, y: y2 },
                    { x: x2, y: y1 },
                ];
                canvas && canvas.polygon(points, WATER_COLOR);
            }
        });
    }

    // 9. нарисовать корпус лодки, чтобы она вообще всё перекрывала
    function printSubmarine(canvas: Canvas, compartments: TCompartment[], submarine: TGameSubmarine): void {
        if (submarine.level === 1) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 1) {
                    printFillSprite(canvas, compartment.points[0][3], submarine1, -10.66, 1.72);
                }
            });
        }
        if (submarine.level === 2) {
            compartments.forEach(compartment => {
                if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT && compartment.slot === 1) {
                    printFillSprite(canvas, compartment.points[0][3], submarine2, -15.77, 1.72);
                }
            });
        }

    }

    // функция отрисовки одного кардра интерьера
    function render(fps: number): void {
        if (canvas) {
            canvas.clear(); // очищаем экран
            canvas.text(WINDOW.LEFT + 0.5, WINDOW.BOTTOM + 0.5, `${fps}`); // выводим ФПС-ы
            // рисуем подводную лодку вместе со всеми внутренностями
            const submarine = mediator.get<TGameSubmarine>(GET_SUBMARINE);
            if (submarine) {
                centerToUser(submarine); // центрирование экрана на игроке
                // @ts-ignore
                const compartments: TCompartment[] = SUBMARINES[submarine.level]; // отсеки лодки
                printCompartments(canvas, compartments, submarine); // 1. нарисовать совсем задний фон отсеков без палубы
                printEquipments(canvas, compartments, submarine); // 2. нарисовать оборудование 
                printTorpedoes(canvas, compartments, submarine); // 2.1 нарисовать торпеды на стеллажах, чтобы лестницы их перекрывали
                printLadders(canvas, compartments); // 3. нарисовать лестницы
                printMiddleDeck(canvas, compartments, submarine); // 4. нарисовать палубу, которая между этажами отсека
                printHatches(canvas, compartments); // 4.1 нарисовать люки, чтобы персонаж их перекрывал
                printCrew(canvas, submarine); // 5. нарисовать персонажей, чтобы они ходили по ней
                printMiddleDeckHole(canvas, compartments, submarine); // 6. нарисовать дырку в палубе, чтобы перекрывали персонажа
                printMiddleHatches(canvas, compartments); // 7. нарисовать перегородку люков, чтобы она перекрывала персонажа
                printTorpedoTubes(canvas, compartments, submarine); // 7.1 нарисовать торпедные аппараты, чтобы они перекрывали персонажа
                printDamages(canvas, compartments, submarine); // 8. нарисовать повреждения (и водичку)
                printSubmarine(canvas, compartments, submarine); // 9. нарисовать корпус лодки, чтобы она вообще всё перекрывала
            }
            canvas.render(); // кладём картинку на экран
        }
    }

    // ресайзим канвас
    function resize(): void {
        canvas?.resizeCanvas();
    }

    useEffect(() => {
        canvas = getCanvas({ parentId: CANVAS_FIELD, WINDOW, SIZE: SPRITE_SIZE });

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
            canvas?.destructor();
            canvas = null;
        }
    });

    return (<>
        <div id={CANVAS_FIELD}></div>
    </>)
}

export default InteriorCanvas;