import React, { useState, useContext } from "react";
import { MediatorContext, ServerContext } from "../../../../../App";
import CONFIG from "../../../../../config";
import { TCrewStatus } from "../CrewManageButton/CrewManageButton";
import SearchWindow from "./SearchWindow/SearchWindow";

const CrewSearch: React.FC = () => {
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const mediator = useContext(MediatorContext);
    const server = useContext(ServerContext);
    const { CLEAR_CREW_LIST } = mediator.getEventTypes();
    const { GET_STORAGE, SET_STORAGE } = mediator.getTriggerTypes();
    const { CREW_STATUS, IS_CREW_OWNER } = CONFIG.STORAGE;

    // открыть окно поиска команды
    const openSearcherHandler = () => { // на открытие ливает из текущей и распускает, если был кэпом (ибо нехер!)
        const crew = mediator.get<TCrewStatus>(GET_STORAGE, CREW_STATUS);
        if (crew && crew.isInCrew && crew.crewId) { // ливнуть, если в команде
            server.leaveCrew({ crewId: crew.crewId });
            mediator.get<TCrewStatus>(SET_STORAGE, { NAME: CREW_STATUS, value: { isInCrew: false, crewId: null } });
            mediator.call(CLEAR_CREW_LIST, '');
        }
        const isCrewOwner = mediator.get<boolean>(GET_STORAGE, IS_CREW_OWNER);
        if (isCrewOwner) { // дропнуть свою команду
            server.dropCrew();
        }
        return !isSearching ? setIsSearching(true) : null;
    };

    // открыть окно поиска команды
    const closeSearcherHandler = () => isSearching ? setIsSearching(false) : null;

    const searchWindow = <SearchWindow closeSearcherHandler={closeSearcherHandler}/>;

    return (<>
        <button 
            className="crew-manager__manage-button"
            onClick={openSearcherHandler}
        >
            Найти команду
        </button>
        { isSearching && searchWindow }
    </>);
}

export default CrewSearch;