import React, { useEffect, useContext, useState } from "react";
import { MediatorContext } from "../../../App";
import { TGameSubmarine, TEquipment, ESTATUS } from "../../../services/server/types";
import { useGetUser } from "../../../hooks";
import MovePanel from "./MovePanel/MovePanel";
import Submarine1FullControlPanel from "./Submarine1FullControlPanel/Submarine1FullControlPanel";
import Submarine2ControlPanel from "./Submarine2ControlPanel/Submarine2ControlPanel";
import Submarine2FirePanel from "./Submarine2FirePanel/Submarine2FirePanel";

import './ControlPanel.scss';

type TEquipmentData = {
    guid: string;
    equipmentId: number;
}

export type TSubmarineControlPanel = {
    submarine: TGameSubmarine;
    equipment: TEquipment;
}

const ControlPanel: React.FC = () => {
    const [equipmentId, setEquipmentId] = useState<number | null>(null);
    const mediator = useContext(MediatorContext);
    const { USE_EQUIPMENT_START, USE_EQUIPMENT_STOP, USER_SET_DIRECTION_STATUS, USER_TORPEDO_DETONATION } = mediator.getEventTypes();
    const { GET_SUBMARINE } = mediator.getTriggerTypes();
    const getUser = useGetUser(mediator);

    useEffect(() => {
        const user = getUser();

        const equipmentStartHandler = (data: TEquipmentData): void => {
            const { equipmentId, guid } = data;
            if (guid === user?.guid) { // Я сам встал за оборудование
                setEquipmentId(equipmentId);
                return;
            }
        }
        const equipmentStopHandler = (data: TEquipmentData): void => {
            const { guid } = data;
            if (guid === user?.guid) { // Я сам вышел из-за оборудование
                setEquipmentId(null);
            }
        }

        mediator.subscribe(USE_EQUIPMENT_START, (data: TEquipmentData) => equipmentStartHandler(data));
        mediator.subscribe(USE_EQUIPMENT_STOP, (data: TEquipmentData) => equipmentStopHandler(data));

        // TODO Выпилить! Только для разработки!!!
        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.keyCode === 69) { // e
                mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.USE });
            }
        }
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            mediator.unsubscribe(USE_EQUIPMENT_START, equipmentStartHandler);
            mediator.unsubscribe(USE_EQUIPMENT_STOP, equipmentStopHandler);
            mediator.call(USER_TORPEDO_DETONATION);

            document.removeEventListener('keydown', keyDownHandler);
        }
    });

    const submarine = mediator.get<TGameSubmarine>(GET_SUBMARINE);
    const equipment = submarine?.equipments.find(equipment => equipment.id === equipmentId);

    if (!equipmentId || !submarine) {
        return (<MovePanel />);
    }

    // не очень красивое решение, зато позволяет избежать миллиона вопросов
    if (!equipment) {
        return (<MovePanel />);
    }

    return (<div className="game__control-panel">
        {equipment.level === 1 && equipment.type === 'weapon' &&
            <Submarine1FullControlPanel submarine={submarine} equipment={equipment}/>
        }
        {equipment.level === 2 ?
            equipment.type === 'weapon' ?
                <Submarine2FirePanel submarine={submarine} equipment={equipment}/> :
                <Submarine2ControlPanel submarine={submarine} equipment={equipment}/> :
            <></>
        }
    </div>)
}

export default ControlPanel;