import { TSailor } from "../../../services/server/types";
import { TFrames } from "../hooks/useSprites";

const useGetPersonSprite = (
    getRank: (rank: number) => { no: number },
    sailor: TFrames,
    foreman: TFrames,
    midshipman: TFrames,
    lieutenant: TFrames,
    captain: TFrames,
    admiral: TFrames,
    trusov: TFrames,
) => {
    return (user: TSailor): TFrames => {
        const rank = getRank(user.rank);
        // данные набираем из порядка чередования уровней
        if ([1, 2].includes(rank.no)) return sailor;
        if ([3, 4, 5, 6].includes(rank.no)) return foreman;
        if ([7, 8].includes(rank.no)) return midshipman;
        if ([9, 10, 11].includes(rank.no)) return lieutenant;
        if ([12, 13, 14, 15].includes(rank.no)) return captain;
        if ([16, 17, 18, 19, 20].includes(rank.no)) return admiral;
        return trusov;
    }
}

export default useGetPersonSprite;