import React from "react";
import { useIsMouseEvent, useIsSafari } from '../hooks';
import MoveStick from "./MoveStick/MoveStick";
import MovePad from "./MovePad/MovePad";

import './Joystick.scss';

export interface IMove {
    upHandler: () => void;
    downHandler: () => void;
    leftHandler: () => void;
    rightHandler: () => void;
    stopHandler: () => void;
}

interface IJoystick extends IMove {
    hide?: boolean;
    classes?: string;
}

const Joystick: React.FC<IJoystick> = (props: IJoystick) => {
    const { hide = false, classes = '', upHandler, downHandler, leftHandler, rightHandler, stopHandler } = props;

    const isMouseEvent = useIsMouseEvent();
    const isSafari = useIsSafari();

    const onTouch = (e: React.TouchEvent<HTMLDivElement>) => {
        if (isMouseEvent(e)) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    // чтобы глаза не мозолил
    if (hide) {
        return (<></>);
    }

    return (
        <div
            onTouchStart={onTouch}
            onTouchEnd={onTouch}
            onTouchMove={onTouch}
            className={"joystickContentContainer " + classes}
        >
            <div className="shotBtnWrapper">
                <div className={`moveStickContainer${isSafari ? " safariFix" : ""}`}>
                    { isSafari
                        ? <MovePad
                            upHandler={upHandler}
                            downHandler={downHandler}
                            leftHandler={leftHandler}
                            rightHandler={rightHandler}
                            stopHandler={stopHandler}
                        />
                        : <MoveStick
                            upHandler={upHandler}
                            downHandler={downHandler}
                            leftHandler={leftHandler}
                            rightHandler={rightHandler}
                            stopHandler={stopHandler}
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default Joystick;