import React, { useEffect, useContext, useState } from "react";
import { MediatorContext } from "../../../../../App";
import { ESTATUS, TDamage } from "../../../../../services/server/types";
import InteractiveButton from "../../../../../components/InteractiveButton/InteractiveButton";
import { SteeringWheel, Repair, Hatch }from "../../../../../components/svg";

import './UsePanel.scss';

type TUserCanRepair = {
    canRepair: boolean;
    repair?: TDamage;
}

const UsePanel: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const { USER_SET_DIRECTION_STATUS, USER_CAN_REPAIR } = mediator.getEventTypes();
    const [repair, setRepair] =useState<TDamage | undefined>();

    useEffect(() => {
        const userCanRepairHandler = (data: TUserCanRepair): void => {
            const { canRepair, repair } = data;
            setRepair(canRepair ? repair : undefined);
        };

        mediator.subscribe(USER_CAN_REPAIR, userCanRepairHandler);

        return () => {
            mediator.unsubscribe(USER_CAN_REPAIR, userCanRepairHandler);
        }
    });

    const stopHandler = () => mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.STAND });
    // хендлеры действий
    const useHandler = () => mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.USE });
    const openCloseHandler = () => {
        mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.CLOSE });
        setTimeout(stopHandler, 300);
    }
    const fixHandler = () => mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.USE, repair });

    return (<>
        <div className="usePanel">
            {repair && <InteractiveButton svg={<Repair/>} classes={'interactive-button_platformer-typed'} onClick={fixHandler}/>}
            <InteractiveButton svg={<Hatch/>} classes={'interactive-button_platformer-typed'} onClick={openCloseHandler}/>
            <InteractiveButton svg={<SteeringWheel/>} classes={'interactive-button_platformer-typed'} onClick={useHandler}/>
        </div>
    </>)
}

export default UsePanel;


