import React, { useContext, useState } from "react";
import { MediatorContext } from "../../../../App";
import CONFIG from "../../../../config";

import './FiringModule.scss';

interface IAmmo {
    shotTorpedoHandler: () => void;
    ammo: number;
    totalAmmo: number;
}

interface IFiringModule {
    initAmmo: number
    isFireModule: boolean
}

const Ammo: React.FC<IAmmo> = (props: IAmmo) => {
    const { 
        shotTorpedoHandler,
        ammo,
        totalAmmo
    } = props;

    const angleStep = 360 / totalAmmo;

    const createSegments = () => {
        const segmentArray = [];

        for (let i = 0; i < totalAmmo; i++) {
            const style = { 
                transform: `rotateZ(${i * 360 / totalAmmo}deg)`,
                height: `calc(${angleStep}% - ${angleStep/8}px)`
            }
            if (i < ammo) {
                segmentArray.push(
                    <div
                        className="firing-module__button_segment button-segment_active"
                        style={style}
                        key={i}
                    />
                );
            } else {
                segmentArray.push(
                    <div
                        className="firing-module__button_segment"
                        style={style}
                        key={i}
                    />
                );
            }
        }

        return segmentArray;
    }

    return (
        <div className="firing-module__button_wrapper" >
            { createSegments() }
            <button 
                className="firing-module__button contorl-panel__fire-torpedo-button"
                onClick={shotTorpedoHandler}
            />
        </div>
    );
}

const FiringModule: React.FC<IFiringModule> = (props: IFiringModule) => {
    const { initAmmo } = props;
    const mediator = useContext(MediatorContext);
    const [ammo, setAmmo] = useState<number>(initAmmo);

    const { AMMO_AMOUNT } = CONFIG.STORAGE;
    const { USER_SHOT_TORPEDO, USER_TORPEDO_DETONATION } = mediator.getEventTypes();
    const { GET_STORAGE } = mediator.getTriggerTypes();
    
    const totalSegments = mediator.get(GET_STORAGE, AMMO_AMOUNT) as number;

    const detonateTorpedoHandler = () => mediator.call(USER_TORPEDO_DETONATION);
    const shotTorpedoHandler = () => {
        if (ammo > 0) {
            mediator.call(USER_SHOT_TORPEDO);
            setAmmo(ammo - 1);
        }
    };

    return (
        <div className="contorl-panel__firing-module">
            <span>{ ammo }</span>
            <button 
                className="firing-module__button contorl-panel__detonate-torpedo-button"
                onClick={detonateTorpedoHandler}
            />
            <Ammo 
                shotTorpedoHandler={shotTorpedoHandler} 
                ammo={ammo}
                totalAmmo={totalSegments}
            />
        </div>
    );
}

export default FiringModule;