import CONFIG from "../config";
import { Mediator } from "../services/mediator";
import useGetStat from "./useGetStat";

import { TStat } from "../services/server/types";

const useGetRank = (mediator: Mediator) => {
    const getStat = useGetStat();

    const { RANKS } = CONFIG.STORAGE;
    const { GET_STORAGE } = mediator.getTriggerTypes();
    const ranks = mediator.get<Array<TStat>>(GET_STORAGE, RANKS) || [];

    return (rank: number) => getStat(ranks, rank);
}

export default useGetRank;