import { TCanvasPoint } from "../services/three/index";
import { TGameSubmarine, TTorpedo } from "../services/server/types";
import CONFIG from "../config";
import usePointRotation from "./useRotatePoint";

export default function useCalculateCoordinates() {
    const rotatePoint = usePointRotation();
    const { BASE } = CONFIG.GAME;
    return (
        base: typeof BASE,
        object: TGameSubmarine|TTorpedo,  
        range: number
    ):TCanvasPoint & {intersectionPlane:number} | null  => {
        const baseRadius = BASE.r;
        const deltaX = base.x - object.x;
        const deltaY = base.y - object.y;
        const deltaZ = base.z - object.z;

        // расстояние между центрами
        const distance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2) + Math.pow(deltaZ, 2));

        const totalRadius = Number(range) + baseRadius;

        // если радиусы не пересекаются
        if (distance > totalRadius) {
            return null;
        }

        const gamma = -object.course / 180 * Math.PI // поврот вокруг оси Z

        // повернутая точка
        const deltaRotated = rotatePoint({
            x: deltaX,
            y: deltaY,
            z: deltaZ
        }, { 
            angleZ: gamma,
        });

        const zDistance = Math.abs(deltaZ);

        // радиус секущей плоскости
        let radius = 0;
        if (zDistance <= baseRadius) {
            radius = Math.sqrt(Math.pow(baseRadius, 2) - Math.pow(zDistance, 2));
        }

        return {
            x: deltaRotated.x,
            y: deltaRotated.y,
            z: deltaRotated.z,
            intersectionPlane: radius
        }
    }
}