import React, { useContext, useEffect, useState } from "react";
import { MediatorContext } from "../../../../../App";
import { useGetUser } from "../../../../../hooks";
import { TCrew } from "../../../../../services/server/types";
import CrewListWide from "./CrewListWide/CrewListWide";
import Sailor from "./Sailor/Sailor";

import './CrewList.scss';

const CrewList: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const [crew, setCrew] = useState<TCrew | null>(null!);
    const [isCrewWide, setIsCrewWide] = useState<boolean>(false);
    const { CREATE_CREW, DROP_CREW, JOIN_TO_CREW, LEAVE_CREW, DROP_FROM_CREW, CLEAR_CREW_LIST } = mediator.getEventTypes();
    const getUser = useGetUser(mediator);
    const user = getUser();

    useEffect(() => {
        const createCrewHandler = (data: TCrew) => setCrew(data);
        const dropCrewHandler = () => setCrew(null);
        const joinToCrewHandler = (data: TCrew) => setCrew(data);
        const leaveCrewHandler = (data: TCrew) => setCrew(data);
        const dropFromCrewHandler = (data: TCrew) => {
            const { guid, sailors } = data;
            if (user?.guid === guid) { // значит это МОЯ команда
                setCrew(data); // обновить список команды
                return;
            }
            if (sailors.find(sailor => sailor.guid === user?.guid)) { // Значит МЕНЯ НЕ выкинули из команды
                setCrew(data);
                return;
            }
            setCrew(null);
        };
        const clearCrewListHandler = () => setCrew(null);

        mediator.subscribe(CREATE_CREW, createCrewHandler);
        mediator.subscribe(DROP_CREW, dropCrewHandler);
        mediator.subscribe(JOIN_TO_CREW, joinToCrewHandler);
        mediator.subscribe(LEAVE_CREW, leaveCrewHandler);
        mediator.subscribe(DROP_FROM_CREW, dropFromCrewHandler);
        mediator.subscribe(CLEAR_CREW_LIST, clearCrewListHandler);

        return () => {
            mediator.unsubscribe(CREATE_CREW, createCrewHandler);
            mediator.unsubscribe(DROP_CREW, dropCrewHandler);
            mediator.unsubscribe(JOIN_TO_CREW, joinToCrewHandler);
            mediator.unsubscribe(LEAVE_CREW, leaveCrewHandler);
            mediator.unsubscribe(CLEAR_CREW_LIST, clearCrewListHandler);
        }
    });

    const openWideCrewList = () => !isCrewWide ? setIsCrewWide(true) : null;
    const closeWideCrewList = () => isCrewWide ? setIsCrewWide(false) : null;

    if(!crew) return <></>;

    const { captain, sailors } = crew;

    const crewCaptain = <Sailor user={user} sailor={captain} isCaptain={true}/>;
    const crewSailors = sailors.map((sailor, index) => <Sailor user={user} key={index} sailor={sailor}/>);
    const widerList = isCrewWide && <CrewListWide user={user} crew={crew} closeWideListHandler={closeWideCrewList}/>;

    return (<>
        <div 
            className="crew-manager__crew-list"
            onClick={openWideCrewList}
        >
            { crewCaptain }
            { crewSailors }
        </div>
        { widerList }
    </>);
}

export default CrewList;