import React, { useContext, useState, useEffect } from "react";
import { MediatorContext } from "../../../App";
import { TERROR } from "../../../services/server";
import Popup from "../../Popup/Popup";

const ErrorPopup: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const [message, setMessage] = useState<TERROR | null>(null);
    const { SOCKET_ON_ERROR, REACT_SHOW_ERROR_POPUP } = mediator.getEventTypes();

    useEffect(() => {
        const socketOnErrorHandler = (data: TERROR) => {
            setMessage(data);
        };

        mediator.subscribe(SOCKET_ON_ERROR, socketOnErrorHandler);
        mediator.subscribe(REACT_SHOW_ERROR_POPUP, socketOnErrorHandler);

        return () => {
            mediator.unsubscribe(SOCKET_ON_ERROR, socketOnErrorHandler);
            mediator.unsubscribe(REACT_SHOW_ERROR_POPUP, socketOnErrorHandler);
        }
    });

    // не показываем ничего
    if (!message) {
        return (<></>);
    }

    const { code, text } = message;

    return (<>
        <Popup 
            title={`Ошибка №${code}`}
            text={text}
            timeout={3000}
            classes="warning"
            cb={() => setMessage(null)}
        />
    </>)
}

export default ErrorPopup;