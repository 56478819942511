import React from "react";

const Hatch: React.FC = () => {
    return (
        <svg id="hatch" fill="none" viewBox="0 0 350 350">
            <path fill="currentColor" d="M176.4 350A175.6 175.6 0 0 1 0 173.5 174.9 174.9 0 0 1 176.4 0 174.6 174.6 0 0 1 350 173.5 175 175 0 0 1 176.4 350Zm-1-343.2c-2.7 0-4.8 2.5-4.8 5.3.1 2.7 2.2 5 4.9 5s4.8-2.3 4.8-5c.1-2.8-2-5.3-4.8-5.3Zm-2.7 27a140.6 140.6 0 0 0-138 128.5c-.7 0-1.7-.1-2.3.4-.3.3-.5.7-.5 1v22.6c0 .7 0 1 .6 1.5.6.6 1.5.6 2.2.6-.1-1 0 1.2 0 0l1 10a141.4 141.4 0 0 0 29.3 66 141.5 141.5 0 0 0 129.4 50.4 143 143 0 0 0 68.7-29 139.8 139.8 0 0 0 53-97.4h2.7V162H316A141.6 141.6 0 0 0 172.7 33.8ZM61 54.8a5.7 5.7 0 0 0-5 8.7 5.6 5.6 0 1 0 5-8.6Zm230 1.4a4.4 4.4 0 0 0-4.4 4.3c0 2.4 2 4.3 4.5 4.3 2.4 0 4.4-1.9 4.4-4.3s-2-4.3-4.4-4.3ZM14 169a5 5 0 1 0 0 10.1 5 5 0 0 0 0-10Zm323 .4c-2.6 0-4.6 2.4-4.6 5 0 2.8 2 5 4.6 5 2.7 0 4.7-2.2 4.7-5 0-2.6-2-5-4.7-5ZM60.5 283.7a5 5 0 1 0 0 10.1 5 5 0 0 0 0-10Zm230.7-.4c-2.9 0-5 2.6-5 5.5s2.1 5.4 5 5.4 5-2.5 5-5.4c0-2.9-2.1-5.5-5-5.5Zm-115.6 48.9c-2.6 0-4.7 2.3-4.6 5 .1 2.6 2 4.7 4.6 4.7 2.6 0 4.6-2.1 4.7-4.7.1-2.7-2-5-4.7-5Z"/>
            <path fill="currentColor" d="M175.7 14.4a2.3 2.3 0 1 1 0-4.7 2.3 2.3 0 0 1 0 4.7Zm5.9 37.1c-2.4-.2-4.7-.1-7-.1a123 123 0 0 0-116.6 84c-3 8.9-4.6 17.9-6 27H38.2c.1-6.6 1.5-13.5 3.1-19.9A136.5 136.5 0 0 1 168.9 37.4 138.4 138.4 0 0 1 312.5 162h-14a125.7 125.7 0 0 0-31.8-70.8 124.1 124.1 0 0 0-85.1-39.7Z"/>
            <path fill="currentColor" d="M129.6 152.4V63.7c12.8-5.5 30.3-8.4 44.2-9v60.1l-44.2 37.6ZM208.8 95l-32.4 18.6v-59c12.3-1 32.4 19.5 32.4 19.5V95Zm-148-32a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Zm230.2-.2a2.3 2.3 0 1 1 0-4.6 2.3 2.3 0 0 1 0 4.6Zm-203.4 118V92.5c9.6-9.6 26.8-22.5 39.4-27.7V154l-39.4 26.8Zm127.3-70.7-3.5 2.4V89.6l3.5 3.5v17Zm-38.5 59.3v-51.3l32.4-20.6V137l-32.4 32.4ZM56.7 198.1l-1-6.7c-.1-1-.4-2-.3-2.9.3 0 .7-.1 1-.3.4-.2.6-1 .6-1v-24.4c-.4-1.2-1.1-.3-1.7-1 1-5.4 1.8-10.8 3-16A116.9 116.9 0 0 1 85 95.5v87.3c-2 1.7-26.4 18.4-27.6 18.7l-.6-3.4ZM215 135.3l-3.5.8v-21.3l3.5-2.4v22.9Zm-.8 94.1-3.5-2.3v23l3.5-3.6v-17Zm0-25.1-3.5-.8v21.3l3.5 2.3v-22.8Zm-84.6-48 44-36.9v50.4l-44 35.3v-48.8Zm80.9 43.1 1-59.5 6.3-1.7-.7 59.3-6.6 2Zm-34 19.6v-45.7l32.2-32.6-1.1 59.1-31 19.2Zm-88.9-34.3L127 158v49l-39.4 27.2v-49.4Zm227.8.2h-16.7v-19.4h16.7V185Zm-261.6-.4H35V166h18.8v18.7Zm-39.7-8c-1.3 0-2.4-1-2.3-2.4a2.3 2.3 0 1 1 4.6 0 2.4 2.4 0 0 1-2.3 2.5Zm323 .3a2.3 2.3 0 1 1 0-4.7 2.3 2.3 0 0 1 0 4.7Zm-207.5 82.1v-50.1L173 175v45l-43.5 38.8ZM69 233c-4.8-9-8.5-18.5-10.8-28.4l26.7-17.8v49.5c-3.3 1.9-6.6 4.5-9.8 6.8L69 232.9Z"/>
            <path fill="currentColor" d="M310.6 202a133.4 133.4 0 0 1-14.7 40.2A139.2 139.2 0 0 1 106.7 295c-16-9-30.2-21.2-41.5-35.8a136.6 136.6 0 0 1-26.9-67.7c0-.8-.4-2.6 0-3.2h13.6a117.7 117.7 0 0 0 26 63.9 126 126 0 0 0 84.7 46 126.3 126.3 0 0 0 92.8-28.6 113 113 0 0 0 16.5-17c7.3-9 13.5-19 18-29.7 4.4-11 7.3-22.5 8.3-34.4h14.3l-2 13.6Z"/>
            <path fill="currentColor" d="M175.7 290.7v-67l31.8-20.2v56.2l-31.8 31Zm-88.3-32.9v-20l39.4-26.6.1 49.7-19.7 13.6-19.8-16.7Zm48.7 31-6.7-2.5.1-23.5 43.5-38.4v66.3c-12.8 0-24.8 2.2-37-2ZM85 255l-8.2-9.7 8.2-5.8V255Zm24.8 21 17-10.9v20.2l-17-9.3Zm181.4 15a2.3 2.3 0 1 1 0-4.6 2.3 2.3 0 0 1 0 4.7Zm-230.7 0a2.3 2.3 0 1 1 0-4.6 2.3 2.3 0 0 1 0 4.7Zm115.2 48.5a2.3 2.3 0 1 1 0-4.6 2.3 2.3 0 0 1 0 4.6Z"/>
        </svg>
    );
}

export default Hatch;