import CONFIG, { ECOMPARTMENT_TYPE, TCompartment } from "../../config";
import { ESTATUS, EDAMAGE, TSailor, TDamage, TGameSubmarine } from "../../services/server/types";
import { TInteriorGame } from "../InteriorGame";
import BaseInterior, { TDirectionStatus } from "../BaseInterior";

export type TFiringTorpedo = {
    courseSteer: number;
    pitchSteer: number;
}

export default class Equipment extends BaseInterior {
    PERSON_WIDTH: number;
    PERSON_HEIGHT: number;
    compartments: TCompartment[];
    equipmentId: number | null = null;
    repairId: number | null = null; // идентификатор чинимого повреждения
    STEER_STEP: number;
    ACCELERATOR_STEP: number;
    DAMAGE_GROW_SPEED: number;
    DAMAGE_FIX_SPEED: number;
    COMPARTMENT_VOLUME: number;
    MAX_DAMAGE_GROW: number;
    WATER_FLOW_SPEED: number;
    getMaxSpeed: (consumption: number) => number;
    getMaxSteeringAngle: (level: number) => number;
    getChanceOfFire: (damage: number) => boolean;
    getWater: (damage: number) => number;
    removeWater: () => number;
    getMaxDepth: (level: number) => number;
    getChanceDepthDamage: (depth: number, maxDepth: number) => boolean;
    FIX_COUNT: number;
    damageFixCount = 0;

    torpedo: TFiringTorpedo;
    emptyWeight: number = 1; // вес пустой субмарины

    constructor(options: TInteriorGame) {
        super(options);
        const { mediator, submarine } = options;
        const { GAME } = CONFIG;
        this.submarine = submarine;
        const {
            INTERIOR_TIMESTAMP,
            SUBMARINES,
            PERSON_WIDTH,
            PERSON_HEIGHT,
            ACCELERATOR_STEP,
            STEER_STEP,
            getMaxSpeed,
            getMaxSteeringAngle,
            DAMAGE_GROW_SPEED,
            DAMAGE_FIX_SPEED,
            getChanceOfFire,
            COMPARTMENT_WEIGHT,
            COMPARTMENT_VOLUME,
            MAX_DAMAGE_GROW,    
            WATER_FLOW_SPEED,
            getWater,
            removeWater,
            getMaxDepth,
            getChanceDepthDamage,
        } = GAME;
        this.PERSON_WIDTH = PERSON_WIDTH;
        this.PERSON_HEIGHT = PERSON_HEIGHT;
        this.STEER_STEP = STEER_STEP;
        this.ACCELERATOR_STEP = ACCELERATOR_STEP;
        this.DAMAGE_GROW_SPEED = DAMAGE_GROW_SPEED;
        this.MAX_DAMAGE_GROW = MAX_DAMAGE_GROW;
        this.DAMAGE_FIX_SPEED = DAMAGE_FIX_SPEED;
        this.COMPARTMENT_VOLUME = COMPARTMENT_VOLUME;
        this.WATER_FLOW_SPEED = WATER_FLOW_SPEED;
        this.getMaxSpeed = getMaxSpeed;
        this.getMaxSteeringAngle = getMaxSteeringAngle;
        this.getChanceOfFire = getChanceOfFire;
        this.getWater = getWater;
        this.removeWater = removeWater;
        this.getMaxDepth = getMaxDepth;
        this.getChanceDepthDamage = getChanceDepthDamage;
        this.FIX_COUNT = Math.floor(1000 / INTERIOR_TIMESTAMP);
        // @ts-ignore
        this.compartments = SUBMARINES[submarine.level];

        const {
            USER_SET_DIRECTION_STATUS,
            USE_EQUIPMENT_START,
            USE_EQUIPMENT_STOP,
            USER_SET_ACCELERATOR,
            SET_ACCELERATOR,
            USER_SET_COURSE_STEER,
            USER_SET_PITCH_STEER,
            SET_COURSE_STEER,
            SET_PITCH_STEER,
            SHOT_TORPEDO,
            USER_SHOT_TORPEDO,
            USER_TORPEDO_DETONATION,
            SUBMARINE_NEW_DAMAGE,
            SET_SUBMARINE_DAMAGE,
            SUBMARINE_PICKUP_SNOWFLAKE,
            SUBMARINE_INTO_BASE,
        } = mediator.getEventTypes();
        const { GET_MAX_SPEED } = mediator.getTriggerTypes();
        mediator.set(GET_MAX_SPEED, () => this.getSpeed());
        mediator.subscribe(USER_SET_DIRECTION_STATUS, (data: TDirectionStatus) => this.setDirectionStatus(data));
        mediator.subscribe(USER_SET_ACCELERATOR, (value: number) => this.setAccelerator(value));
        mediator.subscribe(USER_SET_COURSE_STEER, (data: { value:number, target:'submarine'|'torpedo' }) => this.setCourseSteer(data));
        mediator.subscribe(USER_SET_PITCH_STEER, (data: { value:number, target:'submarine'|'torpedo' }) => this.setPitchSteer(data));
        mediator.subscribe(USER_SHOT_TORPEDO, () => this.setShotTorpedo());
        mediator.subscribe(USER_TORPEDO_DETONATION, () => this.setTorpedoDetonation());
        mediator.subscribe(USE_EQUIPMENT_START, (data: { equipmentId: number }) => this.useEquipmentStartHandler(data));
        mediator.subscribe(USE_EQUIPMENT_STOP, (data: { equipmentId: number }) => this.useEquipmentStopHandler(data));
        mediator.subscribe(SET_ACCELERATOR, (data: { value: number }) => this.setAcceleratorHandler(data));
        mediator.subscribe(SET_COURSE_STEER, (data: { value: number }) => this.setCourseSteerHandler(data));
        mediator.subscribe(SET_PITCH_STEER, (data: { value: number }) => this.setPitchSteerHandler(data));
        mediator.subscribe(SHOT_TORPEDO, () => this.shotTorpedoHandler());
        mediator.subscribe(SUBMARINE_NEW_DAMAGE, (data: { damage: number }) => this.submarineNewDamageHandler(data));
        mediator.subscribe(SET_SUBMARINE_DAMAGE, (data: TDamage) => this.setSubmarineDamageHandler(data));
        mediator.subscribe(SUBMARINE_PICKUP_SNOWFLAKE, (data: { cargo: number }) => this.submarinePickupSnowflakeHandler(data));
        mediator.subscribe(SUBMARINE_INTO_BASE, (data: { isIntoBase: boolean, submarine: TGameSubmarine }) => this.submarineIntoBaseHandler(data));
        // проинициализировать оборудование
        this.initEquipments(COMPARTMENT_WEIGHT);
        // рули управления выпущенной торпедой
        this.torpedo = {
            courseSteer: 0,
            pitchSteer: 0
        };
    }

    destructor(): void {
        const {
            USER_SET_DIRECTION_STATUS,
            USE_EQUIPMENT_START,
            USE_EQUIPMENT_STOP,
            USER_SET_ACCELERATOR,
            USER_SET_COURSE_STEER,
            USER_SET_PITCH_STEER,
            SET_ACCELERATOR,
            SET_COURSE_STEER,
            SET_PITCH_STEER,
            SHOT_TORPEDO,
            USER_SHOT_TORPEDO,
            USER_TORPEDO_DETONATION,
            SUBMARINE_NEW_DAMAGE,
            SET_SUBMARINE_DAMAGE,
            SUBMARINE_PICKUP_SNOWFLAKE,
            SUBMARINE_INTO_BASE,
        } = this.mediator.getEventTypes();
        this.mediator.unsubscribeAll(USER_SET_DIRECTION_STATUS);
        this.mediator.unsubscribeAll(USER_SET_ACCELERATOR);
        this.mediator.unsubscribeAll(USER_SET_COURSE_STEER);
        this.mediator.unsubscribeAll(USER_SET_PITCH_STEER);
        this.mediator.unsubscribeAll(USE_EQUIPMENT_START);
        this.mediator.unsubscribeAll(USE_EQUIPMENT_STOP);
        this.mediator.unsubscribeAll(SET_ACCELERATOR);
        this.mediator.unsubscribeAll(SET_COURSE_STEER);
        this.mediator.unsubscribeAll(SET_PITCH_STEER);
        this.mediator.unsubscribeAll(USER_SHOT_TORPEDO);
        this.mediator.unsubscribeAll(USER_TORPEDO_DETONATION);
        this.mediator.unsubscribeAll(SHOT_TORPEDO);
        this.mediator.unsubscribeAll(SUBMARINE_NEW_DAMAGE);
        this.mediator.unsubscribeAll(SET_SUBMARINE_DAMAGE);
        this.mediator.unsubscribeAll(SUBMARINE_PICKUP_SNOWFLAKE);
        this.mediator.unsubscribeAll(SUBMARINE_INTO_BASE);
    }

    initEquipments(COMPARTMENT_WEIGHT: number): void {
        // освободить всё оборудование, кроме реакторов, с ними взаимодействовать нельзя
        this.submarine.equipments.forEach(equipment => equipment.isUsed = equipment.type === 'reactor');
        this.submarine.accelerator = 0;
        this.submarine.currentAccelerator = 0;
        this.submarine.courseSteer = 0;
        this.submarine.pitchSteer = 0;
        this.submarine.course = 0;
        this.submarine.pitch = 0;
        this.submarine.speed = 0;
        // задать лодке пустой массив повреждений
        this.submarine.damages = [];
        this.submarine.cargo = 0;
        this.repairId = null;
        this.emptyWeight = this.getSubmarineEmptyWeight(COMPARTMENT_WEIGHT);

        // кинуть дамаги в лодку (для теста!)
        //this.submarineNewDamageHandler({ damage: 50 });
        //this.submarineNewDamageHandler({ damage: 20 }, EDAMAGE.FIRE);
        //this.submarineNewDamageHandler({ damage: 80 }, EDAMAGE.FIRE);
    }

    // прикоснулся ли к оборудованию
    // возвращает id оборудования или null
    isTouched(person: TSailor): number | null {
        const { x, y } = person;
        const points = [
            { x, y }, // левый нижний угол
            { x, y: y + this.PERSON_HEIGHT }, // левый верхний угол
            { x: x + this.PERSON_WIDTH, y: y + this.PERSON_HEIGHT }, // правый верхний угол
            { x: x + this.PERSON_WIDTH, y }, // правый нижний угол
        ];

        for (let i = 0; i < points.length; i++) {
            const point = points[i];
            for (let j = 0; j < this.compartments.length; j++) {
                const compartment = this.compartments[j];
                if (compartment.equipment) {
                    if (this.collision(point.x, point.y, compartment.equipment)) {
                        const equipment = this.submarine.equipments.find(equipment => equipment.slot === compartment.slot);
                        if (equipment) {
                            if (equipment.isUsed) {
                                return null;
                            }
                            equipment.isUsed = true; // занять оборудование
                            return equipment.id;
                        }
                    }
                }
            }
        }
        return null;
    }

    // TODO дубль server\application\modules\game\types\BotSubmarine.js
    // получить скорость лодки по имеющемуся у неё значению ускорения
    getSpeed(accelerator = 1): number {
        const reactor = this.submarine.equipments.find(equipment => equipment.type === 'reactor');
        if (reactor) {
            return this.getMaxSpeed(reactor.consumption) * accelerator * this.emptyWeight / this.getSubmarineFullWeight();
        }
        return 0;
    }

    // TODO дубль server\application\modules\game\types\BotSubmarine.js
    // получить вес ПУСТОЙ субмарины
    getSubmarineEmptyWeight(COMPARTMENT_WEIGHT: number): number {
        return this.submarine.slots * COMPARTMENT_WEIGHT + this.submarine.equipments.reduce((S, equipment) => S + equipment.weight, 0) || 1;
    }

    // TODO дубль server\application\modules\game\types\BotSubmarine.js
    // получить ПОЛНЫЙ вес субмарины
    getSubmarineFullWeight(): number {
        return this.emptyWeight + this.compartments.reduce((S, compartment) => compartment.water ? S + compartment.water : S, 0) + this.submarine.cargo;
    }

    // TODO дубль server\application\modules\game\types\BotSubmarine.js
    // изменение скорости лодки покакой-либо причине
    changeSpeed(): void {
        this.submarine.speed = Number(this.getSpeed(this.submarine.currentAccelerator / 100).toFixed(1));
        this.server.speedChange({ value: this.submarine.speed });
    }

    // вырасти повреждения лодки
    growDamages() {
        this.submarine.damages.forEach(damage => {
            if (damage.value <= this.MAX_DAMAGE_GROW) {
                damage.value += this.DAMAGE_GROW_SPEED; // выросли повреждения
            }
            // срандомить новые источники огня
            if (this.submarine.guid === this.user?.guid && // новый пожар имеет право разводить только капитан
                damage.type === EDAMAGE.FIRE // если повреждение - огонь
            ) {
                if (this.getChanceOfFire(damage.value)) {
                    this.submarineNewDamageHandler({ damage: 10 }, EDAMAGE.FIRE);
                }
            }
            if (damage.type === EDAMAGE.HOLE) { // налить воду
                const compartment = this.compartments.find(compartment => compartment.slot === damage.slot);
                if (compartment) {
                    if (compartment.water || compartment.water === 0) {
                        compartment.water += this.getWater(damage.value);
                    } else {
                        compartment.water = 0;
                    }
                    if (compartment.water > this.COMPARTMENT_VOLUME) { // отсек залило полностью
                        compartment.water = this.COMPARTMENT_VOLUME;
                    }
                }
            }
        });
    }

    // что-то чиним
    fixDamages(): void {
        if (this.repairId) {
            const damage = this.submarine.damages.find(damage => damage.id === this.repairId);
            if (damage) {
                damage.value -= this.DAMAGE_FIX_SPEED;
                this.damageFixCount++;
                if (this.damageFixCount % this.FIX_COUNT === 0) {
                    this.damageFixCount = 0;
                    this.setSubmarineDamage({ ...damage });
                }
            }
        }
        // проверить, залила ли вода какой-либо пожар
        this.compartments.forEach(compartment => {
            if (compartment.water) {
                const waterLevel = this.getWaterLevel(compartment);
                const x1 = compartment.points[0][0].x;
                const x2 = compartment.points[0][1].x;
                this.submarine.damages.forEach(damage => {
                    const { x, y, type } = damage;
                    if (type === EDAMAGE.FIRE && x >= x1 && x <= x2 && waterLevel > y) {
                        damage.value = -100;
                        this.setSubmarineDamage({ ...damage });
                    }
                });
            }
        });
        // здесь же воду отсосать, потому что автоматические насосы работают автоматически
        this.compartments.forEach(compartment => {
            if (compartment.water) {
                compartment.water -= this.removeWater();
                compartment.water = compartment.water < 0 ? 0 : compartment.water;
            }
        });
    }

    // перетекание водички из одного отсека в другой
    waterFlow(): void {
        this.compartments.forEach(hatch => {
            if (hatch.type === ECOMPARTMENT_TYPE.HATCH && hatch.open) { // найти открытый люк
                const compartment1 = this.compartments.find(_compartment => _compartment.slot === hatch.way1); // отсек справа
                const compartment2 = this.compartments.find(_compartment => _compartment.slot === hatch.way2); // отсек слева
                if (compartment1 && compartment2) {
                    if (Number(compartment1.water) > 0 || Number(compartment2.water) > 0) { // они с водой
                        const waterLevel1 = this.getWaterLevel(compartment1);
                        const waterLevel2 = this.getWaterLevel(compartment2);
                        const { y } = hatch.points[0][0];
                        if (waterLevel1 > y || waterLevel2 > y) { // уровень воды в одном из отсеков выше, чем пол люка
                            const halfWater = (Number(compartment1.water) + Number(compartment2?.water)) / 2; // выяснить средний уровень между ними
                            if (Math.abs(Number(compartment1.water) - halfWater) > this.WATER_FLOW_SPEED) { // перелить максимальное значение
                                // @ts-ignore
                                compartment1.water += Number(compartment1.water) - halfWater > 0 ? -this.WATER_FLOW_SPEED : this.WATER_FLOW_SPEED;
                                // @ts-ignore
                                compartment2.water += Number(compartment2.water) - halfWater > 0 ? -this.WATER_FLOW_SPEED : this.WATER_FLOW_SPEED;
                            } else {
                                compartment1.water = halfWater;
                                compartment2.water = halfWater;
                            }
                        }
                    }
                }
            }
        });
    }

    getDepthDamage(): void {
        // посчитать повреждения лодки в случае превышения ею максимальной глубины
        if (this.getChanceDepthDamage(this.submarine.z, this.getMaxDepth(this.submarine.level))) {
            this.submarineNewDamageHandler({ damage: 10 });
        }
    }

    /*****************************/
    /* Отправка событий в бекенд */
    /*****************************/
    // начать использовать оборудование или прекратить его использовать
    setDirectionStatus(data: TDirectionStatus): void {
        const { status, repair } = data;
        if (status === ESTATUS.USE) {
            const person = this.getPersonByGuid(this.user?.guid);
            if (person) {
                // починка повреждений
                if (repair) {
                    // если он уже чинит - прекратить чинить
                    if (person.status === ESTATUS.USE) {
                        person.status = ESTATUS.STAND;
                        this.repairId = null;
                    } else { // чинить
                        person.status = ESTATUS.USE;
                        this.repairId = repair.id;
                    }
                    // изменить действие игрока
                    this.server.sailorMove({
                        status: person.status,
                        x: person.x,
                        y: person.y,
                    });
                    return;
                }
                // если он уже что-то делает - прекратить делать
                if (person.status === ESTATUS.USE && this.equipmentId) {
                    person.status = ESTATUS.STAND;
                    // послать команду, что использовать оборудование прекратил
                    const equipment = this.submarine.equipments.find(equipment => equipment.id === this.equipmentId);
                    if (equipment) {
                        equipment.isUsed = false; // освободить оборудование
                        this.server.useEquipmentStop({ equipmentId: this.equipmentId });
                        // если вышел из панели управления - подорвать торпеду
                        if (equipment.type === 'weapon') {
                            this.server.torpedoDetonation();
                            this.torpedo.courseSteer = 0;
                            this.torpedo.pitchSteer = 0;
                        }
                        this.equipmentId = null;
                    }
                    // изменить действие игрока
                    this.server.sailorMove({
                        status: person.status,
                        x: person.x,
                        y: person.y,
                    });
                    return;
                }
                // начинать что-то делать только в районе оборудования
                const equipmentId = this.isTouched(person);
                if (equipmentId && this.user?.guid) {
                    this.equipmentId = equipmentId;
                    person.status = ESTATUS.USE;
                    this.server.useEquipmentStart({ equipmentId: this.equipmentId, guid: this.user.guid });
                    // изменить действие игрока
                    this.server.sailorMove({
                        status: person.status,
                        x: person.x,
                        y: person.y,
                    });
                }
            }
        }
    }

    setAccelerator(value: number): void {
        if (Math.abs(value) <= 100) {
            this.submarine.accelerator = value;
            this.server.setAccelerator({ value: this.submarine.accelerator });
        }
    }

    setCourseSteer(data: { value: number, target:'torpedo'|'submarine'}): void {
        const { value, target } = data;
        const extremeValue = this.getMaxSteeringAngle(this.submarine.level);

        this[target].courseSteer += value;
        this[target].courseSteer = Math.abs(this[target].courseSteer) > extremeValue ? 
            extremeValue * value < 0 ? -extremeValue : extremeValue 
            : this[target].courseSteer
        ;

        if (target === 'submarine') { 
            this.server.setCourseSteer({ value: this.submarine.courseSteer });
        } else {
            this.server.setTorpedoCourseSteer({ value: this.torpedo.courseSteer });
        }
    }

    setPitchSteer(data: { value: number, target:'torpedo'|'submarine' }): void {
        const { value, target } = data;
        const extremeValue = this.getMaxSteeringAngle(this.submarine.level);

        this[target].pitchSteer += value;
        this[target].pitchSteer = Math.abs(this[target].pitchSteer) > extremeValue ? 
            extremeValue * value < 0 ? -extremeValue : extremeValue 
            : this[target].pitchSteer
        ;

        if (target === 'submarine') { 
            this.server.setPitchSteer({ value: this.submarine.pitchSteer });
        } else {
            this.server.setTorpedoPitchSteer({ value: this.torpedo.pitchSteer });
        }
    }

    // выстрелить торпедой
    setShotTorpedo(): void {
        this.server.shotTorpedo();
    }

    // подорвать выпущенную торпеду
    setTorpedoDetonation(): void {
        this.torpedo.courseSteer = 0;
        this.torpedo.pitchSteer = 0;
        this.server.torpedoDetonation();
    }
    /*****************************/

    /********************************/
    /* Обработчики сокетных событий */
    /********************************/
    // событие начала использования оборудования. Пометить его занятым
    useEquipmentStartHandler(data: { equipmentId: number }): void {
        const { equipmentId } = data;
        const equipment = this.submarine.equipments.find(equipment => equipment.id === equipmentId);
        if (equipment) {
            equipment.isUsed = true;
        }
    }

    // событие прекращения использования оборудования. Пометить его свободным
    useEquipmentStopHandler(data: { equipmentId: number }): void {
        const { equipmentId } = data;
        const equipment = this.submarine.equipments.find(equipment => equipment.id === equipmentId);
        if (equipment) {
            equipment.isUsed = false;
        }
    }

    setAcceleratorHandler(data: { value: number }): void {
        if (this.equipmentId === null) {
            const { value } = data;
            this.submarine.accelerator = value;
        }
    }

    setCourseSteerHandler(data: { value: number }): void {
        if (this.equipmentId === null) {
            const { value } = data;
            this.submarine.courseSteer = value;
        }
    }

    setPitchSteerHandler(data: { value: number }): void {
        if (this.equipmentId === null) {
            const { value } = data;
            this.submarine.pitchSteer = value;
        }
    }

    shotTorpedoHandler(value: number = 0): void {
        const shotPanel = this.submarine.equipments.find(equipment => equipment.type === 'weapon');
        if (shotPanel) {
            if (value) {
                shotPanel.properties['ammo'].value = value;
                return;
            }
            if (shotPanel.properties['ammo'].value > 0) {
                shotPanel.properties['ammo'].value--;
            }
        }
    }

    // послать сообщение в бекенд, ИЛИ не посылать, если команда состоит из одного человека
    setSubmarineDamage(data: { id?: number; type: EDAMAGE, value: number, x: number, y: number, slot: number }): void {
        // вот такой вот ХИТРЫЙ способ оптимизации
        if (this.submarine.sailors.length) {
            this.server.setSubmarineDamage(data);
            return;
        }
        const id = data.id || Math.floor(Math.random() * 10000);
        this.setSubmarineDamageHandler({ id, ...data });
    }

    // в лодку прилетело новое повреждение
    submarineNewDamageHandler(data: { damage: number }, defaultType?: EDAMAGE): void {
        const slot = Math.floor(Math.random() * this.submarine.slots) + 1; // отсек, в котором случилось повреждение
        const type = defaultType || Math.round(Math.random() * EDAMAGE.FIRE); // тип повреждения
        // нарандомить координаты дырки по слоту
        const compartment = this.compartments.find(compartment => compartment.slot === slot);
        if (compartment) {
            const i = Math.floor(Math.random() * compartment.points.length); // определить номер палубы, на котороой случилось повреждение
            const points = compartment.points[i];
            const x1 = points[0].x;
            const x2 = points[1].x;
            const y1 = points[0].y;
            const x = x1 + Number((Math.random() * (x2 - x1)).toFixed(2));
            const y = y1 + Number((Math.random() * this.PERSON_HEIGHT).toFixed(2));
            // высрать сообщение команде о новом повреждении и получить его
            this.setSubmarineDamage({ type, value: data.damage, x, y, slot });
        }
    }

    // устанавливаем в лодку повреждение
    setSubmarineDamageHandler(data: TDamage): void {
        const { id, value } = data;
        const damage = this.submarine.damages.find(damage => damage.id === id);
        if (damage) { // изменилось существующее повреждение
            damage.value = value;
            if (damage.value <= 0) { // удалить исправленное повреждение
                for (let i = 0; i < this.submarine.damages.length; i++) {
                    if (this.submarine.damages[i].id === damage.id) {
                        this.submarine.damages.splice(i, 1);
                        // прекратить работу работать
                        if (this.repairId) {
                            const person = this.getPersonByGuid(this.user?.guid);
                            if (person) {
                                person.status = ESTATUS.STAND;
                                this.server.sailorMove({
                                    status: person.status,
                                    x: person.x,
                                    y: person.y,
                                });
                            }
                        }
                        return;
                    }
                }
            }
        } else { // появилось новое повреждение
            this.submarine.damages.push(data);
            this.mediator.call(this.mediator.getEventTypes().SET_NEW_DAMAGE_MESSAGE, data);
        }
    }

    // получили обновлённый груз субмарины
    submarinePickupSnowflakeHandler(data: { cargo: number }) {
        const { cargo } = data;
        this.submarine.cargo = cargo;
        this.changeSpeed();
    }

    // субмарина приплыла или уплыла из базы
    submarineIntoBaseHandler(data: { isIntoBase: boolean, submarine: TGameSubmarine }): void {
        const { isIntoBase, submarine } = data;
        if (isIntoBase) {
            // самопочинка лодки на базе
            this.submarine.damages = [];
            this.submarine.cargo = 0;
            this.changeSpeed();
            // восстановить боезапас и прочие расходники
            const shotPanel = submarine.equipments.find(equipment => equipment.type === 'weapon');
            if (shotPanel) {
                this.shotTorpedoHandler(shotPanel.properties.ammo.value);
                this.mediator.call(this.mediator.getEventTypes().USER_RELOAD_TORPEDO);
            }
        }
    }
    /********************************/

    update(): void {
        // изменение скорости лодки
        if (this.submarine.accelerator !== this.submarine.currentAccelerator) {
            const STEP = Math.round(this.ACCELERATOR_STEP / 10); // потому что апдейт происходит каждые 30мс, а мне не надо такую скорость изменения скорости
            if (this.submarine.accelerator - this.submarine.currentAccelerator > 0) {
                this.submarine.currentAccelerator += STEP;
            } else {
                this.submarine.currentAccelerator -= STEP;
            }
            this.changeSpeed();
        }
        // рост повреждений
        this.growDamages();
        // починка повреждений
        this.fixDamages();
        // перетекание водички из одного отсека в другой
        this.waterFlow();
        // посчитать повреждения лодки в случае превышения ею максимальной глубины
        this.getDepthDamage();
    }

    getTorpedo(): TFiringTorpedo {
        return this.torpedo;
    }
}