import React, { useMemo } from "react";
import { AngleType, ControlType } from "../../../../../../config";
import { useIsAspectRaito } from "../../../hooks";
import { IControlModuleStats } from "../../ControlModule";

import './Angles.scss';

interface IAngle {
    angleType: AngleType;
    subjectType: ControlType;
    angle: number
}

const Angle: React.FC<IAngle> = (props: IAngle) => {
    const { angleType, subjectType, angle } = props;

    return (
        <div className={`control-module__angle control-module__angle_${angleType}`}>
            <div
                style={{ transform: `rotateZ(${angle}deg)` }}
                className={`angle-indicator__icon angle-indicator__icon_${angleType}-${subjectType}`}
            />
            <div className="control-module__angle-indicator">
                {angle}°
            </div>
        </div>
    );
}

const Angles: React.FC<IControlModuleStats> = (props: IControlModuleStats) => {
    const { course, pitch, isFired, isFireingPanel } = props;

    useIsAspectRaito('.control-module__angle', 'width', 'height');

    const pitchIndicator = useMemo(() =>
        <Angle
            angle={-pitch}
            angleType={AngleType.PITCH}
            subjectType={isFireingPanel && isFired ? ControlType.TORPEDO : ControlType.SUBMARINE}
        />
        , [pitch, isFired]);

    const courseIndicator = useMemo(() =>
        <Angle
            angle={course}
            angleType={AngleType.COURSE}
            subjectType={isFireingPanel && isFired ? ControlType.TORPEDO : ControlType.SUBMARINE}
        />
        , [course, isFired]);

    return (
        <div className="control-module__angles">
            {courseIndicator}
            {pitchIndicator}
        </div>
    );
}

export default Angles;