import React, { useState, useEffect, useContext, ChangeEvent, useRef } from "react";
import { TGameSubmarine } from "../../../../services/server/types";
import { MediatorContext } from "../../../../App";
import { useIsAspectRaito } from "../hooks";

import './Speedometer.scss';

interface ISpeedometer {
    submarine: TGameSubmarine;
}

const SpeedIndicator: React.FC<ISpeedometer> = (props: ISpeedometer) => {
    const { submarine } = props;
    const mediator = useContext(MediatorContext);
    const { GET_SUBMARINE } = mediator.getTriggerTypes();
    const { SCENE_UPDATE } = mediator.getEventTypes();

    const [speed, setSpeed] = useState<number>(submarine.speed);

    useIsAspectRaito('.action-module__speed-indicator', 'height', 'width');

    useEffect(() => {
        mediator.subscribe(SCENE_UPDATE, speedUpdater);

        return () => {
            mediator.unsubscribe(SCENE_UPDATE, speedUpdater);
        }
    });

    const speedUpdater = () => {
        const currentSubmarine = mediator.get<TGameSubmarine>(GET_SUBMARINE);
        if (currentSubmarine) {
            setSpeed(currentSubmarine.speed);
        }
    }

    return (
        <div className="action-module__speed-indicator">
            <span>{ speed } узл</span>
        </div>
    );
}

const SpeedControl: React.FC<ISpeedometer> = (props: ISpeedometer) => {
    const { submarine } = props;
    const mediator = useContext(MediatorContext);
    const { SET_ACCELERATOR, USER_SET_ACCELERATOR } = mediator.getEventTypes();

    const currentTrackRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        mediator.subscribe(SET_ACCELERATOR, onAcceleratorChange);

        return () => {
            mediator.unsubscribe(SET_ACCELERATOR, onAcceleratorChange);
        }
    });

    const setAccelerator = (e: ChangeEvent<HTMLInputElement>) => {
        mediator.call(USER_SET_ACCELERATOR, -Number(e.target.value));
    }

    const onAcceleratorChange = (data: { value: number }) => {
        const { value } = data;
        const currentTrack = currentTrackRef.current;
        if (currentTrack) {
            currentTrack.style.clipPath = (
                value < 0 ? `polygon(0 50%, 100% 50%, 100% ${50-value/2}%, 0 ${50-(value/2)}%)` :
                value > 0 ? `polygon(0 ${50-value/2}%, 100% ${50-value/2}%, 100% 50%, 0 50%)` : 'polygon(0 50%, 100% 50%, 100% 50%, 0 50%)'
            );
        }
    }

    return (
        <div className="action-module__speed-control-wrapper">
            <div className="action-module__speed-control-module">
                <datalist id="speed-setter-list">
                    <option value="-100" label="-100"/>
                    <option value="0" label="0"/>
                    <option value="100" label="100"/>
                </datalist>
                <input
                    className="action-module__speed-setter"
                    type="range"
                    min={-100}
                    max={100}
                    step={20}
                    onChange={(e) => setAccelerator(e)}
                    defaultValue={submarine.accelerator}
                    list="speed-setter-list"
                />
                <div className="speed-setter__track" />
            </div>
            <div className="speed-setter__current-track" ref={currentTrackRef} />
        </div>
    );
}

const Speedometer: React.FC<ISpeedometer> = (props: ISpeedometer) => {
    const { submarine } = props;

    return(
        <div className="action-module__speedometer-wrapper">
            <SpeedIndicator submarine={submarine}/>
            <SpeedControl submarine={submarine}/>
            <span>Скорость</span>
        </div>
    );
}

export default Speedometer;