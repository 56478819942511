import React, { useContext, useState, useEffect } from "react";
import { MediatorContext } from "../../App";
import { useGetUser } from "../../hooks";
import { Exit } from "../../components/svg";
import InteractiveButton from "../../components/InteractiveButton/InteractiveButton";
import { IBasePage, PAGES } from "../PageManager";
import Slider from "../../components/Slider/Slider";
import ShopPage from "./ShopPage/ShopPage";

import './Shop.scss';

const Shop: React.FC<IBasePage> = (props: IBasePage) => {
    const { setPage } = props;
    const mediator = useContext(MediatorContext);
    const [renderCount, setRenderCount] = useState<number>(1);
    const { USER_UPDATE_INFO } = mediator.getEventTypes();
    const getUser = useGetUser(mediator);
    const user = getUser();

    useEffect(() => {
        const userUpdateInfoHandler = (data: { money?: number }) => {
            const { money } = data;
            if (money) {
                setRenderCount(renderCount + 1);
            }
        }

        mediator.subscribe(USER_UPDATE_INFO, userUpdateInfoHandler);

        return () => {
            mediator.unsubscribe(USER_UPDATE_INFO, userUpdateInfoHandler);
        }
    });

    if (!user) {
        return <>{'Что-то пошло не так =(. Попробуйте ещё раз'}</>
    }

    const pages = user.submarines.map((submarine, index) => <ShopPage key={index} submarine={submarine} />);

    return (
        <div className="shop">
            <div className="shop__header">
                <div>{user.money}</div>
                <InteractiveButton
                    classes="interactive-button_exit"
                    svg={<Exit />}
                    onClick={() => setPage(PAGES.LOBBY)}
                />
            </div>
            {user.submarines.length < 2 ?
                <ShopPage isSubmarineBuyButton={true} submarine={user.submarines[0]} /> :
                <Slider content={pages} />}
        </div>
    );
}

export default Shop;