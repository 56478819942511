import { TYPE } from '../../config';

type TStorage = {
    type: TYPE, // тип хранимого значения
    name: string, // название ключа, по которому его храним
    value?: any, // само хранимое значение
    isSession?: boolean, // true - НЕ записываем в localStorage, false - записываем
}

export default class Storage {
    private storages: {
        [name: string]: TStorage
    }

    constructor(STORAGE: object) {
        this.storages = {};
        // заполнить стор ключами из настроек с указанием типа
        Object.values(STORAGE).forEach(({ type, name, isSession }) => {
            this.storages[name] = {
                type: type ? type : TYPE.STRING,
                name, 
                isSession,
            }
        });
        // заполнить стор значениями из локалСтора, пусть даже и пустыми
        Object.values(this.storages).forEach((storage) => {
            storage.value = this.get(storage.name);
        });
    }

    // сериализация объекта (функции) в строку
    convertToText(obj: any): string {
        if (obj === null) return 'null';
        if (obj === '') return '""';
        const string = [];
        if (typeof obj === 'object' && obj.join == undefined) {
            string.push('{');
            for (const prop in obj) {
                string.push(`"${prop}"`, ': ', this.convertToText(obj[prop]), ',');
            }
            string.push('}');
        } else if (typeof obj === 'object' && !(obj.join == undefined)) {
            string.push('[');
            for (const prop in obj) {
                string.push(this.convertToText(obj[prop]), ',');
            }
            string.push(']');
        } else if (typeof obj === 'function') {
            string.push(obj.toString());
        } else {
            string.push(JSON.stringify(obj));
        }
        return string.join('');
    }

    // вернуть значение
    get(name: string): any {
        if (this.storages[name]) {
            if (this.storages[name].value) { // если зачение есть, то в локалстор не идём
                return this.storages[name].value;
            }
            switch (this.storages[name].type) { // идём в локал стор
                case TYPE.STRING:
                    return localStorage.getItem(this.storages[name].name);
                case TYPE.NUMBER:
                    return parseFloat(localStorage.getItem(this.storages[name].name) as string);
                case TYPE.BOOLEAN:
                    return !!(localStorage.getItem(this.storages[name].name) === 'true');
                case TYPE.OBJECT:
                    try {
                        return JSON.parse(localStorage.getItem(this.storages[name].name) || '');
                    } catch (e) {
                        return null;
                    }
                case TYPE.FUNCTION:
                    // return eval(`(function() { return ${localStorage.getItem(this.storages[name].name)} })() `); // потому что новый таз не захотел дружить с eval-ом
                default:
                    // like string
                    return localStorage.getItem(this.storages[name].name);
            }
        }
        return null;
    }

    set(name: string, value: any): void {
        if (this.storages[name]) {
            this.storages[name].value = value;
            if (!this.storages[name].isSession) {
                switch (this.storages[name].type) {
                    case TYPE.STRING:
                        localStorage.setItem(this.storages[name].name, value);
                        break;
                    case TYPE.NUMBER:
                        localStorage.setItem(this.storages[name].name, parseFloat(value).toString());
                        break;
                    case TYPE.BOOLEAN:
                        localStorage.setItem(this.storages[name].name, `${Boolean(value)}`);
                        break;
                    case TYPE.OBJECT:
                    case TYPE.FUNCTION:
                        localStorage.setItem(this.storages[name].name, this.convertToText(value));
                        break;
                    default:
                        // like string
                        localStorage.setItem(this.storages[name].name, value);
                        break;
                }
            }
        }
    }

    // почистить
    clear(name: string): void {
        if (this.storages[name]) {
            this.storages[name].value = null;
            if (!this.storages[name].isSession) {
                localStorage.removeItem(this.storages[name].name);
            }
        }
    }
}
