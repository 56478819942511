import CONFIG, { TCompartment, TPoint } from "../config";
import { EDIRECTION, ESTATUS, TUser, TGameSubmarine, TSailor, TDamage } from "../services/server/types";
import { Server } from "../services/server";
import { Mediator } from "../services/mediator";
import { TInteriorGame } from './InteriorGame';

export type TDirectionStatus = {
    direction?: EDIRECTION;
    status: ESTATUS;
    repair?: TDamage;
}

interface IBaseInterior {
    server: Server
    mediator: Mediator;
    submarine: TGameSubmarine;
    user: TUser | null;
    destructor: () => void;
    getPersonByGuid: (guid: string | undefined) => TSailor | null;
    collision: (x: number, y: number, p: TPoint[]) => boolean;
}

export default class BaseInterior implements IBaseInterior {
    server: Server
    mediator: Mediator;
    submarine: TGameSubmarine;
    user: TUser | null;
    INTERIOR_HEIGHT: number;
    COMPARTMENT_VOLUME: number;

    constructor(options: TInteriorGame) {
        const { server, mediator, submarine, user } = options;
        this.server = server;
        this.mediator = mediator;
        this.submarine = submarine;
        this.user = user;
        const {
            COMPARTMENT_VOLUME,
            INTERIOR_HEIGHT,
        } = CONFIG.GAME;
        this.INTERIOR_HEIGHT = INTERIOR_HEIGHT;
        this.COMPARTMENT_VOLUME = COMPARTMENT_VOLUME;
    }

    destructor(): void {
        console.log('переопредели меня!!!');
    }

    getPersonByGuid(guid: string | undefined): TSailor | null {
        if (guid && this.submarine.captain.guid === guid) {
            return this.submarine.captain;
        }
        return this.submarine.sailors.find(sailor => sailor.guid === guid) || null;
    }

    /*
        p - список точек
        size - количество точек
        result - входит ли точка в многоугольник
    */
    collision(x: number, y: number, p: TPoint[]): boolean {
        const size = p.length;
        let result = false;
        let j = size - 1;
        for (let i = 0; i < size; i++) {
            if ((p[i].y < y && p[j].y >= y || p[j].y < y && p[i].y >= y) &&
                (p[i].x + (y - p[i].y) / (p[j].y - p[i].y) * (p[j].x - p[i].x) < x))
                result = !result;
            j = i;
        }
        return result;
    }

    // получить уровень воды в отсеке
    getWaterLevel(compartment: TCompartment): number {
        return compartment.points[1][0].y + this.INTERIOR_HEIGHT * Number(compartment.water) / this.COMPARTMENT_VOLUME;
    }
}