import React from "react";

const Exit: React.FC = () => {
    return (
        <svg id="exit" fill="none" viewBox="0 0 260 400">
            <path fill="currentColor" d="M260 400H0L2 85c4-18 14-36 28-49C45 22 61 13 81 6c0 0 31-6 51-6 19 0 48 6 48 6 25 8 47 24 62 44 9 12 16 21 18 35v315ZM133 34c-26 0-65 13-82 32a82 82 0 0 0-20 44v268h198V138c0-12 1-25-1-37-3-15-11-28-22-39a103 103 0 0 0-73-28ZM18 113l-3 1v29l3 1 4-1v-29l-4-1Zm221 3v27l3 2 4-2v-27l-4-2-3 2Zm3 94-3 2v25l3 1 4-1v-25l-4-2Zm-224 2-3 2v26l3 2 4-2v-26l-4-2Zm0 103-3 2v25l3 2 4-2v-25l-4-2Zm224-1-3 2v26l3 2 4-2v-26l-4-2Z"/>
            <path fill="currentColor" d="M38 371V142c0-11-1-23 1-33 2-14 7-26 17-37 17-20 46-29 72-31l1 330H38Zm59-142a13 13 0 1 0 0 27c7 0 12-6 12-13 1-7-5-14-12-14Zm126 142-87 1V41c24 1 47 11 65 27 11 10 17 22 20 36 2 12 1 36 1 36l1 231Zm-59-142c-7 0-13 7-13 14s6 13 13 13c8 0 13-6 13-13 1-7-5-14-13-14Z"/>
        </svg>
    );
}

export default Exit;