import React, { useRef } from "react";

import './ModuleButton.scss';

interface IModuleButton {
    changeValueFunction: (step: number) => void
    type: 'course'|'pitch';
    value: number;
    sign: -1|1;
    limitValue: number;
}

const ButtonArrow: React.FC<{ isLighted: boolean }> = ({ isLighted }) => {
    return(<div className={"control-module__arrow " + (isLighted ? "control-module__arrow_lighted" : '')}/>)
}

const ModuleButton: React.FC<IModuleButton> = (props: IModuleButton) => {
    const {
        changeValueFunction,
        type,
        value,
        sign,
        limitValue
    } = props;

    const buttonRef = useRef<HTMLButtonElement>(null);

    const onClick = () => {
        changeValueFunction(sign * -5);
    }

    return (
        <div className="control-module__button-wrapper">
            <button
                onClick={onClick}
                className={"control-module__button control-module__button_"+type}
                ref={buttonRef}
            />
            <div className="control-module__arrows-wrapper">
                <ButtonArrow isLighted={value * sign < 0}/>
                <ButtonArrow isLighted={value * sign < -10}/>
                <ButtonArrow isLighted={value * sign <= -limitValue}/>
            </div>
        </div>
    )
}

export default ModuleButton;