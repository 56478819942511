import React, { useEffect, useContext } from "react";
import { MediatorContext } from "../../../../App";
import { EDIRECTION, ESTATUS } from "../../../../services/server/types";
import Joystick from "../Joystick/Joystick";
import Menu from "./Menu/Menu";
import UsePanel from './UsePanel/UsePanel';

import './MovePanel.scss';

const MovePanel: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const { USER_SET_DIRECTION_STATUS } = mediator.getEventTypes();

    // TODO Выпилить! Только для разработки!!!
    useEffect(() => {
        const keyDownHandler = (event: KeyboardEvent) => {
            switch (event.keyCode) {
                case 65: // a
                    mediator.call(USER_SET_DIRECTION_STATUS, { direction: EDIRECTION.LEFT, status: ESTATUS.WALK });
                    break;
                case 68: // d
                    mediator.call(USER_SET_DIRECTION_STATUS, { direction: EDIRECTION.RIGHT, status: ESTATUS.WALK });
                    break;
                case 87: // w
                    mediator.call(USER_SET_DIRECTION_STATUS, { direction: EDIRECTION.UP, status: ESTATUS.WALK });
                    break;
                case 83: // s
                    mediator.call(USER_SET_DIRECTION_STATUS, { direction: EDIRECTION.DOWN, status: ESTATUS.WALK });
                    break;
                case 69: // e
                    // перетащил в ControlPanel, пока в панелях нету крестика
                    //mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.USE });
                    break;
                case 81: // q
                    mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.CLOSE });
                    break;
            }
        }

        const keyUpHandler = (event: KeyboardEvent) => {
            switch (event.keyCode) {
                case 65: // a
                case 68: // d
                case 87: // w
                case 83: // s
                //case 69: // e // чтобы морячок всегда что-то делал, но делал это с чувством и с толком
                case 81: // q
                    mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.STAND });
                    break;
            }
        }

        document.addEventListener('keydown', keyDownHandler);
        document.addEventListener('keyup', keyUpHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
            document.removeEventListener('keyup', keyUpHandler);
        }
    });

    // хендлеры движения
    const upHandler = () => mediator.call(USER_SET_DIRECTION_STATUS, { direction: EDIRECTION.UP, status: ESTATUS.WALK });
    const downHandler = () => mediator.call(USER_SET_DIRECTION_STATUS, { direction: EDIRECTION.DOWN, status: ESTATUS.WALK });
    const leftHandler = () => mediator.call(USER_SET_DIRECTION_STATUS, { direction: EDIRECTION.LEFT, status: ESTATUS.WALK });
    const rightHandler = () => mediator.call(USER_SET_DIRECTION_STATUS, { direction: EDIRECTION.RIGHT, status: ESTATUS.WALK });
    const stopHandler = () => mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.STAND });

    return (<>
        <Joystick
            classes="platformer__joystick"
            upHandler={upHandler}
            downHandler={downHandler}
            leftHandler={leftHandler}
            rightHandler={rightHandler}
            stopHandler={stopHandler}
        />
        <Menu />
        <UsePanel />
    </>)
}

export default MovePanel;