import React, { useEffect, useRef, useState } from "react";

import './Slider.scss';

interface ISlider {
    content: JSX.Element[];
}

const Slider: React.FC<ISlider> = (props: ISlider) => {
    const { content } = props;
    const [slided, setSlided] = useState<number>(0);
    const sliderRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const slider = sliderRef.current;
        if (slider) {
            const sliderElem = slider.firstChild as HTMLElement;
            if (sliderElem) {
                const scrollLenght = sliderElem.getBoundingClientRect().width;
                slider.style.transform = `translateX(${ -scrollLenght*slided }px)`;
            }
        }
    });

    const changeSlide = (direction: boolean): null|void => {
        return direction ?
            slided < content.length-1 ? setSlided(prev => prev+1) : null : 
            slided > 0 ? setSlided(prev => prev-1) : null
        ;
    }

    const slides = content.map((slide, index) => <div className="slide" key={index}>{ slide }</div>)

    return (
        <div className="slider">
            <button 
                className="slider__button prev"
                onClick={() => changeSlide(false)}
            />
            <div ref={sliderRef} className="slider__content">
                { slides }
            </div>
            <button 
                className="slider__button next"
                onClick={() => changeSlide(true)}
            />
        </div>
    );
}

export default Slider;