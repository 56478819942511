import React, { useContext, useState, useEffect } from "react";
import CONFIG from "../../../config";
import { MediatorContext } from "../../../App";
import { EDAMAGE, TDamage } from "../../../services/server/types";
import { useGetRating, useGetRank } from '../../../hooks';
import Popup from "../../Popup/Popup";

type TMessage = {
    title: string;
    text: string;
    messageType: string;
    timeout?: number;
}

const GameMessagePopup: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const [message, setMessage] = useState<TMessage | null>(null);
    const { 
        SUBMARINE_PICKUP_SNOWFLAKE, 
        USER_UPDATE_INFO, 
        SET_NEW_DAMAGE_MESSAGE, 
        SUBMARINE_INTO_BASE, 
        USER_DIE_BY_FIRE, 
        USER_DIE_BY_WATER 
    } = mediator.getEventTypes();
    const getRating = useGetRating(mediator);
    const getRank = useGetRank(mediator);

    useEffect(() => {
        const submarineIntoBaseHandler = (data: { isIntoBase: boolean }) => {
            const { isIntoBase } = data;
            let title = '';
            let text = '';
            let messageType = '';

            if (isIntoBase) {
                title = 'Вы на базе. Безопасная зона.';
                text = `Стоимость починки и перезарядки 1 монета. Ваш груз продан.`;
                messageType = 'beneficial beneficial__base_inside';
            } else {
                title = 'Вы покинули базу. Будьте осторожны!';
                text = `Собирайте снежинки и везите их на базу`;
                messageType = 'neutral neutral__base_outside';
            }
            setMessage({ title, text, messageType });
        }

        const submarinePickupSnowflakeHandler = (data: { cargo: number }) => {
            const { cargo } = data;
            const title = 'Снежинка подобрана.';
            const text = `Ваш груз ${cargo} тонн`;
            const messageType = 'beneficial beneficial__snowflake';
            setMessage({ title, text, messageType });
        }

        const userUpdateInfoHandler = (data: { money?: number, rating?: number, rank?: number }) => {
            const { money, rating, rank } = data;
            let title = '';
            let text = '';
            let messageType = '';

            if (rating) {
                title = 'Рейтинг изменен.';
                text = `Ваш рейтинг ${getRating(rating).name}. Очков ${rating}`;
                messageType = 'neutral neutral__rating';
                setMessage({ title, text, messageType });
            }
            if (rank) {
                title = 'Ранг изменен.';
                text = `Ваш ранг ${getRank(rank).name}. Очков ${rank}`;
                messageType = 'neutral neutral__rating';
                setMessage({ title, text, messageType });
            }
            if (money) {
                title = `У вас ${money} монеток`;
                messageType = 'neutral neutral__money';
            }
            setMessage({ title, text, messageType });
        }

        const setNewDamageMessage = (data: TDamage) => {
            const { type, value, slot } = data;
            let title;
            let text;
            let messageType = '';
            
            if (type === EDAMAGE.FIRE) {
                title = 'Мы горим!';
                text = `Пожар в отсеке №${slot} площадью ${value.toFixed(2)}м²`;
                messageType = 'warning warning__fire';
            } else {
                const { INTERIOR_TIMESTAMP, getWater } = CONFIG.GAME;
                title = 'Мы тонем!';
                text = `Скорость затопления отсека №${slot} ${(1000 / INTERIOR_TIMESTAMP * getWater(value) * 1000).toFixed(2)}кг/с`;
                messageType = 'warning warning__drown';
            }
            setMessage({ title, text, timeout: 3000, messageType });
        }

        const userDieByFire = () => {
            const title = 'Вы сгорели на работе!';
            const text = 'Жар пожара поглотил вас!';
            const messageType = 'warning warning__death';
            setMessage({ title, text, timeout: 3000, messageType });
        }

        const userDieByWater = () => {
            const title = 'Вы утонули!';
            const text = 'Объятья воды оказались слишком крепкими';
            const messageType = 'warning warning__death';
            setMessage({ title, text, timeout: 3000, messageType });
        }

        mediator.subscribe(SUBMARINE_INTO_BASE, submarineIntoBaseHandler);
        mediator.subscribe(SUBMARINE_PICKUP_SNOWFLAKE, submarinePickupSnowflakeHandler);
        mediator.subscribe(USER_UPDATE_INFO, userUpdateInfoHandler);
        mediator.subscribe(SET_NEW_DAMAGE_MESSAGE, setNewDamageMessage);
        mediator.subscribe(USER_DIE_BY_FIRE, userDieByFire);
        mediator.subscribe(USER_DIE_BY_WATER, userDieByWater);

        return () => {
            mediator.unsubscribe(SUBMARINE_INTO_BASE, submarineIntoBaseHandler);
            mediator.unsubscribe(SUBMARINE_PICKUP_SNOWFLAKE, submarinePickupSnowflakeHandler);
            mediator.unsubscribe(USER_UPDATE_INFO, userUpdateInfoHandler);
            mediator.unsubscribe(SET_NEW_DAMAGE_MESSAGE, setNewDamageMessage);
            mediator.unsubscribe(USER_DIE_BY_FIRE, userDieByFire);
            mediator.unsubscribe(USER_DIE_BY_WATER, userDieByWater);
        }
    });

    // не показываем ничего
    if (!message) {
        return (<></>);
    }

    const { title, text, timeout, messageType } = message;

    return (<>
        <Popup
            title={title}
            text={text}
            timeout={timeout || 5000}
            classes={messageType}
            cb={() => setMessage(null)}
        />
    </>)
}

export default GameMessagePopup;