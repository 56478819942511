import * as THREE from 'three';
import { TPoint } from '../../config';

import boomTexture from '../../assets/img/sonarImages/boomTexture.png';

interface IThree {
    WIDTH: number;
    HEIGHT: number;
    sonarRange:number;
}

export type TCanvasPoint = TPoint & {
    z: number;
}

export type TThree = {
    parentId: string;
    WIDTH: number;
    HEIGHT: number;
    sonarRange: number;
    weaponRange: number;
    baseRadius?: number;
}

class Three implements IThree {
    WIDTH;
    HEIGHT;
    camera;
    scene;
    renderer;
    sonarRange;
    weaponRange;
    baseRadius;
    clock;
    textureLoader;
    textures;
    protected canvasElement: HTMLElement | null = null;
    protected parentId: string;

    constructor(options: TThree) {
        const {
            parentId,
            WIDTH,
            HEIGHT,
            sonarRange,
            weaponRange,
            baseRadius
        } = options;

        this.WIDTH = WIDTH;
        this.HEIGHT = HEIGHT;
        this.sonarRange = sonarRange;
        this.weaponRange = weaponRange;
        this.parentId = parentId;
        this.baseRadius = baseRadius ? baseRadius : 0;

        const d = 20;

        /*** scene ***/
        this.scene = new THREE.Scene();

        /*** camera ***/
        this.camera = new THREE.OrthographicCamera(
            -this.sonarRange,
            this.sonarRange,
            this.sonarRange+d,
            -this.sonarRange-d,
            1, this.sonarRange*5
        );
        this.camera.position.z = this.sonarRange/2;
        this.camera.lookAt(new THREE.Vector3(0, 0, 0));
        
        /*** render ***/
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.WIDTH, this.HEIGHT);
        this.renderer.setViewport(0, 0, this.WIDTH, this.HEIGHT);

        this.clock = new THREE.Clock();
        this.textureLoader = new THREE.TextureLoader();

        this.textures = {
            boom: this.textureLoader.load(boomTexture)
        }

        /*** set canvas ***/
        if (this.parentId) {
            document.getElementById(this.parentId)?.appendChild(this.renderer.domElement);
            this.canvasElement = this.renderer.domElement;
        }
    }

    destructor() {
        if (this.canvasElement) {
            const parent = document.getElementById(this.parentId);
            if (parent) {
                if (parent.hasChildNodes() && parent.firstChild === this.canvasElement) {
                    parent.removeChild(this.canvasElement)
                }
            }
            this.canvasElement = null;
        }
    }

    // нарисовать оси
    protected drawAxisHelper() { // z-синяя, y-зеленая, x-оранжевая
        const axesHelper = new THREE.AxesHelper(this.sonarRange);
        this.scene.add(axesHelper);
    }

    // изменить привычние оси на их расположение в three
    protected changeAxis({ x, y, z }: { x: number, y: number, z: number }): TCanvasPoint {
        return { 
            x: -y,
            y: -z,
            z: -x
        };
    }

    // проверка на утечку памати
    protected checkMemory(): void {
        console.log(this.renderer.info.memory);
        console.log(this.renderer.info.render.triangles, this.renderer.info.render.lines);
    }

    // координаты на сцене в координаты html поля
    protected toScreenCoords(point: THREE.Group<THREE.Object3DEventMap>) {
        const vector = new THREE.Vector3();

        vector.setFromMatrixPosition(point.matrixWorld);
        vector.project(this.camera);

        const halfSize = this.renderer.domElement.width / window.devicePixelRatio;

        return {
            x: ((0.5 + vector.x / 2) * halfSize).toFixed(1),
            y: ((0.5 - vector.y / 2) * halfSize).toFixed(1)
        };
    }

    // повернуть объект на сцене относительно юзера
    protected setConvertedRotation(
        subject: THREE.Group<THREE.Object3DEventMap>,
        selfCourse: number,
        subjectCourse: number = 0,
        subjectPitch: number = 90
    ): void {
        const userCourseRad = THREE.MathUtils.degToRad(selfCourse);
        const objectCourseRad = THREE.MathUtils.degToRad(subjectCourse);
        const objectPitchRad = THREE.MathUtils.degToRad(subjectPitch-90);
    
        const relativeCourse = objectCourseRad - userCourseRad;
    
        subject.rotation.set(objectPitchRad, relativeCourse, 0);
    }
}

export default Three;