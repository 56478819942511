import React, { useEffect, useState, useRef, createRef } from "react";
import { useIsMouseEvent } from '../../hooks';

import './MoveStick.scss';

type TStickPosition = {
    x: number;
    y: number;
    startX: number;
    startY: number;
    canMove: boolean;
}

export type TMoveStick = {
    upHandler: () => void;
    downHandler: () => void;
    leftHandler: () => void;
    rightHandler: () => void;
    stopHandler: () => void;
}

const MoveStick: React.FC<TMoveStick> = (props: TMoveStick) => {
    const { upHandler, downHandler, leftHandler, rightHandler, stopHandler } = props;
    const [stickPosition, setStickPosition] = useState<TStickPosition>({ x: 0, y: 0, startX: 0, startY: 0, canMove: false });
    const [currentCommand, setCurrentCommand] = useState<string>('stop');
    const firstUpdate = useRef<boolean>(true);
    const stickRef = createRef<HTMLDivElement>();

    const isMouseEvent = useIsMouseEvent();

    useEffect(() => {
        // проверка на первый рендер (не вызваем событие стоп при монтировании компонента)
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        switch (currentCommand) {
            case 'up': return upHandler();
            case 'down': return downHandler();
            case 'right': return rightHandler();
            case 'left': return leftHandler();
            case 'stop':
            default: return stopHandler();
        }
    }, [currentCommand])

    const getEventCoords = (e: any) => isMouseEvent(e) ? e : e.touches[0];

    const onTouchStart = (e: any) => {
        isMouseEvent(e) && e.preventDefault();
        const { clientX: startX, clientY: startY } = getEventCoords(e);
        setStickPosition({ x: 0, y: 0, startX, startY, canMove: true });
        stickRef?.current?.classList.add('activeStick');
    }

    const onTouchMove = (e: any) => {
        if (isMouseEvent(e)) {
            e.preventDefault();
            e.stopPropagation();
            if (!stickPosition.canMove) {
                return;
            }
        }
        const { clientX, clientY } = getEventCoords(e);
        let { x, y, startY, startX } = stickPosition;
        x = clientX - startX;
        y = startY - clientY;
        // максимальная длиння дижения стика от центра
        const delta = window.outerHeight / 7;
        // вычисляем длину вектора от начала до места в котором находится палец
        const l = Math.sqrt(x ** 2 + y ** 2);
        if (l > delta) {
            // не даем стику выйти за область круга
            x /= (l / delta);
            y /= (l / delta);
        }
        setCurrentCommand(x > y
            ? (x + y > 0) ? 'right' : 'down'
            : (x + y > 0) ? 'up' : 'left'
        );
        setStickPosition({ x, y, startX, startY, canMove: true });
    }

    const onTouchEnd = () => {
        //isMouseEvent(e) && e.preventDefault();
        setStickPosition(prevState => {
            prevState.x = 0;
            prevState.y = 0;
            return prevState;
        });
        setCurrentCommand('stop');
        stickRef?.current?.classList.remove('activeStick');
    }

    return (
        <div className="moveStickOuter">
            <div className="moveStickInner" >
                <div
                    ref={stickRef}
                    className="stick"
                    style={{
                        left: `${stickPosition.x}px`,
                        bottom: `${stickPosition.y}px`
                    }}
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                    onTouchMove={onTouchMove}
                    onMouseDown={onTouchStart}
                    onMouseUp={onTouchEnd}
                    onMouseMove={onTouchMove}
                    onMouseLeave={onTouchEnd}
                ></div>
            </div>
        </div>
    );
}

export default MoveStick;