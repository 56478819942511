import { TCanvasPoint } from "../services/three/index";

type Angle = {
    [K in keyof TCanvasPoint as `angle${Capitalize<K>}`]: TCanvasPoint[K];
};

export default function useRotatePoint() {
    return (
        { x: pointX=0, y: pointY=0, z: pointZ=0 }: Partial<TCanvasPoint> = {}, // точка
        { angleX: x=0, angleY: y=0, angleZ: z=0 }: Partial<Angle> = {}, // угол
    ): TCanvasPoint => {
        const cos = (angle:number) => Math.cos(angle);
        const sin = (angle:number) => Math.sin(angle);

        // общая матрица поворота для 3-х осей
        const matrix = [
            [         cos(y)*cos(z),                       -sin(z)*cos(y),                sin(y)    ],
            [sin(x)*sin(y)*cos(z)+sin(z)*cos(x), -sin(x)*sin(y)*sin(z)+cos(x)*cos(z), -sin(x)*cos(y)],
            [sin(x)*sin(z)-sin(y)*cos(x)*cos(z),  sin(x)*cos(z)+sin(y)*sin(z)*cos(x),  cos(x)*cos(y)]
        ];

        const newX = matrix[0][0]*pointX + matrix[0][1]*pointY + matrix[0][2]*pointZ;
        const newY = matrix[1][0]*pointX + matrix[1][1]*pointY + matrix[1][2]*pointZ;
        const newZ = matrix[2][0]*pointX + matrix[2][1]*pointY + matrix[2][2]*pointZ;
    
        return {
            x: newX,
            y: newY,
            z: newZ
        };
    }
}