export enum EDIRECTION {
    LEFT = 0,
    RIGHT = 1,
    UP = 2,
    DOWN = 3,
}

export enum ESTATUS {
    WALK = 0, // идти
    CLOSE = 1, // закрывать люк
    OPEN = 2, // открывать люк
    USE = 3, // использовать оборудование
    STAND = 4, // стоять
}

// Виды повреждений, поддерживаемых лодкой
export enum EDAMAGE {
    HOLE = 0, // пробоина
    FIRE = 1, // пожар
}

// свойство оборудования
export type TProperty = {
    id: number;
    value: number;
    name: string;
    cost: number;
    upgrade: number;
}

// оборудование
export type TEquipment = {
    type: string;
    consumption: number;
    id: number;
    level: number;
    name: string;
    properties: {
        [key: string]: TProperty
    }
    slot: number;
    weight: number;
    isUsed: boolean;
}

// тип тип субмарины
export type TSubmarineType = {
    id: number;
    level: number;
    name: string;
    slots: number;
    description: string;
    cost: number;
}

// повреждение лодки
export type TDamage = {
    id: number; // идентификатор, чтобы не путалось
    type: EDAMAGE; // тип повреждения (пробоина или пожар)
    value: number; // величина повреждения. Если уменьшится до нуля и меньше, значит повреждение устранено
    x: number; // расположение повреждения
    y: number;
    slot: number; // номер отсека, в котором получили повреждение
}

// субмарина
export type TSubmarine = TSubmarineType & {
    id: number;
    guid: string;
    equipments: TEquipment[];
    accelerator: number; // значение ускорения
    currentAccelerator: number; // значение ускорения
    courseSteer: number; // угол поворота руля
    pitchSteer: number; // угол поворота вертикальных рулей (тангаж)
    course: number; // значение курса поворота лодки
    pitch: number; // значение тангажа лодки
    speed: number; // значение скорости
    x: number; // расположение лодки по x
    y: number; // расположение лодки по y
    z: number; // расположение лодки по z
    damages: TDamage[]; // список повреждений лодки
    cargo: number; // груз снежинок в субмарине
}

export type TUser = {
    guid: string;
    name: string;
    rank: number;
    rating: number;
    submarines: TSubmarine[];
    money: number;
    isPasswordSet: boolean;

    // левый нижний угол персонажа
    x: number;
    y: number;
    direction: EDIRECTION;
    status: ESTATUS;
}

export type TStat = {
    name: string;
    points: number;
};

export type TStats = {
    ranks: TStat[];
    ratings: TStat[];
    submarineTypes: TSubmarineType[];
}

export type TSailor = Omit<TUser, 'submarines'> & {
    guid: string;
}

export type TCrew = {
    guid: string;
    captain: TSailor;
    sailors: TSailor[];
}

export type TGameSubmarine = TSubmarine & Omit<TCrew, 'guid'>;

export type TBoom = {
    guid: string;
    level: number;
    x: number;
    y: number;
    z: number;
    timestamp: number;
};
export type TSnowflake = {
    x: number;
    y: number;
    z: number;
    value: number;
};
export type TSubmarineShort = Omit<
    Omit<
        Omit<
            Omit<
                Omit<
                    Omit<
                        Omit<TSubmarine, 'name'>,
                        'slots'>,
                    'id'>,
                'equipments'>,
            'accelerator'>,
        'currentAccelerator'>,
    'pitchSteer'> & Omit<TCrew, 'sailors'>;

export type TTorpedo = {
    submarineGuid: string;
    guid: string;
    level: number;
    x: number;
    y: number;
    z: number;
    courseSteer: number;
    pitch: number;
    course: number;
    speed: number;
    range: number;
    power: number;
};

type TBot = TSubmarineShort & { name: string };

export type TSceneUpdate = {
    booms: TBoom[];
    snowflakes: TSnowflake[];
    submarines: TSubmarineShort[];
    torpedoes: TTorpedo[];
    bots: TBot[];
};