import React, { useEffect } from "react";
import { TCrew, TUser } from "../../../../../../services/server/types";
import SailorDetailed from "./SailorDetailed/SailorDetailed";

interface ICrewListWide {
    crew: TCrew;
    user: TUser|null;
    closeWideListHandler: () => void;
}

const CrewListWide: React.FC<ICrewListWide> = (props: ICrewListWide) => {
    const { crew, user, closeWideListHandler } = props;

    useEffect(() => () => closeWideListHandler()); // =)

    const { captain, sailors } = crew;

    // если капитан, то есть возможность кикать
    const isKickButton = captain.guid === user?.guid;

    const crewCaptain = <SailorDetailed
        sailor={captain} 
        isCaptain={true} 
        user={user} 
        isKickButton={isKickButton}
    />;
    const crewSailors = sailors.map((sailor, index) => 
        <SailorDetailed 
            key={index} 
            sailor={sailor} 
            user={user} 
            isKickButton={isKickButton}
        />
    );

    return (
        <div className="crew-manager__popup-window">
            <button 
                className="crew-manager__close-button"
                onClick={closeWideListHandler}
            />
            <h2>Моя команда</h2>
            <div className="popup-window__list">
                { crewCaptain }
                { crewSailors }
            </div>
        </div>
    );
}

export default CrewListWide;