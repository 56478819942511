import React, { useState, useEffect } from "react";

import './Popup.scss';

type TPopup = {
    title: string;
    text: string;
    timeout?: number;
    classes?: string;
    cb?: () => void;
}

const Popup: React.FC<TPopup> = (props: TPopup) => {
    const { title, text, timeout = 3000, cb = () => { }, classes = '' } = props;
    const [show, setShow] = useState<Boolean>(true);

    useEffect(() => {
        const messageTimeout = setTimeout(() => {
            setShow(false);
            cb();
        }, timeout);

        return () => clearTimeout(messageTimeout);
    });

    // не показываем ничего
    if (!show) {
        return (<></>);
    }

    return (<div className={'popup popup__' + classes} >
        <div className="popup__icon" />
        <div className="popup__message">
            <h2>{title}</h2>
            <h3>{text}</h3>
        </div>
    </div>)
}

export default Popup;