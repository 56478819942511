import CONFIG from "../config";
import { Server } from "../services/server";
import { Mediator } from "../services/mediator";
import { TGameSubmarine, TUser } from "../services/server/types";
import Interior from "./Interior/Interior";
import Equipment, { TFiringTorpedo } from "./Equipment/Equipment";
import Exterior from "./Exterior/Exterior";

export type TInteriorGame = {
    server: Server;
    mediator: Mediator;
    submarine: TGameSubmarine;
    user: TUser | null;
}

interface IInteriorGame {
    interval: NodeJS.Timer | null;
}

export default class InteriorGame implements IInteriorGame {
    interval: NodeJS.Timer | null = null;
    interior: Interior;
    exterior: Exterior;
    equipment: Equipment;
    mediator: Mediator;
    submarine: TGameSubmarine;

    constructor(options: TInteriorGame) {
        const { mediator, submarine } = options;
        const { INTERIOR_TIMESTAMP } = CONFIG.GAME;
        this.mediator = mediator;
        this.submarine = submarine;
        this.interior = new Interior(options);
        this.exterior = new Exterior(options);
        this.equipment = new Equipment(options);

        const { GET_SUBMARINE, GET_TORPEDO } = mediator.getTriggerTypes();
        mediator.set(GET_SUBMARINE, () => this.get());
        mediator.set(GET_TORPEDO, () => this.getTorpedo());

        // start scene updating
        this.startInterval(INTERIOR_TIMESTAMP);
    }

    // деструктор (ВНЕЗАПНО!!!)
    destructor(): void {
        this.stopInterval();
        this.interior.destructor();
        this.equipment.destructor();
        this.exterior.destructor();
    }

    /********************************/
    /* старт и стоп обновления сцен */
    stopInterval(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    startInterval(timestamp: number): void {
        this.stopInterval()
        this.interval = setInterval(() => this.updateGameScenes(), timestamp);
    }
    /********************************/

    // обновление игровых сцен
    updateGameScenes(): void {
        this.interior.update();
        this.equipment.update();
        this.exterior.update();
        this.mediator.call(this.mediator.getEventTypes().UPDATE_GAME_SCENES);
    }

    get(): TGameSubmarine {
        return this.submarine;
    }
    getTorpedo(): TFiringTorpedo {
        return this.equipment.getTorpedo();
    }
}