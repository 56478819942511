import React from "react";
import { EDIRECTION } from "../../../../../services/server/types";
import MoveButton from "./MoveButton";

import './MovePad.scss';

interface IMovePad {
    upHandler: () => void;
    downHandler: () => void;
    leftHandler: () => void;
    rightHandler: () => void;
    stopHandler: () => void;
}

const MovePad: React.FC<IMovePad> = (props: IMovePad) => {
    const { upHandler, downHandler, leftHandler, rightHandler, stopHandler } = props;

    return (
        <div className="movePadOuter">
            <div className="movePadBtnWrapper">
                <div className="movePadBtnRow">
                    <MoveButton classes="movePadBtn" direction={EDIRECTION.UP} moveHandler={upHandler} stopHandler={stopHandler} />
                    <MoveButton classes="movePadBtn" direction={EDIRECTION.RIGHT} moveHandler={rightHandler} stopHandler={stopHandler} />
                </div>
                <div className="movePadBtnRow">
                    <MoveButton classes="movePadBtn" direction={EDIRECTION.LEFT} moveHandler={leftHandler} stopHandler={stopHandler} />
                    <MoveButton classes="movePadBtn" direction={EDIRECTION.DOWN} moveHandler={downHandler} stopHandler={stopHandler} />
                </div>
            </div>
        </div>
    );
}

export default MovePad;