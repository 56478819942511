import { TCanvasPoint } from "../services/three/index";
import usePointRotation from "./useRotatePoint";
import { TGameSubmarine, TTorpedo, TBoom, TSubmarineShort, TSnowflake } from "../services/server/types";

export default function useCalculateCoordinates() {
    const rotatePoint = usePointRotation();
    return (
        target: TTorpedo|TBoom|TSubmarineShort|TSnowflake,
        object: TGameSubmarine|TTorpedo, 
        range: number
    ): TCanvasPoint|null => {
        const deltaX = target.x - object.x;
        const deltaY = target.y - object.y;
        const deltaZ = target.z - object.z;

        // расстояние до лодки пользователя
        const distance = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2) + Math.pow(deltaZ, 2));

        // если таргет не вошел в зону действия радара
        if (distance > Number(range)) {
            return null;
        }

        const radians = (angle:number) => angle / 180 * Math.PI;

        // TODO здесь должен быть pitchSteer???
        //const beta = radians(submarine.pitch-90); // Поворот вокруг оси Y
        const gamma = radians(-object.course); // поврот вокруг оси Z

        // нашли повернутую точку
        const deltaRotated = rotatePoint({
            x: deltaX,
            y: deltaY,
            z: deltaZ
        }, { 
            //angleX: beta,
            angleZ: gamma,
        });

        return {
            x: deltaRotated.x,
            y: deltaRotated.y,
            z: deltaRotated.z,
        }
    }
}