const guid = "7bb0ebbc-448b-20b0-6739-1da051c946ae";
const guid2 = "7bb0ebbc-448b-20b0-6739-1da151c946ae";
//const guid3 = "7bb0ebbc-448b-20b0-6739-1da150c946ae";
const controlEquipmentId = 7;

const user = {
    guid,
    name: "тестовый юзер основной",
    rank: 2378,
    rating: -67,
}
const user2 = {
    guid: guid2,
    name: "тестовый юзер второй",
    rank: 21378,
    rating: 167,
}
/*
const user3 = {
    guid: guid3,
    name: "тестовый юзер третий",
    rank: 21378,
    rating: 127,
}
*/

const submarineLevel1 = {
    equipments: [
        {
            consumption: -10,
            id: 8,
            level: 1,
            name: "Реактор 1",
            properties: {},
            slot: 2,
            type: 'reactor',
            weight: 100,
        }, {
            consumption: 10,
            id: controlEquipmentId,
            level: 1,
            name: "Блок управления",
            properties: {
                ammo: {
                    name: "Запас торпед",
                    value: 4,
                },
                'locator range': {
                    name: "Дальность обнаружения (м)",
                    value: 300,
                },
                'weapon speed': {
                    name: "Скорость торпеды (уз)",
                    value: 40,
                },
                'weapon range': {
                    name: "Дальность торпед (м)",
                    value: 250,
                },
                'weapon power': {
                    name: "Мощность торпед (кг)",
                    value: 200,
                },
            },
            slot: 1,
            type: 'weapon',
            weight: 500,
        }
    ],
    id: 4,
    level: 1,
    name: "Одноместная",
    slots: 2,
};

const autoLoginData = {
    ...user,
    submarines: [
        submarineLevel1
    ]
};

const gameStatsData = {
    ranks: [
        { name: 'Матрос', points: 8 },
        { name: 'Старший матрос', points: 16 },
        { name: 'Старшина 2-й статьи', points: 32 },
        { name: 'Старшина 1-й статьи', points: 64 },
        { name: 'Главный старшина', points: 128 },
        { name: 'Главный корабельный старшина', points: 256 },
        { name: 'Мичман', points: 512 },
        { name: 'Старший мичман', points: 1024 },
        { name: 'Младший лейтенант', points: 2048 },
        { name: 'Лейтенант', points: 4096 },
        { name: 'Старший лейтенант', points: 8192 },
        { name: 'Капитан-лейтенант', points: 16384 },
        { name: 'Капитан III ранга', points: 32768 },
        { name: 'Капитан II ранга', points: 65536 },
        { name: 'Капитан I ранга', points: 131072 },
        { name: 'Контр-адмирал', points: 262144 },
        { name: 'Вице-адмирал', points: 524288 },
        { name: 'Адмирал', points: 1048576 },
        { name: 'Адмирал флота', points: 2097152 },
        { name: 'Генерал-адмирал', points: 4194304 },
    ],
    ratings: [
        { name: 'Вор в законе', points: -512 },
        { name: 'Бандит', points: -256 },
        { name: 'Разбойник', points: -128 },
        { name: 'Грабитель', points: -64 },
        { name: 'Преступник', points: -32 },
        { name: 'Хулиган', points: -16 },
        { name: 'Шалун', points: -8 },
        { name: 'Безобидный', points: 0 },
        { name: 'Хороший', points: 8 },
        { name: 'Добрый', points: 16 },
        { name: 'Прилежный', points: 32 },
        { name: 'Пионер', points: 64 },
        { name: 'Благочестивый', points: 128 },
        { name: 'Праведник', points: 256 },
        { name: 'Святой', points: 512 },
    ],
    submarineTypes: [
        { name: 'Одноместная', level: 1, slots: 2 },
        { name: 'Двухместная', level: 2, slots: 3 },
        { name: 'Трёхместная', level: 3, slots: 5 },
        { name: 'Четырёхместная', level: 4, slots: 7 },
        { name: 'Пятиместная', level: 5, slots: 9 },
    ],
};

const startGameData = {
    ...submarineLevel1,
    captain: {
        ...user,
        direction: 0,
        status: 4,
        x: 8,
        y: 5.251,
    },
    guid,
    sailors: [],
}

const useEquipment = {
    guid,
    equipmentId: controlEquipmentId,
}

const sceneUpdate = {
    booms: [
        //{
        //    guid,
        //    level: 1,
        //    x: -420.3968461435963,
        //    y: -95,
        //    z: 25.243499730119296
        //}
    ],
    snowflakes: [
        {
            value: 160,
            x: -427.3968461435963,
            y: -120.69002913482171,
            z: 29.243499730119296
        }
    ],
    submarines: [
        {
            guid,
            captain: user,
            level: 1,
            courseSteer: 0,
            // положение лодки
            course: 0,
            pitch: 90,
            speed: 0,
            x: -423.3968461435963,
            y: -97.69002913482171,
            z: 25.243499730119296,
        }, {
            guid: guid2,
            captain: user2,
            level: 1,
            courseSteer: 15,
            // положение лодки
            course: -65,
            pitch: 90,
            speed: 17,
            x: -193.3968461435963,
            y: 97.69002913482171,
            z: 25.243499730119296,
        }
    ],
    torpedoes: [
        {
            guid: guid2,
            level: 1,
            courseSteer: 10,
            pitch: 90,
            course: 22,
            speed: 5,
            range: 5,
            x: -427.3968461435963,
            y: -120.69002913482171,
            z: 29.243499730119296
        },
        {
            guid: guid2,
            level: 1,
            courseSteer: 10,
            pitch: 90,
            course: 22,
            speed: 5,
            range: 5,
            x: -457.3968461435963,
            y: -140.69002913482171,
            z: 29.243499730119296
        }
    ],
}

export default {
    autoLoginData,
    gameStatsData,
    startGameData,
    useEquipment,
    sceneUpdate,
}