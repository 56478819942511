import React from "react";
import { TSubmarineControlPanel } from '../ControlPanel';
import Sonar from "../Sonar/Sonar";
//import SonarOld from "../SonarOld/SonarOld";
import Speedometer from "../Speedometer/Speedometer";
import ControlModule from "../ControlModule/ControlModule";
import Frame from "../Frame/Frame";

const Submarine2ControlPanel: React.FC<TSubmarineControlPanel> = (props: TSubmarineControlPanel) => {
    const { submarine, equipment } = props;

    return (
        <div className="control-panel control-panel_move-panel">
            <ControlModule submarine={submarine}/>
            <Sonar 
                submarine={submarine} 
                equipment={equipment}
            />
            <div className="control-panel__action-module">
                <Speedometer submarine={submarine}/>
            </div>
            <Frame />
        </div>
    );
}

export default Submarine2ControlPanel;