import { SHA3 } from 'sha3';
// @ts-ignore
import crypto from 'asymmetric-crypto';
import CryptoJS from 'crypto-js';
import io, { Socket } from 'socket.io-client';

import { TSTORAGE } from '../../config';
import { Mediator, TNamesArray } from '../mediator';
import { EDIRECTION, ESTATUS, EDAMAGE, TUser } from './types';
import mocks from './mocks';

export type TERROR = {
    code: number,
    text: string
}

type TERRORS = {
    [key: string]: TERROR,
}

export type TUseServer = {
    MESSAGES: TNamesArray,
    HOST: string,
    STORAGE: TSTORAGE,
    mediator: Mediator,
}

type TMESSAGEON = {
    result: 'ok' | 'error',
    data?: string,
    error?: {
        code: number,
        text: string,
    }
}

interface IServer {
    gameStats: () => void;
    createCrew: () => void;
    dropCrew: () => void;
    crewsList: () => void;
    joinToCrew: (data: { crewId: string }) => void;
    leaveCrew: (data: { crewId: string }) => void;
    dropFromCrew: (data: { dropGuid: string }) => void;
    startGame: (submarineId: number) => void;
    submarineInit: () => void;
    submarineCancel: () => void;
    sailorMove: (data: { direction?: EDIRECTION, status: ESTATUS, x: number, y: number }) => void;
    sailorOpenCloseHatch: (data: { index: number, open: boolean }) => void;
    useEquipmentStart: (data: { equipmentId: number, guid: string }) => void;
    useEquipmentStop: (data: { equipmentId: number }) => void;
    setAccelerator: (data: { value: number }) => void;
    setCourseSteer: (data: { value: number }) => void;
    setPitchSteer: (data: { value: number }) => void;
    speedChange: (data: { value: number }) => void;
    shotTorpedo: () => void;
    torpedoDetonation: () => void;
    setTorpedoCourseSteer: (data: { value: number }) => void;
    setTorpedoPitchSteer: (data: { value: number }) => void;
    setSubmarineDamage: (data: { id?: number, type: EDAMAGE, value: number, x: number, y: number, slot: number }) => void;
    buySubmarine: (level: number) => void;
    buyPropertyUpgrade: (data: { submarineId: number, equipmentId: number, propertyId: number }) => void;
    setPassword: (data: { password: string }) => void;
    updatePassword: (data: { oldPassword: string, newPassword: string }) => void;
    login: (data: { guid: string, password: string }) => void;
}

export default class Server implements IServer {
    private ERRORS: TERRORS = {
        GET_PUBLIC_KEY: {
            code: 2100,
            text: 'Error to getting public key from server'
        },
        DECRYPT_MESSAGE: {
            code: 2200,
            text: 'Error decrypt on message'
        },
        ENCRYPT_MESSAGE: {
            code: 2300,
            text: 'Error encrypt emit message'
        },
        UNDEFINED: {
            code: 9000,
            text: 'Undefined error'
        },
    };
    private mediator: Mediator;
    private STORAGE: TSTORAGE;
    private MESSAGES: TNamesArray;
    private guid: string | null = null; // guid юзера (постоянный)
    private token: string | null = null; // сессионный токен юзера
    private temporalToken: string | null = null; // локальный токен
    //private publicKey: string | null = null; // публичный ключ, который присылается сервером при установлении соединения
    private temporalSessionKey: string | null = null; // локальный сессионный ключ
    private sessionKey: string | null = null; // сессионный ключ, пригодный для шифрования
    private hash: SHA3<256> = new SHA3(256); // хеширующая функция
    private socket: Socket;

    private useMocks: boolean;

    constructor(options: TUseServer) {
        const { HOST, MESSAGES, STORAGE, mediator } = options;
        this.mediator = mediator;
        this.STORAGE = STORAGE; // список объектов, хранимых в хранилище
        this.MESSAGES = MESSAGES;

        const searchParams = new URLSearchParams(document.location.search);

        this.useMocks = searchParams.get('test') === 'game';

        // автологин, чтобы сработал
        if (this.useMocks) {
            setTimeout(() => {
                this.mediator.call(this.MESSAGES.AUTO_LOGIN, mocks.autoLoginData);
                setTimeout(() => this.gameStats(), 150);
            }, 150);
        }
        // проинициализировать сокеты
        console.log(`try to connect to socket ${HOST}`);
        this.socket = io(`${HOST}`);
        // соединение поднялось. проверить локальные данные и зарегистрировать/авторизовать пользователя
        this.socket.on('connect', () => this._connectOn());
        // получить из бекенда публичный ключ, для формирования сессионного ключа
        this.socket.on(this.MESSAGES.GET_PUBLIC_KEY, data => this._getPublicKeyOn(data));
        // ответ из бекенда о получении сессионного ключа
        this.socket.on(this.MESSAGES.SET_SESSION_KEY, data => this._setSessionKeyOn(data));
        // ответ об автоматической регистрации
        this.socket.on(this.MESSAGES.AUTO_REGISTRATION, data => this._autoRegistrationOn(data));
        // ответ об автоматической авторизации
        this.socket.on(this.MESSAGES.AUTO_LOGIN, data => this._autoLoginOn(data));
        // ответ об обычной авторизации
        this.socket.on(this.MESSAGES.LOGIN, data => this._loginOn(data));

        // ответ об изменении данных пользователя
        this.socket.on(this.MESSAGES.USER_UPDATE_INFO, data => this._userUpdateInfo(data));
        this.socket.on(this.MESSAGES.GAME_STATS, data => this._gameStats(data))
    }

    _socketOnError(error: TERROR): void {
        const { SOCKET_ON_ERROR } = this.mediator.getEventTypes();
        this.mediator.call(SOCKET_ON_ERROR, error);
    }

    // создание методов для эмита и подписка на коллбеки в них
    _registerOnAndEmitMethods(): void {
        if (!this.useMocks) { // если НЕ моки, то всё будет работать, в противном случае будут юзаться дефолтные методы
            //console.log('список методов в классе:');
            const capitalizeFirstLetter = (elem: string): string => {
                const [first, ...rest] = Array.from(elem)
                return first.toUpperCase() + rest.join('');
            }
            Object.values(this.MESSAGES).map(name => {
                if (name !== this.MESSAGES.AUTO_REGISTRATION && // автоматическая регистрация отдельно
                    name !== this.MESSAGES.AUTO_LOGIN && // автоматическая авторизация - тоже отдельно
                    name !== this.MESSAGES.LOGIN && // обычная авторизация - тем более отдельно
                    name !== this.MESSAGES.SET_SESSION_KEY && // получение сессионного ключа так же отдельно
                    name !== this.MESSAGES.GET_PUBLIC_KEY
                ) {
                    const methodName = name.toLowerCase()
                        .split('_')
                        .map((elem, index) => index === 0 ? elem : capitalizeFirstLetter(elem))
                        .join('');
                    // @ts-ignore
                    this[methodName] = (data = {}) => this.messageEmit(this.MESSAGES[name], data); // записываем эмитный метод
                    this.socket.on(this.MESSAGES[name], data => {
                        const encrypt = this._checkMessageOn(data);
                        if (encrypt) {
                            try {
                                if (this.sessionKey) {
                                    const decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypt, this.sessionKey).toString(CryptoJS.enc.Utf8));
                                    this.mediator.call(name, decrypted);
                                }
                            } catch (e) {
                                console.log(e);
                                this._socketOnError(this.ERRORS.DECRYPT_MESSAGE);
                            }
                        }
                    }); // записываем этот метод в прослушку сокета
                    //console.log(methodName);
                }
                return true;
            });
        }
    }

    // все варианты с автоматическими регистрацией и авторизацией
    _autoRegisterAndLogin(): void {
        // возможные варианты
        if (this.guid && this.token) { // все данные есть, можно пытаться автоматически авторизоваться
            this._autoAuthEmit();
            return;
        }
        // TODO проверить кейс, когда есть guid, но нет token
        //...
        // зарегистрировать нового пользователя
        this._autoRegistrationEmit(); // автоматически регистрируемся
    }

    // сгенерировать сессионный ключ. Каждый раз разный и сложный
    _generateSessionKey(): string {
        const text = `${Math.round(Math.random() * 10000000)}${this.genGuid()}`;
        return this._getHash(text);
    }

    // хеширующая функция, поддерживающая ключ. Ключом будет токен
    _getHash(text: string, withoutToken: boolean = false): string {
        this.hash.reset();
        if (withoutToken) {
            this.hash.update(text);
        } else if (this.token) {
            this.hash.update(this.token).update(text);
        } else {
            this.hash.update(text);
        }
        return this.hash.digest('hex');
    }

    _checkMessageOn(data: TMESSAGEON) {
        if (data?.result === 'ok') {
            return data.data;
        }
        this._socketOnError(data?.error || this.ERRORS.UNDEFINED);
    }

    /*******************/
    /* Support methods */
    /*******************/
    // генерирует guid
    genGuid(): string {
        function s4() { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    // возвращает контрольную сумму хеша, сформированную по правилу hash = sha3(строка(guid=значение;rnd=значение), token)
    getHash(params: TNamesArray = {}): string {
        return this._getHash(
            Object.keys(params).sort().reduce(
                (acc, key) => acc += params[key] ? `${key}=${params[key]};` : '',
                ''
            )
        );
    }

    /*****************/
    /* Socket events */
    /*****************/
    // отправить любое сообщение в бекенд
    messageEmit(message: string, params: TNamesArray = {}, _rnd?: number): void {
        if (this.guid && this.token) {
            const rnd = _rnd || Math.round(Math.random() * 1000000);
            const hash = this.getHash({ guid: this.guid, rnd: `${rnd}`, ...params });
            try {
                if (this.sessionKey) {
                    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(params), this.sessionKey).toString();
                    this.socket.emit(message, { guid: this.guid, rnd, hash, encrypted });
                }
            } catch (e) {
                console.log(e);
                this._socketOnError(this.ERRORS.ENCRYPT_MESSAGE);
            }
        }
    }

    _connectOn(): void {
        console.log(`connected!`);
        const { GET_STORAGE } = this.mediator.getTriggerTypes();
        const { TOKEN, GUID } = this.STORAGE;
        this.token = this.mediator.get(GET_STORAGE, TOKEN);
        this.guid = this.mediator.get(GET_STORAGE, GUID);
    }

    _getPublicKeyOn(data: TMESSAGEON): void {
        const publicKey = this._checkMessageOn(data);
        if (publicKey) {
            try {
                const myKeys = crypto.keyPair();
                this.temporalSessionKey = this._generateSessionKey();
                const encrypted = crypto.encrypt(this.temporalSessionKey, publicKey, myKeys.secretKey);
                this.socket.emit(this.MESSAGES.SET_SESSION_KEY, { encrypted, publicKey: myKeys.publicKey });
            } catch (e) {
                console.log(e);
                this._socketOnError(this.ERRORS.GET_PUBLIC_KEY);
            }
        }
    }

    _setSessionKeyOn(data: TMESSAGEON): void {
        if (this._checkMessageOn(data)) {
            console.log('sessionKey generated' /*, this.temporalSessionKey*/);
            this.sessionKey = this.temporalSessionKey; // записываем ключ в контекст, чтобы им могли пользоваться остальные методы
            this.temporalSessionKey = null;
            this._registerOnAndEmitMethods();
            this._autoRegisterAndLogin();
        }
    }

    /*********************/
    /* AUTO_REGISTRATION */
    /*********************/
    _autoRegistrationEmit(): void {
        this.token = 'default token'; // обнулить на всякий
        this.guid = this.genGuid(); // сгенерировать guid
        this.messageEmit(this.MESSAGES.AUTO_REGISTRATION, { guid: this.guid, token: this.token });
    }

    _autoRegistrationOn(data: TMESSAGEON): void {
        const encrypt = this._checkMessageOn(data);
        if (encrypt) {
            try {
                if (this.sessionKey) {
                    const decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypt, this.sessionKey).toString(CryptoJS.enc.Utf8));
                    if (decrypted?.result) {
                        this._autoAuthEmit();
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    /**************/
    /* AUTO_LOGIN */
    /**************/
    _autoAuthEmit(): void {
        if (this.guid) {
            const rnd = Math.round(Math.random() * 1000000);
            this.temporalToken = this._getHash(`${this.guid}${this.token}${rnd}`);
            this.messageEmit(this.MESSAGES.AUTO_LOGIN, { guid: this.guid, rnd: `${rnd}` });
        }
    }

    // успешный автологин случился
    _autoLoginOn(data: TMESSAGEON): void {
        const encrypt = this._checkMessageOn(data);
        if (encrypt) {
            try {
                if (this.sessionKey) {
                    const decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypt, this.sessionKey).toString(CryptoJS.enc.Utf8));
                    if (decrypted) {
                        const { guid } = decrypted;
                        const { SET_STORAGE } = this.mediator.getTriggerTypes();
                        const { TOKEN, GUID, USER } = this.STORAGE;

                        console.log('Успешный автологин!', decrypted);

                        this.token = this.temporalToken; // перезаписать токен, потому что авторизация успешно случилась и темповый токен теперь основной
                        this.mediator.get(SET_STORAGE, { NAME: TOKEN, value: this.token });
                        this.mediator.get(SET_STORAGE, { NAME: GUID, value: guid });
                        this.mediator.get(SET_STORAGE, { NAME: USER, value: decrypted });
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    /*********/
    /* LOGIN */
    /*********/
    login(data: { guid: string, password: string }): void {
        const { guid, password } = data;
        this.guid = guid;
        if (guid && password) {
            const rnd = Math.round(Math.random() * 1000000);
            const passwordHash = this._getHash(`${guid}${password}`, true); // хеш пароля, который в базе
            this.temporalToken = this._getHash(`${passwordHash}${rnd}`, true); // сформировать новый токен
            this.token = this.temporalToken; // принудительно в токен записать временный токен
            this.messageEmit(this.MESSAGES.LOGIN, { guid, rnd: `${rnd}` }, rnd);
        }
    }

    _loginOn(data: TMESSAGEON): void {
        const encrypt = this._checkMessageOn(data);
        if (encrypt) {
            try {
                if (this.sessionKey) {
                    const decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypt, this.sessionKey).toString(CryptoJS.enc.Utf8));
                    if (decrypted) {
                        const { guid } = decrypted;
                        const { SET_STORAGE } = this.mediator.getTriggerTypes();
                        const { TOKEN, GUID, USER } = this.STORAGE;

                        console.log('Успешный логин!', decrypted);

                        this.mediator.get(SET_STORAGE, { NAME: TOKEN, value: this.token });
                        this.mediator.get(SET_STORAGE, { NAME: GUID, value: guid });
                        this.mediator.get(SET_STORAGE, { NAME: USER, value: decrypted });
                    }
                }
            } catch (e) {
                console.log(e);
                this.token = null;
                this.temporalToken = null;
            }
        }
    }

    /********************/
    /* USER_UPDATE_INFO */
    /********************/
    // изменение данных игрока
    _userUpdateInfo(data: TMESSAGEON): void {
        const encrypt = this._checkMessageOn(data);
        if (encrypt) {
            try {
                if (this.sessionKey) {
                    const decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypt, this.sessionKey).toString(CryptoJS.enc.Utf8));
                    if (decrypted) {
                        const { money, rating, rank, submarines, isPasswordSet } = decrypted;
                        const { USER } = this.STORAGE;
                        const { GET_STORAGE, SET_STORAGE } = this.mediator.getTriggerTypes();
                        const user = this.mediator.get<TUser>(GET_STORAGE, USER);
                        if (user) {
                            user.money = isNaN(money) ? user.money : money;
                            user.rating = isNaN(rating) ? user.rating : rating;
                            user.rank = isNaN(rank) ? user.rank : rank;
                            user.submarines = submarines ? submarines : user.submarines;
                            user.isPasswordSet = isPasswordSet;
                            this.mediator.get(SET_STORAGE, { NAME: USER, value: user });
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    // хендлер получения стат данных с сервера
    _gameStats(data: TMESSAGEON): void {
        const encrypt = this._checkMessageOn(data);
        if (encrypt) {
            try {
                if (this.sessionKey) {
                    const decrypted = JSON.parse(CryptoJS.AES.decrypt(encrypt, this.sessionKey).toString(CryptoJS.enc.Utf8));
                    if (decrypted) {
                        const { ranks, ratings, submarineTypes } = decrypted;

                        const { SET_STORAGE } = this.mediator.getTriggerTypes();
                        const { RANKS, RATINGS, SUBMARINE_TYPES } = this.STORAGE;

                        // записать рейтинги и ранги в стор
                        this.mediator.get(SET_STORAGE, { NAME: RANKS, value: ranks });
                        this.mediator.get(SET_STORAGE, { NAME: RATINGS, value: ratings });
                        this.mediator.get(SET_STORAGE, { NAME: SUBMARINE_TYPES, value: submarineTypes });

                        console.log('список рангов', ranks);
                        console.log('список рейтингов', ratings);
                        console.log('список типов лодок', submarineTypes);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    // чтобы этот ТС подавился поленом
    // так же здесь прописана логика дефолтного старта игры на моках
    gameStats(): void {
        this.mediator.call(this.MESSAGES.GAME_STATS, mocks.gameStatsData);
    }
    createCrew(): void { }
    dropCrew(): void { }
    crewsList(): void { }
    joinToCrew(data: { crewId: string }): void { }
    leaveCrew(data: { crewId: string }): void { }
    dropFromCrew(data: { dropGuid: string }): void { }
    startGame(submarineId: number): void {
        this.mediator.call(this.MESSAGES.START_GAME, mocks.startGameData);
        this.submarineInit();
    }
    submarineInit(): void {
        setTimeout(() => this.mediator.call(this.MESSAGES.SUBMARINE_INIT, mocks.startGameData), 150);
    }
    submarineCancel(): void { }
    sailorMove(data: { direction?: EDIRECTION, status: ESTATUS, x: number, y: number }): void { }
    sailorOpenCloseHatch(data: { index: number, open: boolean }): void { }
    useEquipmentStart(data: { equipmentId: number, guid: string }): void {
        this.mediator.call(this.MESSAGES.USE_EQUIPMENT_START, mocks.useEquipment);
    }
    useEquipmentStop(data: { equipmentId: number }): void {
        this.mediator.call(this.MESSAGES.USE_EQUIPMENT_STOP, mocks.useEquipment);
    }
    setAccelerator(data: { value: number }): void { }
    setCourseSteer(data: { value: number }): void {
        mocks.sceneUpdate.submarines[0].course = data.value;
        this.mediator.call(this.MESSAGES.SCENE_UPDATE, mocks.sceneUpdate);
    }
    setPitchSteer(data: { value: number }): void {
        mocks.sceneUpdate.submarines[0].pitch = data.value;
        this.mediator.call(this.MESSAGES.SCENE_UPDATE, mocks.sceneUpdate);
    }
    speedChange(data: { value: number }): void {
        mocks.sceneUpdate.submarines[0].speed = data.value;
        this.mediator.call(this.MESSAGES.SCENE_UPDATE, mocks.sceneUpdate);
    }
    shotTorpedo(): void { }
    torpedoDetonation(): void { }
    setTorpedoCourseSteer(data: { value: number }): void { }
    setTorpedoPitchSteer(data: { value: number }): void { }
    setSubmarineDamage(data: { id?: number, type: EDAMAGE, value: number, x: number, y: number, slot: number }): void { }
    buySubmarine(level: number): void { }
    buyPropertyUpgrade(data: { submarineId: number, equipmentId: number, propertyId: number }): void { }
    setPassword(data: { password: string }): void { }
    updatePassword(data: { oldPassword: string, newPassword: string }): void { }
}