import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { Entity } from "../entities";

export interface IManager {
    scene: THREE.Scene|null;
    gltfModels: GLTF[],
}

class Manager {
    protected scene;
    protected collection: Map<string, Entity>;
    protected models:  GLTF[];

    constructor(options: IManager) {
        const { scene, gltfModels } = options;

        this.scene = scene;
        this.models = gltfModels;
        
        this.collection = new Map();
    }

    destructor() {
        this.collection.forEach((item, _) => {
            this.scene?.remove(item.mesh);
            item.destructor();
        });
        this.scene = null;
        this.collection.clear();
    }

    public getItem(guid: string) {
        return this.collection.get(guid);
    }

    public createItem({ guid, x, y, z, modelIndex=0, scale=1, isIntersectionCircle=false } : {
        guid: string,
        x:number,
        y:number,
        z:number,
        modelIndex?: number,
        scale?: number,
        isIntersectionCircle?: boolean
    }) {
        if (this.scene) {
            const item = new Entity({
                model: this.models[modelIndex],
                x, y, z,
                scale,
                isIntersectionCircle
            });
            this.collection.set(guid, item);
            this.scene.add(item.mesh);

            return item;
        }
    }

    // оцичтка сцены(canvas) и модулей от элементов, которых УЖЕ НЕТ на сцене(радиус сонара)
    public removeUnnecessaryItems(existItems: Set<string>) {
        this.collection.forEach((item, guid) => {
            if (
                !existItems.has(guid) ||
                existItems.size <= 0
            ) {
                if (this.scene) {
                    this.scene.remove(item.mesh);
                    item.destructor();
                    this.collection.delete(guid);
                }
            }
        });
    }
}

export default Manager;