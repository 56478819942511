import React from "react";
import CrewList from "./CrewList/CrewList";
import CrewManageButton from "./CrewManageButton/CrewManageButton";
import CrewSearch from "./CrewSearch/CrewSearch";

import './CrewManager.scss';

const CrewManager: React.FC = () => {
    return (
        <div className="crew-info__crew-manager">
            <CrewSearch/>
            <CrewManageButton />
            <CrewList />
        </div>
    );
}

export default CrewManager;