import React, { useContext } from "react";

import { MediatorContext } from "../../App";
import CONFIG from "../../config";
import { Exit } from "../../components/svg";
import InteractiveButton from "../../components/InteractiveButton/InteractiveButton";
import Slider from "../../components/Slider/Slider";
import { IBasePage, PAGES } from "../PageManager";

import './Training.scss';

interface ITrainingScreen {
    text: string;
    no: number;
}

const slidesInfo = [
    '',
    'Нам нужно собрать ресурсы. Мы называем их Снежинками Собирай их и отвози на базу. Наши враги мешают нам - они воруют Снежинки!',
    'Вступи в команду или набери её, выбери лодку и отправляйся в плавание!',
    'На лодке встань к пульту и нажми на штурвал. Закрывай люки, чини повреждения.',
    'Перед тобой Сонар. Управляй лодкой, ищи Снежинки, уничтожай ботов',
    'Набрал снежинок - вези их на базу! За них тебе дадут деньги',
    'На деньги ты можешь купить апгрейды или даже новую лодку!',
    'На базе безопасно. Старайся не убивать своих, это нехорошо. Убивай ботов! За них дают опыт, повышающий твой ранг, и приближающий к победе!',
    'Закрывай все люки! Получил повреждение - чини его! Не наступай на огонь - сгоришь, в воде можно утонуть. Плавай вдвоём - один управляет, другой чинит.'
]

const TrainingScreen: React.FC<ITrainingScreen> = (props: ITrainingScreen) => {
    const { text, no } = props;

    return (
        <div className={"training__screen training__screen_" + no}>
            <span>{text}</span>
        </div>
    );
}

const Training: React.FC<IBasePage> = (props: IBasePage) => {
    const { setPage } = props;
    const mediator = useContext(MediatorContext);
    const { SET_STORAGE } = mediator.getTriggerTypes();
    const { IS_TRAINING_COMPLETED } = CONFIG.STORAGE;

    const backHandler = () => {
        mediator.get(SET_STORAGE, { NAME: IS_TRAINING_COMPLETED, value: true });
        setPage(PAGES.LOBBY);
    }

    const slides = slidesInfo.map((text, index) => <TrainingScreen no={index + 1} text={text} />);

    return (
        <div className="training">
            <h2>Обучение</h2>
            <InteractiveButton classes="interactive-button_exit" svg={<Exit />} onClick={backHandler} />
            <Slider content={slides} />
        </div>
    )
}

export default Training;