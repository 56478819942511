import { EDIRECTION, ESTATUS } from '../../../services/server/types';
import { getSailorFrames, getForemanFrames, getMidshipmanFrames, getLieutenantFrames, 
    getCaptainFrames, getAdmiralFrames, getTrusovFrames, getHoleFrame, getFireFrame 
} from './frames';

import sprites from '../../../assets/img/sprites.png';
import interiorImg from '../../../assets/img/interior.png';

type TCount = {
    [key: string]: {
        frame: number,
        timestamp: number,
    };
}

export type TFrames = (id: string, direction: EDIRECTION, status: ESTATUS) => number[];
type TFrame = (id: string) => number[];

// выбрать спрайты для персонажей
const getSpriteFromFrames = (frames: number[][][][]) => {
    const counts: TCount = {};

    return (
        id: string,
        direction: EDIRECTION = EDIRECTION.RIGHT,
        status: ESTATUS = ESTATUS.STAND
    ): number[] => {
        if (id) {
            if (!counts[id]) {
                counts[id] = {
                    frame: 0,
                    timestamp: Date.now(),
                };
            }
            if (status === ESTATUS.STAND) {
                return frames[direction][0][counts[id].frame] ? frames[direction][0][counts[id].frame] : frames[direction][0][0];
            }
            const st = (direction === EDIRECTION.UP || direction === EDIRECTION.DOWN) ? 0 : status;
            const currentTimestamp = Date.now();
            if (currentTimestamp - counts[id].timestamp >= 100) {
                counts[id].timestamp = currentTimestamp;
                if (counts[id].frame >= 0) {
                    counts[id].frame++;
                    if (counts[id].frame >= frames[direction][st].length) {
                        counts[id].frame = 0;
                    }
                }
            }
            // тернарник на случай, если counts[id].frame > frames[direction][st].length
            // ищет в массиве ползущего моряка(4 фрейма), позицию идущего(9 фреймов)
            // а еще без условия бывает вылазит вся карта спрайтов ¯\_(ツ)_/¯
            return frames[direction][st][counts[id].frame] ? frames[direction][st][counts[id].frame] : frames[direction][st][0];
        }
        return [];
    }
}

// взять спрайт для обычной анимации (без дирекшенов и статусов)
const getSpritesFromFrame = (frame: number[][]) => {
    const counts: TCount = {};

    return (id: string): number[] => {
        if (id) {
            if (!counts[id]) {
                counts[id] = {
                    frame: 0,
                    timestamp: Date.now(),
                };
            }
            const currentTimestamp = Date.now();
            if (currentTimestamp - counts[id].timestamp >= 100) {
                counts[id].timestamp = currentTimestamp;
                if (counts[id].frame >= 0) {
                    counts[id].frame++;
                    if (counts[id].frame >= frame.length) {
                        counts[id].frame = 0;
                    }
                }
            }
            return frame[counts[id].frame];
        }
        return frame[0];
    }
}

const useSprites = (SIZE: number): [HTMLImageElement, HTMLImageElement, number[][], TFrames[], TFrame[]] => {
    const img = new Image(); // спрайты персонажей
    img.src = sprites;
    const interior = new Image(); // спрайты интерьера
    interior.src = interiorImg;

    const compartment = [0, 0, SIZE * 5, SIZE * 3]; // спрайт отсека
    const compartmentEmpty = [0, SIZE * 3, SIZE * 5, SIZE * 3]; // спрайт отсека
    const react1 = [SIZE * 5, 0, 95, 164]; // спрайт реактора 1
    const react2 = [SIZE * 5, 164, 111, 164]; // спрайт реактора 2
    const ladder = [SIZE * 5 + 95, 0, 41, 148]; // спрайт лестницы
    const middleDeckReact1 = [SIZE * 5 + 95 + 41 * 2, 0, 294, 47]; // спрайт средней палубы для реактора №1
    const middleDeckReact2 = [SIZE * 5 + 95 + 41 * 2, 79, 294, 47]; // спрайт средней палубы для реактора №1
    const middleDeckReact1Hole = [SIZE * 5 + 95 + 41 * 2, 47, 51, SIZE / 2]; // спрайт для передней вставки средней палубы для реактора №1
    const openHatchLeft = [791, 0, 42, 58];
    const openHatchRight = [859, 0, 42, 58];
    const closeHatchLeft = [791, 58, 42, 58];
    const closeHatchRight = [859, 58, 42, 58];
    const middleHatch = [833, 0, 26, 81]; // передняя перегородка, чтобы загораживать персонажа
    const controlPanel = [901, 0, 58, 49]; // панель управления
    const firePanel2 = [959, 0, 71, 49]; // панель управления стрельбой для лодки №2
    const controlPanel2 = [901, 49, 83, 49]; // панель управления для лодки №2
    const torpedoTube1 = [1030, 63, 81, 67]; // торпедный аппарат лодки №1
    const torpedoTube2 = [1030, 0, 118, 62]; // торпедный аппарат лодки №2
    const torpedoes1 = [1111, 63, 61, 51]; // торпеды на стеллажах лодки №1
    const torpedoes2 = [1148, 0, 88, 46]; // торпеды на стеллажах лодки №2
    const submarine1 = [8, 391, 1108, 323]; // корпус лодки №1
    const submarine2 = [4, 713, 1620, 370]; // корпус лодки №2

    // персонажи
    const sailor = getSpriteFromFrames(getSailorFrames(SIZE)); // матрос
    const foreman = getSpriteFromFrames(getForemanFrames(SIZE)); // старшина
    const midshipman = getSpriteFromFrames(getMidshipmanFrames(SIZE)); // мичман
    const lieutenant = getSpriteFromFrames(getLieutenantFrames(SIZE)); // лейтенант
    const captain = getSpriteFromFrames(getCaptainFrames(SIZE)); // лейтенант
    const admiral = getSpriteFromFrames(getAdmiralFrames(SIZE)); // адмирал
    const trusov = getSpriteFromFrames(getTrusovFrames(SIZE)); // Трусов

    // анимированные элементы интерьера
    const smallHole = getSpritesFromFrame(getHoleFrame(1238, 0, 30, 36));
    const mediumHole = getSpritesFromFrame(getHoleFrame(1268, 0, 32, 64));
    const bigHole = getSpritesFromFrame(getHoleFrame(1300, 0, 62, 99));
    const smallFire = getSpritesFromFrame(getFireFrame(1362, 0, 45, 70));
    const mediumFire = getSpritesFromFrame(getFireFrame(1407, 0, 64, 64));
    const bigFire = getSpritesFromFrame(getFireFrame(1751, 0, 128, 128));

    return [
        img,
        interior,
        [
            compartment,
            compartmentEmpty,
            react1,
            react2,
            ladder,
            middleDeckReact1,
            middleDeckReact2,
            middleDeckReact1Hole,
            openHatchLeft,
            openHatchRight,
            closeHatchLeft,
            closeHatchRight,
            middleHatch,
            controlPanel,
            firePanel2,
            controlPanel2,
            torpedoTube1,
            torpedoTube2,
            torpedoes1,
            torpedoes2,
            submarine1,
            submarine2,
        ], [
            sailor, foreman, midshipman, lieutenant, captain, admiral, trusov
        ], [
            smallHole,
            mediumHole,
            bigHole,
            smallFire,
            mediumFire,
            bigFire,
        ]
    ];
}

export default useSprites;