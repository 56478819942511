import { useEffect, useState } from 'react';

const useIsSafari = (): boolean => {
    // тупой, но без реактовых приблуд
    //return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // стильный, модный, молодёжный
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        const isSafariDiscovered = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafariDiscovered) {
            setIsSafari(true);
        }
    }, [])

    return isSafari;
}

export default useIsSafari;