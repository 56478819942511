import React, { useEffect, useState, useContext } from "react";
import { MediatorContext } from "../../App";
import { useGetRank, useGetRating, useGetUser } from "../../hooks";

import './UserInfoOld.scss';

const UserInfoOld: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const [updateInfo, setUpdateInfo] = useState<number>(1);
    const { USER_UPDATE_INFO } = mediator.getEventTypes();
    const getUser = useGetUser(mediator);

    const user = getUser();
    const getRank = useGetRank(mediator);
    const getRating = useGetRating(mediator);

    useEffect(() => {
        const userUpdateInfoHandler = () => setUpdateInfo(updateInfo + 1);

        mediator.subscribe(USER_UPDATE_INFO, userUpdateInfoHandler);

        return () => {
            mediator.unsubscribe(USER_UPDATE_INFO, userUpdateInfoHandler);
        }
    });

    if (!user) {
        return (<></>);
    }

    const { name, rank, rating, money } = user;

    return (<div className="user-info-old">
        <div className="user-info-old__name">
            <span className="user-info-old-bold">{name}</span>
        </div>
        <div className="user-info-old__rank">
            <span>Ранг: </span>
            <span className="user-info-old-bold">{getRank(rank).name}</span>
        </div>
        <div className="user-info-old__rating">
            <span>Рейтинг: </span>
            <span className="user-info-old-bold">{getRating(rating).name}</span>
        </div>
        <div className="user-info-old__money">
            <span>Монеток: </span>
            <span className="user-info-old-bold">{money}</span>
        </div>
    </div>);
}

export default UserInfoOld;