import React, { useContext, useState, useEffect } from "react";
import { MediatorContext, ServerContext } from "../../../../../App";
import CONFIG from "../../../../../config";
import { TCrew } from "../../../../../services/server/types";
import { useGetUser } from "../../../../../hooks";

interface IManageButton {
    text: string;
    classes?:string;
    handler: () => void;
}

export type TCrewStatus = {
    isInCrew: boolean,
    crewId: string|null;
}

const ManageButton: React.FC<IManageButton> = (props: IManageButton) => {
    const { text, classes = '', handler } = props;

    return (
        <button 
            className={"crew-manager__manage-button " + classes}
            onClick={handler}
        >
            { text }
        </button>
    );
}

const CrewManageButton: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const server = useContext(ServerContext);
    const [crewStatus, setCrewStatus] = useState<string>('create');
    const { CREATE_CREW, DROP_CREW, JOIN_TO_CREW, DROP_FROM_CREW, CLEAR_CREW_LIST } = mediator.getEventTypes();
    const { GET_STORAGE, SET_STORAGE } = mediator.getTriggerTypes();
    const { CREW_STATUS, IS_CREW_OWNER } = CONFIG.STORAGE;
    const getUser = useGetUser(mediator);

    const user = getUser();

    useEffect(() => {
        const createCrewHandler = () => { // создали команду -> кнопка/хэндлер = дропнуть команду
            const isCrewOwner = mediator.get<boolean>(GET_STORAGE, IS_CREW_OWNER);
            if (!isCrewOwner) { // на создание становишься капитаном для сторажда
                mediator.get<boolean>(SET_STORAGE, { NAME: IS_CREW_OWNER, value: true });
            }
            setCrewStatus('drop');
        };

        const dropCrewHandler = () => { // дропнули команду -> кнопка/хэндлер = создать команду
            const isCrewOwner = mediator.get<boolean>(GET_STORAGE, IS_CREW_OWNER);
            const crew = mediator.get<TCrewStatus>(GET_STORAGE, CREW_STATUS);
            if (crew && crew.isInCrew) { // если был в тиме и произошел дроп -> больше не в тиме
                mediator.get<TCrewStatus>(SET_STORAGE, { NAME: CREW_STATUS, value: { isInCrew: false, crewId: null } });
            }
            if (isCrewOwner) { // если был капитаном и произошел дроп -> больше не кэп
                mediator.get<boolean>(SET_STORAGE, { NAME: IS_CREW_OWNER, value: false });
            }

            setCrewStatus('create');
        };
        
        const joinCrewHandler = (data:TCrew) => { // вошли в команду -> кнопка/хэндлер = покинуть команду
            // принадлежность к команде присваивается в другом месте (Crew.tsx)
            if (user && user.guid !== data.captain.guid) {
                setCrewStatus('leave');
            }
        };

        // если пользака выкинули из команды, ресетнуть ему значение кнопки.
        const dropFromCrewHandler = (data: TCrew) => {
            const user = getUser();
            const { guid, sailors } = data;
            if (user?.guid === guid) { return; }
            if (sailors.find(sailor => sailor.guid === user?.guid)) { 
                return; 
            } else { // Если выкинути ТЕБЯ, убрать принадлежность к команде в сторадже
                const crew = mediator.get<TCrewStatus>(GET_STORAGE, CREW_STATUS);
                if (crew && crew.isInCrew) {
                    mediator.get<TCrewStatus>(SET_STORAGE, { NAME: CREW_STATUS, value: { isInCrew: false, crewId: null } });
                }
            }

            setCrewStatus('create');
        };

        mediator.subscribe(CREATE_CREW, createCrewHandler);
        mediator.subscribe(DROP_CREW, dropCrewHandler);
        mediator.subscribe(JOIN_TO_CREW, joinCrewHandler);
        mediator.subscribe(DROP_FROM_CREW, dropFromCrewHandler);
        mediator.subscribe(CLEAR_CREW_LIST, dropCrewHandler);

        return () => {
            mediator.unsubscribe(CREATE_CREW, createCrewHandler);
            mediator.unsubscribe(DROP_CREW, dropCrewHandler);
            mediator.unsubscribe(JOIN_TO_CREW, joinCrewHandler);
            mediator.unsubscribe(DROP_FROM_CREW, dropFromCrewHandler);
            mediator.subscribe(CLEAR_CREW_LIST, dropCrewHandler);
        }
    });

    // создать команду
    const createCrewHandler = () => {
        server.createCrew();
    }

    // распустить команду
    const dropCrewHandler = () => {
        server.dropCrew();
    }

    // покинуть команду
    const leaveCrewHandler = () => {
        const crew = mediator.get<TCrewStatus>(GET_STORAGE, CREW_STATUS);
        if (crew && crew.isInCrew && crew.crewId) {
            server.leaveCrew({ crewId: crew.crewId });
            mediator.get<TCrewStatus>(SET_STORAGE, { NAME: CREW_STATUS, value: { isInCrew: false, crewId: null } });
            mediator.call(CLEAR_CREW_LIST, '');
            setCrewStatus('create');
        }
    }

    return (<>
        {
            crewStatus === 'create' ?  <ManageButton handler={createCrewHandler} text="Создать команду"/> :
            crewStatus === 'drop' ? <ManageButton classes="manage-button_active" handler={dropCrewHandler} text="Распустить команду"/> :
            crewStatus === 'leave' ? <ManageButton classes="manage-button_active" handler={leaveCrewHandler} text="Покинуть команду"/> : <></>
        }
    </>);
}

export default CrewManageButton;