import React, { useContext, useEffect, useRef, useState } from "react";
import { ServerContext } from "../../../../App";
import { TProperty } from "../../../../services/server/types";

import './PurchaseCard.scss';

interface IPurchaseCard {
    submarineId: number;
    equipmentId: number;
    property: TProperty
}

const PurchaseCard: React.FC<IPurchaseCard> = (props: IPurchaseCard) => {
    const { submarineId, equipmentId, property } = props;
    const server = useContext(ServerContext);
    const cardRef = useRef<HTMLButtonElement>(null);
    const [updater, setUpdater] = useState<number>(0);

    const { id, name, value, upgrade, cost } = property;

    useEffect(() => {
        const card = cardRef.current;
        if (card && upgrade >= 50) {
            card.setAttribute('disabled', '');
        }
    });

    const buyHandler = () => {
        server.buyPropertyUpgrade({ submarineId, equipmentId, propertyId: id });
        setUpdater(updater + 1);
    }

    const percentage = upgrade >= 50 ? 'max' : `${upgrade}%`;
    const upgradeCost = cost * Math.pow(2, Math.floor(upgrade / 10))
    const addClass = upgrade >= 50 ? 'purchase-card_maxed' : '';
    const valueCurrent = upgrade >= 50 ? `${value}(max)` : value;
    const title = name.split('(')[0];

    return (
        <button
            ref={cardRef}
            className={"shop-page__purchase-card " + addClass}
            onClick={buyHandler}
        >
            {
                upgrade < 50 ?
                <>
                    <div className="purchase-card__cost">
                        <span>{upgradeCost}</span>
                    </div>
                    <div className="purchase-card__icon">
                        <span>{percentage}</span>
                        <div className="purchase-card__icon_background" />
                    </div>
                </>
                : <></>
            }
            <span>{title}</span>
            <div className="purchase-card__value">
                <span>{valueCurrent}</span>
            </div>
        </button>
    );
}

export default PurchaseCard;