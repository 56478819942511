import { TStat } from '../services/server/types';

const useGetStat = () => {
    return (stats: Array<TStat>, points: number) => {
        // чтобы возвращал спрайт Трусова. Плохо сработает в случае с рейтингом, поэтому такая цифра заоблачная
        if (points < -1000000) {
            return { no: -1, ...stats[0] };
        }
        // стандартный код, чтобы вернуть набор статов
        for (let i = 0; i < stats.length; i++) {
            if (points <= stats[i].points) {
                return { no: i + 1, ...stats[i] };
            }
        }
        return { no: stats.length, ...stats[stats.length - 1] };
    }
}

export default useGetStat;