export enum TYPE {
    STRING = 'string',
    OBJECT = 'object',
    NUMBER = 'number',
    FUNCTION = 'function',
    BOOLEAN = 'boolean',
};

export enum AngleType {
    PITCH = 'pitch',
    COURSE = 'course'
}

export enum ControlType {
    SUBMARINE = 'submarine',
    TORPEDO = 'torpedo'
}

type TITEM = {
    type: TYPE,
    name: string,
    isSession?: boolean,
}

export type TSTORAGE = {
    [key: string]: TITEM,
}

export type TWINDOW = {
    LEFT: number;
    BOTTOM: number;
    HEIGHT: number;
    WIDTH: number;
}

export enum ECOMPARTMENT_TYPE {
    COMPARTMENT, // непосредственно отсек
    HATCH, // люк
    LADDER, // лестница
}

export type TPoint = {
    x: number;
    y: number;
}

export type TCompartment = {
    slot?: number;
    type: ECOMPARTMENT_TYPE;
    points: TPoint[][]; // координаты палуб отсека
    equipment?: TPoint[]; // расположение оборудование (не графическое, а места, в котором можно с ним взаимодействовать)
    open?: boolean; // открыт или закрыт люк
    water?: number; // количество воды в отсеке
    way1?: number; // номер отсека справа
    way2?: number; // номер отсека слева
}

// общее хранилище на всё приложение. Значения хранит в localStorage
export const STORAGE: TSTORAGE = {
    USER: { // данные по пользователю
        type: TYPE.OBJECT,
        name: 'user',
        isSession: true,
    },
    TOKEN: {
        type: TYPE.STRING,
        name: 'token'
    },
    GUID: {
        type: TYPE.STRING,
        name: 'guid'
    },
    RANKS: {
        type: TYPE.OBJECT,
        name: 'ranks',
        isSession: true,
    },
    RATINGS: {
        type: TYPE.OBJECT,
        name: 'ratings',
        isSession: true,
    },
    SUBMARINE_TYPES: {
        type: TYPE.OBJECT,
        name: 'submarine_types',
        isSession: true,
    },
    AMMO_AMOUNT: {
        type: TYPE.NUMBER,
        name: 'ammo_amount',
        isSession: true,
    },
    WEAPON_RANGE: {
        type: TYPE.NUMBER,
        name: 'weapon_range',
        isSession: true,
    },
    CREW_STATUS: { // id команды, в которую вступил пользак
        type: TYPE.OBJECT,
        name: 'crew_status',
        isSession: true,
    },
    OBJECTS_3D: {
        type: TYPE.OBJECT,
        name: 'objects_3d',
        isSession: true,
    },
    IS_CREW_OWNER: {
        type: TYPE.BOOLEAN,
        name: 'is_crew_owner',
        isSession: true,
    },
    IS_TRAINING_COMPLETED: {
        type: TYPE.BOOLEAN,
        name: 'is_training_completed',
    },
};

const WINDOW: TWINDOW = {
    LEFT: 0,
    BOTTOM: 0,
    HEIGHT: 18,
    WIDTH: 32
};
const SONAR: TWINDOW = {
    LEFT: -600,
    BOTTOM: -600,
    HEIGHT: 1200,
    WIDTH: 1200,
};

// Лодка №1. Приборный отсек
const COMPARTMENT_1_1: TCompartment = {
    slot: 1,
    type: ECOMPARTMENT_TYPE.COMPARTMENT,
    points: [
        [ // первая палуба
            { x: 6.25, y: 6.75 }, { x: 10.75, y: 6.75 }, { x: 10.75, y: 7.75 }, { x: 6.25, y: 7.75 }
        ], [ // вторая палуба
            { x: 6.25, y: 5.25 }, { x: 10.75, y: 5.25 }, { x: 10.75, y: 6.25 }, { x: 6.25, y: 6.25 }
        ]
    ],
    equipment: [ // приборная доска с управлением всей лодки
        { x: 9.5, y: 6.75 }, { x: 10.4, y: 6.75 }, { x: 10.4, y: 7.6 }, { x: 9.5, y: 7.6 }
    ],
};

// Лодка №1. Реакторный отсек
const COMPARTMENT_1_2: TCompartment = {
    slot: 2,
    type: ECOMPARTMENT_TYPE.COMPARTMENT,
    points: [
        [ // первая палуба
            { x: 1.25, y: 6.75 }, { x: 5.75, y: 6.75 }, { x: 5.75, y: 7.75 }, { x: 1.25, y: 7.75 }
        ], [ // вторая палуба
            { x: 1.25, y: 5.25 }, { x: 5.75, y: 5.25 }, { x: 5.75, y: 6.25 }, { x: 1.25, y: 6.25 }
        ]
    ],
    equipment: [ // реактор
        { x: 1.53, y: 5.25 }, { x: 3, y: 5.25 }, { x: 3, y: 7.85 }, { x: 1.53, y: 7.85 }
    ],
};

// Лодка №1. Лестницы
const LADDERS_1: TCompartment = {
    type: ECOMPARTMENT_TYPE.LADDER,
    points: [
        [ // первый отсек
            { x: 7, y: 5.25 }, { x: 7.75, y: 5.25 }, { x: 7.75, y: 7.75 }, { x: 7, y: 7.75 }
        ], [ // второй отсек
            { x: 4.25, y: 5.25 }, { x: 5, y: 5.25 }, { x: 5, y: 7.75 }, { x: 4.25, y: 7.75 }
        ]
    ],
}

// Лодка №1. Люк на первой палубе
const HATCH_1_1: TCompartment = {
    type: ECOMPARTMENT_TYPE.HATCH,
    open: true,
    way1: 1, // номер отсека справа
    way2: 2, // номер отсека слева
    points: [
        [ // первая палуба
            { x: 5.75, y: 6.75 }, { x: 6.25, y: 6.75 }, { x: 6.25, y: 7.6 }, { x: 5.75, y: 7.6 }
        ]
    ],
}

// Лодка №1. Люк на второй палубе
const HATCH_1_2: TCompartment = {
    type: ECOMPARTMENT_TYPE.HATCH,
    open: true,
    way1: 1, // номер отсека справа
    way2: 2, // номер отсека слева
    points: [
        [ // вторая палуба
            { x: 5.75, y: 5.25 }, { x: 6.25, y: 5.25 }, { x: 6.25, y: 6.1 }, { x: 5.75, y: 6.1 }
        ]
    ],
}

// Лодка №2. Отсек управления
const COMPARTMENT_2_2: TCompartment = {
    slot: 2,
    type: ECOMPARTMENT_TYPE.COMPARTMENT,
    points: [
        [ // первая палуба
            { x: 1.25, y: 6.75 }, { x: 5.75, y: 6.75 }, { x: 5.75, y: 7.75 }, { x: 1.25, y: 7.75 }
        ], [ // вторая палуба
            { x: 1.25, y: 5.25 }, { x: 5.75, y: 5.25 }, { x: 5.75, y: 6.25 }, { x: 1.25, y: 6.25 }
        ]
    ],
    equipment: [ // управление
        { x: 2.5, y: 6.75 }, { x: 3.8, y: 6.75 }, { x: 3.8, y: 7.6 }, { x: 2.5, y: 7.6 }
    ],
};

// Лодка №2. Отсек с реактором
const COMPARTMENT_2_3: TCompartment = {
    slot: 3,
    type: ECOMPARTMENT_TYPE.COMPARTMENT,
    points: [
        [ // первая палуба
            { x: -3.75, y: 6.75 }, { x: 0.75, y: 6.75 }, { x: 0.75, y: 7.75 }, { x: -3.75, y: 7.75 }
        ], [ // вторая палуба
            { x: -3.75, y: 5.25 }, { x: 0.75, y: 5.25 }, { x: 0.75, y: 6.25 }, { x: -3.75, y: 6.25 }
        ]
    ],
    equipment: [ // управление
        { x: -3.65, y: 5.25 }, { x: -1.9, y: 5.25 }, { x: -1.9, y: 7.85 }, { x: -3.65, y: 7.85 }
    ],
};

// Лодка №2. Лестницы
const LADDERS_2: TCompartment = {
    type: ECOMPARTMENT_TYPE.LADDER,
    points: [
        [ // первый отсек
        { x: 7, y: 5.25 }, { x: 7.75, y: 5.25 }, { x: 7.75, y: 7.75 }, { x: 7, y: 7.75 }
        ], [ // второй отсек
            { x: 4.25, y: 5.25 }, { x: 5, y: 5.25 }, { x: 5, y: 7.75 }, { x: 4.25, y: 7.75 }
        ], [ // третий отсек
            { x: -0.75, y: 5.25 }, { x: 0, y: 5.25 }, { x: 0, y: 7.75 }, { x: -0.75, y: 7.75 }
        ]
    ],
}

// Лодка №2. Люк на первой палубе
const HATCH_2_3: TCompartment = {
    type: ECOMPARTMENT_TYPE.HATCH,
    open: true,
    way1: 2, // номер отсека справа
    way2: 3, // номер отсека слева
    points: [
        [ // первая палуба
            { x: 0.75, y: 6.75 }, { x: 1.25, y: 6.75 }, { x: 1.25, y: 7.6 }, { x: 0.75, y: 7.6 }
        ]
    ],
}

// Лодка №2. Люк на второй палубе
const HATCH_2_4: TCompartment = {
    type: ECOMPARTMENT_TYPE.HATCH,
    way1: 2, // номер отсека справа
    way2: 3, // номер отсека слева
    open: true,
    points: [
        [ // вторая палуба
            { x: 0.75, y: 5.25 }, { x: 1.25, y: 5.25 }, { x: 1.25, y: 6.1 }, { x: 0.75, y: 6.1 }
        ]
    ],
}


// отсеки лодки первого уровня
const SUBMARINE_1: TCompartment[] = [
    COMPARTMENT_1_1,
    COMPARTMENT_1_2,
    LADDERS_1, // лестницы
    HATCH_1_1,
    HATCH_1_2,
];

// отсеки лодки второго уровня
const SUBMARINE_2: TCompartment[] = [
    COMPARTMENT_1_1, // пусть будет от первой лодки
    COMPARTMENT_2_2,
    COMPARTMENT_2_3,
    LADDERS_2, // лестницы
    HATCH_1_1, // люки
    HATCH_1_2,
    HATCH_2_3,
    HATCH_2_4,
];

const CONFIG = {
    // PROD
    HOST: 'https://rebelsubmarine.ru',
    // DEV
    //HOST: 'http://localhost:3001',
    //HOST: 'http://192.168.0.106:3001', // Matveev
    //HOST: 'http://192.168.1.86:3001', // Trusov

    STORAGE, // общее хранилище на всё приложение. Значения хранит в localStorage

    // mediator events and triggers
    MEDIATOR: {
        EVENTS: {
            SOCKET_ON_ERROR: 'SOCKET_ON_ERROR', // получение ошибки из сокета
            CLEAR_CREW_LIST: 'CLEAR_CREW_LIST', // очистить "Мою Команду", если моряк ливнул
            LEAVE_SAILOR_POPUP: 'LEAVE_SAILOR_POPUP', // показать какое-нибудь сообщение
            USER_SET_DIRECTION_STATUS: 'USER_SET_DIRECTION_STATUS', // перемещение морячка
            USER_SET_ACCELERATOR: 'USER_SET_ACCELERATOR', // изменение ускорения лодки в обе стороны
            USER_SET_COURSE_STEER: 'USER_SET_COURSE_STEER', // изменить направление по горизонтали (лодки и торпеды)
            USER_SET_PITCH_STEER: 'USER_SET_PITCH_STEER', // изменить направление по вертикали (лодки и торпеды)
            UPDATE_GAME_SCENES: 'UPDATE_GAME_SCENES', // коллбек на обновление локальной игровой сцены
            USER_SHOT_TORPEDO: 'USER_SHOT_TORPEDO', // выстрел торпедой
            USER_RELOAD_TORPEDO: 'USER_RELOAD_TORPEDO', // перезарядка торпедами
            USER_TORPEDO_DETONATION: 'USER_TORPEDO_DETONATION', // подрыв торпеды игроком
            // управление торпедой
            USER_CAN_REPAIR: 'USER_CAN_REPAIR', // игрок находится достаточно близко от повреждения, чтобы начать его чинить (или достаточно далеко)
            USER_DIE_BY_FIRE: 'USER_DIE_BY_FIRE', // игрок помер от огня
            USER_DIE_BY_WATER: 'USER_DIE_BY_WATER', // игрок утонул

            REACT_SHOW_ERROR_POPUP: 'REACT_SHOW_ERROR_POPUP', // показать сообщение об ошибке
        },
        TRIGGERS: {
            /***********************************/
            /* Служебные триггеры. Не трогать! */
            GET_STORAGE: 'GET_STORAGE',
            SET_STORAGE: 'SET_STORAGE',
            CLEAR_STORAGE: 'CLEAR_STORAGE',
            /***********************************/
            GET_SUBMARINE: 'GET_SUBMARINE', // получить субмарину для отрисовки на канвасе
            GET_MAX_SPEED: 'GET_MAX_SPEED', // получить максимальную скорость лодки
            GET_TORPEDO: 'GET_TORPEDO', // получить торпеду, чтобы отобразить значения её рулей
            GET_SCENE: 'GET_SCENE', // получить сцену для отображения на сонаре
        }
    },

    // all socket messages
    MESSAGES: {
        GET_PUBLIC_KEY: 'GET_PUBLIC_KEY', // скинуть на клиент публичный ключ для передачи первичной информации
        SET_SESSION_KEY: 'SET_SESSION_KEY', // установить сессионный ключ между бекендом и клиентом
        /******************************/
        /* login/logout, registration */
        /******************************/
        AUTO_REGISTRATION: 'AUTO_REGISTRATION', // автоматическая регистриция любого нового ползователя. 
        AUTO_LOGIN: 'AUTO_LOGIN', // автоматический логин пользователя по guid и token
        LOGIN: 'LOGIN', // логин пользователя по guid + password
        LOGOUT: 'LOGOUT', // логаут пользователя
        SET_PASSWORD: 'SET_PASSWORD', // задать пароль для пользователя
        UPDATE_PASSWORD: 'UPDATE_PASSWORD', // поменять пароль для пользователя
        /**************************************/
        /* Lobby, create, delete, choose team */
        /**************************************/
        GAME_STATS: 'GAME_STATS', // Получить список рангов, рейтингов и типов подводных лодок
        CREATE_CREW: 'CREATE_CREW', // создать команду
        DROP_CREW: 'DROP_CREW', // распустить команду
        JOIN_TO_CREW: 'JOIN_TO_CREW', // присоединиться к команде
        LEAVE_CREW: 'LEAVE_CREW', // покинуть команду
        DROP_FROM_CREW: 'DROP_FROM_CREW', // выкинуть из команды
        CREWS_LIST: 'CREWS_LIST', // список команд
        START_GAME: 'START_GAME', // выйти на лодке с экипажем в заплыв
        /********/
        /* Game */
        /********/
        USER_UPDATE_INFO: 'USER_UPDATE_INFO', // изменение характеристик пользователя
        SUBMARINE_INIT: 'SUBMARINE_INIT', // лодка проинициализировалась
        SUBMARINE_CANCEL: 'SUBMARINE_CANCEL', // конец субмарины (по любым причинам)
        SAILOR_CANCEL: 'SAILOR_CANCEL', // конец морячка (по любым причинам)
        SAILOR_MOVE: 'SAILOR_MOVE', // изменение направления движения или статуса пользователя (передаются координаты)
        SAILOR_OPEN_CLOSE_HATCH: 'SAILOR_OPEN_CLOSE_HATCH', // открытие/закрытие люка на лодке
        USE_EQUIPMENT_START: 'USE_EQUIPMENT_START', // начал использовать оборудование
        USE_EQUIPMENT_STOP: 'USE_EQUIPMENT_STOP', // закончил использовать оборудование
        SET_ACCELERATOR: 'SET_ACCELERATOR', // изменение ускорения лодки
        SET_COURSE_STEER: 'SET_COURSE_STEER', // изменение курсовых рулей
        SET_PITCH_STEER: 'SET_PITCH_STEER', // изменение рулей тангажа
        SPEED_CHANGE: 'SPEED_CHANGE', // изменение скорости лодки
        SCENE_UPDATE: 'SCENE_UPDATE', // изменение сцены
        SHOT_TORPEDO: 'SHOT_TORPEDO', // выстрел торпедой
        TORPEDO_DETONATION: 'TORPEDO_DETONATION', // подрыв торпеды игроком
        SET_TORPEDO_COURSE_STEER: 'SET_TORPEDO_COURSE_STEER', // изменение рулей горизонтального поворота торпеды
        SET_TORPEDO_PITCH_STEER: 'SET_TORPEDO_PITCH_STEER', // изменение рулей тангажа торпеды
        SUBMARINE_NEW_DAMAGE: 'SUBMARINE_NEW_DAMAGE', // подводная лодка получила новое повреждение
        SET_SUBMARINE_DAMAGE: 'SET_SUBMARINE_DAMAGE', // событие получения конкретного повреждения лодками
        SUBMARINE_PICKUP_SNOWFLAKE: 'SUBMARINE_PICKUP_SNOWFLAKE', // событие подбора снежинки
        SUBMARINE_INTO_BASE: 'SUBMARINE_INTO_BASE', // изменение положения субмарины относительно базы (приплыла или уплыла)
        SET_NEW_DAMAGE_MESSAGE: 'SET_NEW_DAMAGE_MESSAGE', // событие о новом повреждении ТОЛЬКО для того, чтобы показать попап игроку
        /********/
        /* Shop */
        /********/
        BUY_SUBMARINE: 'BUY_SUBMARINE', // купить подводную лодку
        BUY_PROPERTY_UPGRADE: 'BUY_PROPERTY_UPGRADE', // купить улучшение на свойство оборудования
    },

    GAME: {
        TEAMS: {
            BLUE: 'blue',
            RED: 'red'
        },

        // размер спрайта в пикселях
        SPRITE_SIZE: 64,
        DAMAGE_RADIUS: 64 * 4, // перевод в декартову систему координат
        REPAIR_RADIUS: 0.5, // радиус доступности ремонта повреждения
        DAMAGE_GROW_SPEED: 0.025, // скорость увеличения повреждения (любого)
        DAMAGE_FIX_SPEED: 0.250, // скорость починки повреждения (любого)

        // игровое окно
        WINDOW,
        // экран сонара (размерность в метрах)
        SONAR,

        // отсеки подводных лодок, распределённые по лодкам и их уровням
        SUBMARINES: {
            1: SUBMARINE_1,
            2: SUBMARINE_2,
        },

        // время обновления игровой сцены для движения внутри лодки
        INTERIOR_TIMESTAMP: 25,
        STEP_SIZE: 0.03, // размер шага движения персонажа
        PERSON_WIDTH: 0.5, // ширина персонажа
        PERSON_HEIGHT: 0.75, // высота персонажа
        ACCELERATOR_STEP: 10, // В процентах от мощности
        STEER_STEP: 5, // В градусах угол поворота рулей
        COMPARTMENT_WEIGHT: 200, // вес отсека (в тоннах)
        COMPARTMENT_VOLUME: 240, // объём отсека (в метрах), а так же объём воды, которая в него помещается (в тоннах)
        WATER_FLOW_SPEED: 2, // скорость перетекания воды из одного отсека в другой (в килограммах)
        INTERIOR_HEIGHT: 2.5, // высота внутреннего объёма отсека 
        // TODO дубль server\application\modules\game\types\BotSubmarine.js
        // максимальная скорость в узлах. Получается 17, 19, 21, 22, 23
        getMaxSpeed: (consumption: number): number => Math.round(Math.log(consumption * (-1) * 500) * 2),
        // TODO дубль server\application\modules\game\types\BotSubmarine.js
        // получить максимальный угол поворота рулей лодки (горизонтальных и вертикальных)
        getMaxSteeringAngle: (level: number): number => 25 + (level * 5),
        // вероятность возникновения нового очага пожара (0.23 - очень много, 0.22 - почти никогда)
        MAX_DAMAGE_GROW: 250, // максимальный рост повреждения
        getChanceOfFire: (damage: number): boolean => Math.random() * Math.log(damage) * 0.22 > 1,
        getWater: (damage: number): number => damage * 0.001, // скорость наливания водички. (т.к. функция вызывается по 40 раз в секунду, то водичку наливаем в КИЛОГРАММАХ)
        removeWater: (): number => 0.025, // скорость отсасывания водички
        getMaxDepth: (level: number): number => 400 + level * 100, // максимальная глубина погружения лодки
        // вероятность получения повреждения, если превысить допустимую глубину
        getChanceDepthDamage:(depth: number, maxDepth: number): boolean => depth < maxDepth ? false : Math.random() * Math.log(depth - maxDepth) * 0.22 > 1,
        MIN_RADIUS_RESPAWN: 500, // м. Минимальный радиус респавна снежинок
        MAX_RADIUS_RESPAWN: 600, // м. Минимальный радиус респавна снежинок
        BASE: { x: 0, y: 0, z: 0, r: 100, guid: '816bab06-3410-4d38-9e12-51e8c995325e' }, // м. база респавна, починки и сдачи ништяков. Является безопасным местом
        ENEMY_BASE: { x: 0, y: 0, z: 800, r: 100, guid: '0cc6b4e7-4f0c-4460-83ca-05b9c122e368' }, // м. база респавна ботов и сдачи ими ништяков
    },
};

export default CONFIG;