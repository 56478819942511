import React, { useState, useEffect, useContext } from "react";
import CONFIG from "../../../../config";
import { TGameSubmarine, TSceneUpdate } from "../../../../services/server/types";
import { TFiringTorpedo } from "../../../../game/Equipment/Equipment";
import { MediatorContext } from "../../../../App";
import ControlPanel from "./ControlPanel/ControlPanel";
import InfoPanel from "./InfoPanel/InfoPanel";

interface IControlModule {
    submarine: TGameSubmarine;
    isFireingPanel?: boolean;
}

export interface IControlModuleStats {
    pitch: number;
    course: number;
    isFired?: boolean;
    isFireingPanel?: boolean;
}

const ControlModule: React.FC<IControlModule> = (props: IControlModule) => {
    const { submarine, isFireingPanel } = props;

    const mediator = useContext(MediatorContext);
    const { UPDATE_GAME_SCENES } = mediator.getEventTypes();
    const { 
        GET_SUBMARINE, 
        GET_TORPEDO, 
        GET_SCENE 
    } = mediator.getTriggerTypes();

    const { getMaxSteeringAngle } = CONFIG.GAME;
    const maxSteeringLevel = getMaxSteeringAngle(submarine.level||1);

    const [isFired, setIsFired] = useState<boolean>(false);
    const [targetPitch, setTargetPitch] = useState<number>(submarine.pitchSteer);
    const [targetCourse, setTargetCourse] = useState<number>(submarine.courseSteer);

    useEffect(() => {
        if (isFireingPanel) {
            const setSteeringAngles = () => {
                const scene = mediator.get<TSceneUpdate>(GET_SCENE);
                const torpedo = mediator.get<TFiringTorpedo>(GET_TORPEDO);
                const currentSubmarine = mediator.get<TGameSubmarine>(GET_SUBMARINE);

                if (scene && torpedo && currentSubmarine) {
                    const userTorpedo = scene.torpedoes.find(_torpedo => _torpedo.submarineGuid === submarine.guid);
                    if (userTorpedo) {
                        if (!isFired) { setIsFired(true); }
                        const currentCourse = Number(torpedo.courseSteer);
                        const currentPitch = Number(torpedo.pitchSteer);
                        if (targetCourse !== currentCourse) { setTargetCourse(currentCourse); }
                        if (targetPitch !== currentPitch) { setTargetPitch(currentPitch); }
                    } else {
                        if (isFired) { setIsFired(false); }
                        const currentCourse = Number(currentSubmarine.courseSteer);
                        const currentPitch = Number(currentSubmarine.pitchSteer);
                        if (targetCourse !== currentCourse) { setTargetCourse(currentCourse); }
                        if (targetPitch !== currentPitch) { setTargetPitch(currentPitch); }
                        torpedo.courseSteer = 0;
                        torpedo.pitchSteer = 0;
                    }
                }
            }
        
            mediator.subscribe(UPDATE_GAME_SCENES, setSteeringAngles);

            return () => {
                mediator.unsubscribe(UPDATE_GAME_SCENES, setSteeringAngles);
            }
        }

        const setSubmarineSteeringAngles = () => {
            const currentSubmarine = mediator.get<TGameSubmarine>(GET_SUBMARINE);
            if (currentSubmarine) {
                const currentCourse = Number(currentSubmarine.courseSteer);
                const currentPitch = Number(currentSubmarine.pitchSteer);
                if (targetCourse !== currentCourse) { setTargetCourse(currentCourse); }
                if (targetPitch !== currentPitch) { setTargetPitch(currentPitch); }
            }
        }

        mediator.subscribe(UPDATE_GAME_SCENES, setSubmarineSteeringAngles);

        return () => {
            mediator.unsubscribe(UPDATE_GAME_SCENES, setSubmarineSteeringAngles);
        }
    });

    return (
        <div className="control-panel__control-module">
            <InfoPanel
                pitch={targetPitch}
                course={targetCourse}
                isFired={isFired}
                isFireingPanel={isFireingPanel}
            />
            <ControlPanel
                maxSteeringLevel={maxSteeringLevel}
                pitch={targetPitch}
                course={targetCourse}
                isFired={isFired}
                isFireingPanel={isFireingPanel}
            />
        </div>
    )
}

export default ControlModule;
