import React from "react";

const Repair: React.FC = () => {
    return (
        <svg id="repair" fill="none" viewBox="0 0 350 350">
            <path fill="currentColor" d="M126.2 59.6a127.4 127.4 0 0 0-48 19l2.3 2.9-21.4 18-2.9-3.3 4.4-3.6-15-18.2-4.8 3.9a18.5 18.5 0 0 1 2.4-17 13.2 13.2 0 0 1 9.5-4.5c4-.3 8.5.9 11.6 3.5 1-.1 5-5.7 5.7-6.9L88 26c4.4-6.3 9.6-12.2 15.3-17.5 3-2.6 6-5 9.4-7 1-.5 2.1-1.3 3.2-1.5l.7 6.4c-5 4.1-9.5 8.8-13.6 14C99.4 25 96 30 93 35c-6 9.9-11.5 20-19.4 28.5l4.1 5c5.3-3.4 11-6.3 16.7-8.8 8.4-3.7 16.9-6.5 25.9-8 10.1-1.8 24.9-1.3 35 1 0 .6-.2 1.2-.6 1.8-.6 1-4 3.4-4 3.4h-7.4c-5.8.2-11.4.7-17 1.5Z"/>
            <path fill="currentColor" d="m74.2 266.6-22.6 22.5c-3-6.7-46-97.4-48-104.5-3.2-10.9-4.1-22-3.3-33.3a88.7 88.7 0 0 1 22-52.3c3.1-3.6 18.8-16.5 22.3-19.6L55 91.8c-6 4.9-12.2 9.6-17.6 15a72.5 72.5 0 0 0-17.6 73c2.3 7 50.4 80.7 54.4 86.8h.1Z"/>
            <path fill="currentColor" d="M249.3 284.2a190 190 0 0 1-27.6 21.8 79.7 79.7 0 0 1-21.3 10.5c-2.4.7-7 2-9.2.4L69 168.5a48.2 48.2 0 0 1-2.7-54.9c0-.2-.8-1-1-1.2-1.3-1.7-3-3.4-4.2-5.2-.3-.4-.8-1-.8-1.5 0-1 .3-1.8.7-2.6l22.7-18.9c.7-.4 1.4-.5 2.2-.4 1.8.1 3.1 2.3 4.2 3.6l3.1 3.6a50 50 0 0 1 52.6 11.4l122.5 149c.7.8.7 1.7.7 2.8 0 3-1.1 5.9-2.5 8.5a90.3 90.3 0 0 1-17.2 21.4Z"/>
            <path fill="currentColor" d="M291.3 284a45.1 45.1 0 0 1-6.1 17.8 103.2 103.2 0 0 1-20.4 22 107 107 0 0 1-28.1 18 34.5 34.5 0 0 1-16.3 3.1 15 15 0 0 1-8-3c-3.2-2.4-5.8-6.2-8.4-9.3l-9.9-12a41 41 0 0 0 19.7-6c4-2 8-4.4 11.8-7 8.4-5.7 16.2-11.8 23.6-18.7 4.7-4.4 9.2-9 13.2-14.1A58 58 0 0 0 270 263c1-2.3 1.7-4.7 2-7.1l11.3 13.6c2.1 2.6 4.9 5.4 6.4 8.4 1 1.9 1.5 4 1.6 6Zm13.6-188.6c.2-4.7-1.3-9.3-4.2-13l-36.5-35.9c-1.6-1-4.1-1.4-6-2-11.9-3.9-24.2-6-36.7-6.5A91.5 91.5 0 0 0 172 51.5c-1.2.8-3.5 1.9-4.2 3l4.5 5.7a54.7 54.7 0 0 1 27-2.3c12.7 2 26.3 8.6 34 19.3 0 3.5-.8 7.2-1.3 10.7l-1.9 14.2 14.5 15c3.7 3.9 7.4 8 11.5 11.4l21-4.8c-.9 1 1.2-.5 0 0L305 95.5v-.1Zm17.1 69.4 27-27.6c.5-.6.8-1.2 1-2 .1-1.3 0-2.7-.9-3.8-1.3-1.3-15.1-15.1-16.8-15.9-1.7-.8-3.1 0-4.8-.2-7.1-1-18.1-11.1-23-16l-24.8 25a71 71 0 0 1 17.2 19.8c1 2.4.9 5.3 2.6 7.3 0 0 14.9 15 15.7 15.5.6.4 1.2.7 2 .7 2.1 0 3.4-1.4 4.8-2.8Z"/>
            <path fill="currentColor" d="m204.1 170.8 43.8-47.4-15-15.5-44 44.6 15.2 18.3Zm6.4 8 42.7-50-3.9-3.8-43.6 47.5 4.8 6.2ZM50.3 350a21 21 0 0 0 10.1-3.7c2.2-1.8 79.8-88.6 79.8-88.6l-25.8-31S30.4 310 27.8 313a13.1 13.1 0 0 0-2.8 6c-.8 4.3-.3 8.6 2.1 12.2 1.5 2.3 10.5 11 12.2 13 3.2 2.8 7 4.7 11 5.8Z"/>
        </svg>
    );
}

export default Repair;