import CONFIG from "../config";
import { Mediator } from "../services/mediator";
import useGetStat from "./useGetStat";

import { TStat } from "../services/server/types";

const useGetRating = (mediator: Mediator) => {
    const getStat = useGetStat();

    const { RATINGS } = CONFIG.STORAGE;
    const { GET_STORAGE } = mediator.getTriggerTypes();
    const ratings = mediator.get<Array<TStat>>(GET_STORAGE, RATINGS) || [];

    return (rank: number) => getStat(ratings, rank)
}

export default useGetRating;