import * as THREE from 'three';
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { VisualHelper } from './index';

export interface IEntity {
    x: number,
    y: number,
    z: number,
    model: GLTF;
    scale: number
    isIntersectionCircle: boolean
}

class Entity {
    public mesh;
    public animationMixer;
    private visualHelper: VisualHelper;
    private isIntersectionCircle;

    constructor(options: IEntity) {
        const { 
            model,
            x, y, z,
            scale,
            isIntersectionCircle
        } = options;

        this.isIntersectionCircle = isIntersectionCircle;

        this.mesh = model.scene.clone();
        this.mesh.position.set(x, y, z);
        this.mesh.scale.set(scale, scale, scale);

        // визуальный помощник
        this.visualHelper = new VisualHelper({ subject: this.mesh, isIntersectionCircle });
        this.visualHelper.update(y);

        // анимации
        this.animationMixer = null;
        const animations = model.animations;
        if (animations.length > 0) {
            this.animationMixer = new THREE.AnimationMixer(this.mesh);
            animations.forEach(clip => {
                if (this.animationMixer) {
                    const action = this.animationMixer.clipAction(clip);
                    action.play();
                }
            });
        }
    }

    destructor() {
        this.visualHelper.destructor();
    }

    public updateMesh({ x, y, z, userCourse, course=0, pitch=90, intersectionCircle=1 }: {
        x: number, 
        y: number, 
        z: number,
        userCourse: number,
        course?: number,
        pitch?: number,
        intersectionCircle?: number
    }): void {
        const userCourseRad = THREE.MathUtils.degToRad(userCourse);
        const objectCourseRad = THREE.MathUtils.degToRad(course);
        const objectPitchRad = THREE.MathUtils.degToRad(pitch-90);
        const relativeCourse = objectCourseRad - userCourseRad;
    
        this.mesh.position.set(x, y, z);
        this.mesh.rotation.set(objectPitchRad, relativeCourse, 0);

        if (this.isIntersectionCircle) {
            this.visualHelper.update(y, intersectionCircle);
        } else {
            this.visualHelper.update(y);
        }
    }
}

export default Entity;