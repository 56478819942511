import React, { useState, useEffect, useContext, useRef } from "react";
import CONFIG from "../../config";
import { MediatorContext, ServerContext } from "../../App";
import { useGetUser } from "../../hooks";
import { Exit } from "../../components/svg";
import InteractiveButton from "../../components/InteractiveButton/InteractiveButton";
import { IBasePage, PAGES } from "../PageManager";

import './Settings.scss';

const Settings: React.FC<IBasePage> = (props: IBasePage) => {
    const { setPage } = props;
    const mediator = useContext(MediatorContext);
    const server = useContext(ServerContext);
    const [updateInfo, setUpdateInfo] = useState<number>(1);
    const [disabledGuid, setDisabledGuid] = useState<boolean>(true);
    const { USER_UPDATE_INFO, REACT_SHOW_ERROR_POPUP } = mediator.getEventTypes();
    const getUser = useGetUser(mediator);
    const user = getUser();

    // ref`s
    const guidRef = useRef<HTMLInputElement>(null);
    const loginPasswordRef = useRef<HTMLInputElement>(null); // инпут для воода уже заданного пароля, в случае если свалилась автоматическая авторизация
    const passwordRef = useRef<HTMLInputElement>(null);
    const repeatPasswordRef = useRef<HTMLInputElement>(null);
    const oldPasswordRef = useRef<HTMLInputElement>(null);
    const newPasswordRef = useRef<HTMLInputElement>(null);
    const repeatNewPasswordRef = useRef<HTMLInputElement>(null);

    const clearInputs = () => {
        if (loginPasswordRef.current) loginPasswordRef.current.value = '';
        if (passwordRef.current) passwordRef.current.value = '';
        if (repeatPasswordRef.current) repeatPasswordRef.current.value = '';
        if (oldPasswordRef.current) oldPasswordRef.current.value = '';
        if (newPasswordRef.current) newPasswordRef.current.value = '';
        if (repeatNewPasswordRef.current) repeatNewPasswordRef.current.value = '';
    }

    useEffect(() => {
        const userUpdateInfoHandler = () => {
            clearInputs();
            setUpdateInfo(updateInfo + 1);
        }

        mediator.subscribe(USER_UPDATE_INFO, userUpdateInfoHandler);

        return () => {
            mediator.unsubscribe(USER_UPDATE_INFO, userUpdateInfoHandler);
        }
    });

    const backClickHandler = () => setPage(PAGES.LOBBY);
    const undisabledCluckHandler = () => setDisabledGuid(false);
    const authClickHandler = () => {
        if (loginPasswordRef.current && guidRef.current) {
            const guid = guidRef.current.value;
            const password = loginPasswordRef.current.value;
            if (!guid) {
                mediator.call(REACT_SHOW_ERROR_POPUP, { code: 100004, text: 'GUID не может быть пустым!' });
                return;
            }
            if (!password) {
                mediator.call(REACT_SHOW_ERROR_POPUP, { code: 100001, text: 'Пароль не может быть пустым!' });
                return;
            }
            server.login({ guid, password });
            clearInputs();
        }
    }
    // задать пароль у ещё не задававшего пароль пользователя
    const setPasswordClickHandler = () => {
        if (passwordRef.current && repeatPasswordRef.current) {
            const password = passwordRef.current.value;
            const repeatPassword = repeatPasswordRef.current.value;
            if (!password && !repeatPassword) {
                mediator.call(REACT_SHOW_ERROR_POPUP, { code: 100001, text: 'Пароль не может быть пустым!' });
                return;
            }
            if (password !== repeatPassword) {
                mediator.call(REACT_SHOW_ERROR_POPUP, { code: 100002, text: 'Введённые пароли не совпадают!' });
                return;
            }
            if (password.length < 8) {
                mediator.call(REACT_SHOW_ERROR_POPUP, { code: 100003, text: 'Пароль слишком короткий!' });
                return;
            }
            server.setPassword({ password });
            clearInputs();
        }
    }

    const setNewPasswordClickHandler = () => {
        if (oldPasswordRef.current && newPasswordRef.current && repeatNewPasswordRef.current) {
            const oldPassword = oldPasswordRef.current.value;
            const newPassword = newPasswordRef.current.value;
            const repeatNewPassword = repeatNewPasswordRef.current.value;
            if (!oldPassword) {
                mediator.call(REACT_SHOW_ERROR_POPUP, { code: 100001, text: 'Пароль не может быть пустым!' });
                return;
            }
            if (newPassword !== repeatNewPassword) {
                mediator.call(REACT_SHOW_ERROR_POPUP, { code: 100002, text: 'Введённые пароли не совпадают!' });
                return;
            }
            if (newPassword.length < 8) {
                mediator.call(REACT_SHOW_ERROR_POPUP, { code: 100003, text: 'Пароль слишком короткий!' });
                return;
            }
            server.updatePassword({ oldPassword, newPassword });
            clearInputs();
        }
    }

    const settingsHeader = <div className="settings__header">
        <div>Настройки игрока</div>
        <InteractiveButton
            classes="interactive-button_exit"
            svg={<Exit />}
            onClick={backClickHandler}
        />
    </div>;

    if (!user) {
        const { GET_STORAGE } = mediator.getTriggerTypes();
        const { GUID } = CONFIG.STORAGE;
        const guid = mediator.get<string>(GET_STORAGE, GUID) || '';

        return (<div className="settings">
            {settingsHeader}
            <div className="settings__main">
                <p>{'Что-то пошло не так. Попробуйте авторизоваться!'}</p>
                <div>
                    <span>guid:</span>
                    <input ref={guidRef} disabled={disabledGuid} defaultValue={guid} />
                    <button className="settings-button" onClick={undisabledCluckHandler}>Поменять значение</button>
                </div>
                <div>
                    <input ref={loginPasswordRef} type="password" placeholder="Пароль" />
                </div>
                <button className="settings-button" onClick={authClickHandler}>Авторизоваться</button>
            </div>
        </div>);
    }

    const { name, guid, isPasswordSet } = user;

    return (
        <div className="settings">
            {settingsHeader}
            <div className="settings__main">
                <div>
                    <span>Имя:</span>
                    <span>{name}</span>
                </div>
                <div>
                    <span>guid:</span>
                    <input disabled defaultValue={guid} />
                </div>
                {isPasswordSet ? <div>
                    <p>Поменять пароль:</p>
                    <input ref={oldPasswordRef} type="password" placeholder="Введите старый пароль" /><br />
                    <input ref={newPasswordRef} type="password" placeholder="Введите новый пароль" /><br />
                    <input ref={repeatNewPasswordRef} type="password" placeholder="Повторите новый Пароль" /><br />
                    <button className="settings-button" onClick={setNewPasswordClickHandler}>Установить новый пароль</button>
                </div>
                    : <div>
                        <p>Задать пароль:</p>
                        <input ref={passwordRef} type="password" placeholder="Введите пароль" /><br />
                        <input ref={repeatPasswordRef} type="password" placeholder="Повторите Пароль" /><br />
                        <button className="settings-button" onClick={setPasswordClickHandler}>Установить пароль</button>
                    </div>
                }
            </div>
        </div>
    );
}

export default Settings;