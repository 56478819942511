export const UNSET_COLOR = '#fff';
export const COMPARTMENT_COLOR = '#fff4'; // цвет отсека
export const LADDER_COLOR = '#ba56'; // цвет лестницы
export const HATCH_OPEN_COLOR = '#fff8'; // цвет открытого люка
export const HATCH_CLOSE_COLOR = '#fff2'; // цвет закрытого люка
export const EQUIPMENT_FREE_COLOR = '#2f04' // цвет свободного оборудования
export const EQUIPMENT_USED_COLOR = '#f204' // цвет занятого оборудования
export const HOLE_DAMAGE = '#00fc'; // пробоина
export const FIRE_DAMAGE = '#f00c'; // пожар
export const REPAIR_COLOR = '#ff07'; // радиус починки
export const WATER_COLOR = '#007ba766'; // цвет водички
