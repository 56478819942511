const getFireFrame = (x = 0, y = 0, sizeX = 45, sizeY = 70): number[][] => {
    return [
        [x, y + 0 * sizeY, sizeX, sizeY],
        [x, y + 1 * sizeY, sizeX, sizeY],
        [x, y + 2 * sizeY, sizeX, sizeY],
        [x, y + 3 * sizeY, sizeX, sizeY],
        [x, y + 4 * sizeY, sizeX, sizeY],
        [x, y + 5 * sizeY, sizeX, sizeY],
    ];
}

export default getFireFrame;