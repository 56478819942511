import React, {  forwardRef, useEffect, useImperativeHandle, useRef, useContext } from "react";
import { MediatorContext } from "../../../App";
import { TCrew, TUser } from "../../../services/server/types";

import './BoatList.scss';

interface IBoatList {
    user: TUser|null;
    startHandler: (button: HTMLButtonElement | null) => void;
}

export interface IBoatListHandle {
    getBoatListRef: () => HTMLDivElement | null;
}

const BoatList = forwardRef<IBoatListHandle, IBoatList>((props, ref) => {
    const { user, startHandler } = props;

    const boatListRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const mediator = useContext(MediatorContext);
    const { DROP_CREW, JOIN_TO_CREW, LEAVE_CREW, DROP_FROM_CREW, CLEAR_CREW_LIST } = mediator.getEventTypes();

    useEffect(() => {
        const inputs = document.querySelectorAll<HTMLDivElement>('.baot-selection__selector-wrapper');
        const button = buttonRef.current;

        const disableHadler = (data: TCrew) => {
            if(user?.guid !== data.captain.guid) {
                if (inputs && button) {
                    inputs.forEach(input => {
                        const child = input.firstChild as HTMLInputElement;
                        child.setAttribute('disabled', 'true');
                    });
                    button.setAttribute('disabled', 'true');
                }
            }
        }

        const enableHadler = (data: TCrew) => {
            if(data.sailors) {
                if (!data.sailors.find(sailor => sailor.guid === user?.guid)) {
                    if (inputs && button) {
                        inputs.forEach(input => {
                            const child = input.firstChild as HTMLInputElement;
                            child.removeAttribute('disabled');
                        });
                        button.removeAttribute('disabled');
                    }
                }
            } else {
                if (inputs && button) {
                    inputs.forEach(input => {
                        const child = input.firstChild as HTMLInputElement;
                        child.removeAttribute('disabled');
                    });
                    button.removeAttribute('disabled');
                }
            }
        }

        mediator.subscribe(JOIN_TO_CREW, disableHadler);
        mediator.subscribe(DROP_CREW, enableHadler);
        mediator.subscribe(LEAVE_CREW, enableHadler);
        mediator.subscribe(DROP_FROM_CREW, enableHadler);
        mediator.subscribe(CLEAR_CREW_LIST, enableHadler);

        return () => {
            mediator.unsubscribe(JOIN_TO_CREW, disableHadler);
            mediator.unsubscribe(DROP_CREW, enableHadler);
            mediator.unsubscribe(LEAVE_CREW, enableHadler);
            mediator.unsubscribe(DROP_FROM_CREW, enableHadler);
            mediator.unsubscribe(CLEAR_CREW_LIST, enableHadler);
        }
    });

    useImperativeHandle(ref, () => ({
        getBoatListRef: () => boatListRef.current
    }));

    const onClickHandler = () => startHandler(buttonRef.current);

    const boats = user ? user.submarines.map((submarine, index) => {
        let isChecked = false;

        if (user.submarines.length <= 1) {
            isChecked = true;
        }

        return <div className="baot-selection__selector-wrapper" key={index}>
            <input
                className="baot-selection__selector" 
                type="radio"
                name="submarine"
                id={`submarine-choice__${index}`}
                value={submarine.id}
                defaultChecked={isChecked}
            />
            <label
                htmlFor={`submarine-choice__${index}`}
            >{submarine.name}</label>
        </div>
    }) : <></>;

    return (
        <div ref={boatListRef} className="lobby__baot-selection">
            <h2 className="baot-selection__title">Выберите субмарину</h2>
            { boats }
            <button
                ref={buttonRef}
                className="lobby__start-button"
                onClick={onClickHandler}
            >
                <div /> {/* <- стили */} 
                <span>В плавание!</span>
            </button>
        </div>
    )
});

export default BoatList;