import getPersonFrames from './getPersonFrames';
import getHoleFrame from './getHoleFrame';
import getFireFrame from './getFireFrame';

const getSailorFrames     = (SIZE: number): number[][][][] => getPersonFrames(SIZE);
const getForemanFrames    = (SIZE: number): number[][][][] => getPersonFrames(SIZE, SIZE * 9);
const getMidshipmanFrames = (SIZE: number): number[][][][] => getPersonFrames(SIZE, SIZE * 9 * 2);
const getLieutenantFrames = (SIZE: number): number[][][][] => getPersonFrames(SIZE, SIZE * 9 * 3);
const getCaptainFrames    = (SIZE: number): number[][][][] => getPersonFrames(SIZE, SIZE * 9 * 4);
const getAdmiralFrames    = (SIZE: number): number[][][][] => getPersonFrames(SIZE, SIZE * 9 * 5);
const getTrusovFrames     = (SIZE: number): number[][][][] => getPersonFrames(SIZE, SIZE * 9 * 6);

export { getSailorFrames, getForemanFrames, getMidshipmanFrames, getLieutenantFrames, getCaptainFrames, getAdmiralFrames, getHoleFrame, getFireFrame, getTrusovFrames };