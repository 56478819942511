import React from "react";

const SteeringWheel: React.FC = () => {
    return (
        <svg id="steering-wheel" fill="none" viewBox="0 0 350 350">
            <path fill="currentColor" d="M181.2 38h-12.7l-1.8-11.5c-.7-5-2.1-10.3-1.4-15.6.3-2.8 1-4.9 2.4-7.4a9 9 0 0 1 6-3.5h2.2c2.1 0 4.3 1.4 5.7 3.2 5 6.7 2.8 17.6 1.4 25l-1.8 9.9Zm4 13.1-10.3-.3c-4-.4-10.3.3-10.3.3l1.4-10.9c2.1-.7 5.3-1 8.9-1l8.8.7 1.5 11.2Zm92.6 31-5.6-5.6-4.3-3.5c5.3-5.3 9.6-11.6 14.2-17.3 1.8-2.4 4-5.6 6.4-7.4 1.4-1 3.2-1.7 4.6-1.7h1.8a10 10 0 0 1 8.5 7c.4 1.3.5 2.6.4 3.9 0 1.8-.7 3.2-2.2 4.6-2.5 3.1-6 6-9.2 8.4l-14.6 11.7Zm-205.2 0L58 71c-3.5-2.5-7-5-10-8.1-1-1.4-1.7-3.2-2-5.3-.4-2.5.3-5 1.7-7a11 11 0 0 1 7.1-4c2.1 0 4.3.8 6 1.8 2.9 2.1 5.4 5.7 7.9 8.8 4.2 5.3 8.5 11 13.5 16-3.2 2.4-7.1 5.9-9.6 9Zm105 215.2a121.4 121.4 0 0 1-101.1-49A122.7 122.7 0 0 1 51.6 182c-1.8-32 9.2-63.4 30.6-87.5a127 127 0 0 1 90.2-42.3c32.6 0 64 12.5 87.7 35a121.3 121.3 0 0 1 11.7 163.6l-4.6 5.6-4.6 4.2a122.6 122.6 0 0 1-85 36.7ZM167.5 72.7a101.7 101.7 0 0 0-70.7 36 102.3 102.3 0 0 0 26 154.5 103.9 103.9 0 0 0 57.1 14.4l1.8-.4a107.8 107.8 0 0 0 73.2-38 101 101 0 0 0-15-143 104.9 104.9 0 0 0-72.4-23.5ZM81 93.5 72.2 85l5.7-6.7c2.1-2.1 4.3-4.2 6.4-5 0 0 8.2 6.4 9.2 7.8l-6.4 6c-2.8 2.1-6 6.4-6 6.4Zm187.9.3-12.8-12.7 4.6-4.6c1.4-1 2.5-2 4.3-2.8 1-.3 1.4 0 2.4.7l6.4 6 2.9 3.2.3 1-.7 2.2-2.5 2.4-5 4.6Z"/>
            <path fill="currentColor" d="M180.9 271.6c-24.5 1-50.4-6.7-69-22.6a98.2 98.2 0 0 1-34.4-67.4 99 99 0 0 1 53.7-93.4 97.6 97.6 0 0 1 137 60.3A89 89 0 0 1 270 195a96.9 96.9 0 0 1-89.1 76.6ZM232 105c-2.1-2.5-5.3-4.2-8.1-6a93.4 93.4 0 0 0-40.2-14.5c-.3 1.8.7 3.6-2.5 5l-.3 56a28 28 0 0 0-12 .4V89.6c-1.9-.4-2.6-2.5-2.6-5l-11.7 1.8a91 91 0 0 0-29.5 12.7c-2.8 2.1-6 3.9-8.5 6.4a2 2 0 0 1 1.8 1c.7 1 .7 2.8.3 4.2l39.8 39.2c-3.9 2.1-6.7 5.6-9.2 8.8l-39-38.8h-1.5c-1.4-.3-2.8-1.7-4-2.8l-7 10.2a91.3 91.3 0 0 0-13.5 39.2l2.1.3c1 0 1.8 1 2.5 1.8l56.5.3c-.7 4-.7 7.8-.4 12H89l-1.5 1.4c-1.4 1.1-2.1.7-3.2.7a90.3 90.3 0 0 0 20.6 49.4l1.8-1.7c1-.7 2.1-.4 3.2 0l39-39.9c2.5 3.9 5.7 6.7 9.3 9.5l-39.5 39.5.4 1.4c-.4 1.5-1 2.5-2.1 3.6l2.5 1.7 4.6 3.6a95.2 95.2 0 0 0 42.6 15.8c.3-2.4-.4-4.6 1.8-6v.4-56c4.2.6 8.5.6 12.7 0l-.3 55.6c1.4 1.4 1.4 4 1.4 5.7 13.9-.7 28.4-6 40.1-13.4 3.6-2.1 7.1-5 10.3-7.4-.7-.7-1.7-1.4-2-2.9-.8-1-.4-2 0-3.1L191.1 200c3.9-1.8 7-5.7 9.6-9.2l38 39.9 2.8-.4c1.4.4 2.1 1.4 3.2 2.8a91.4 91.4 0 0 0 21-50.4c-.7 0-4 .3-4.7-1.4h-56.8l.4-3.9c.3-2.8 0-5.6-.4-8.5h56.8a5.9 5.9 0 0 1 4.7-1.7l-1.1-7.8a92.8 92.8 0 0 0-19.5-41.2c-2.2 1.7-4.6 1.4-5.7.7l-39 39.8c-2.6-3.5-5.4-6-9-8.8l39.5-39.5c-1.1-1.4-.7-3.5 1-5.3Z"/>
            <path fill="currentColor" d="M177.3 203.5c-8.1.4-14.9-1.8-21.3-6.7a28.5 28.5 0 0 1-3.2-40.2c5.7-6 12.5-9.2 20.3-9.9l5.5.2a27.6 27.6 0 0 1 24.6 31.2 25 25 0 0 1-6 14.8 28.7 28.7 0 0 1-19.9 10.6Zm-2.4-50.8a22.9 22.9 0 0 0-21.1 14.2 22.4 22.4 0 0 0-1.7 8.7c.4 6.4 2.9 11.7 7.5 16 4.2 3.8 9.6 6 15.3 6 6.3-.4 12-2.6 16.3-7.1a22.2 22.2 0 0 0 6.4-16.6c-.4-5.7-3-11-7.2-15a22.8 22.8 0 0 0-15.5-6.2Z"/>
            <path fill="currentColor" d="M177 191.2c-4.6.3-8.5-.4-12.4-3.2a15.4 15.4 0 0 1-6-10.2 16 16 0 0 1 2.8-12.7c3.2-4 7.4-6 12-6.4 4-.3 8.2.7 11 2.8a16.6 16.6 0 0 1 4.3 23c-2.8 3.8-7.1 6-11.7 6.7Zm-137.8-7.8c-1-1-2.1-6.3-2.1-9.5 0-3.6 1-6 2-7.4l11.1-.4c-.7 5.7-.7 11.3 0 17.7l-11-.4Zm-11.7-1L16 184c-2.5 0-5.3.7-7.8 0l-4-1.4a9.1 9.1 0 0 1-4.2-6c-.3-2.5 0-5.3 1.4-7a9.6 9.6 0 0 1 6.8-4c3.5-.3 6.7 0 10.3.4l17.8 2.5c-1.1 4.2-.8 8.8.3 12.7l-3.2.3-6 .7Zm299.3.6-14.2-1.7a38 38 0 0 0 0-12.7l23.8-2.8h6c1.8.7 4.3 1.7 5.4 3.5a9.5 9.5 0 0 1 2.1 6.7v.3a8.7 8.7 0 0 1-3.5 5.7c-3.6 2.8-8.2 2.5-12.4 2.1l-7.2-1Zm-16.6-.3c-.4.7-.8 1-1.5 1l-3.5.4h-6.4v-18.3h6.7l3.6.3c1.4.7 2.1 2.5 2.5 3.9.7 3.9.7 9.5-1.4 12.7ZM85.4 276.2l-1.1.3c-1 .4-1.8 0-2.1-.3-2.2-1.5-9.6-8.5-9.6-10.3-.4-.7 0-1.4.3-1.7l2.5-3.2 5-5.7 13.1 13.4-8.1 7.5Zm180.4 1.7-9-8.8 12.2-12 8.8 8.5-5.3 6.3-6.7 6Zm-211 25h-.3a10 10 0 0 1-8.5-7.6 9.8 9.8 0 0 1 0-4c.7-2.1 1.7-3.9 3.1-5.3 1.8-2 4-3.8 6-5.3l9-6.3 8.5-6a62 62 0 0 0 8.1 8.5l-11.3 15.8-6 7.4c-2.5 2.5-5 3.2-8.6 2.9Zm240.8.4h-2.8c-1.8-.3-3.6-1-5-2.1-1.8-1.4-3.2-3.5-4.6-5.3l-15-18.3 5.7-4.6 3.6-4.3 20.2 14.9a11 11 0 0 1 4 3.1 9.4 9.4 0 0 1 2.4 5c.4 2.4 0 5.3-1.8 7.4a9.4 9.4 0 0 1-6.7 4.2Zm-112.2 6.4c-.4.7-1 .7-1.8.7-2.1.3-13.9.7-15.3-.4l-1-11.3h19.1l-1 11Zm-4 38.8-.3.3c-1.8 1-3.9 1.4-6 1a8.6 8.6 0 0 1-5.4-4.5c-4.2-6.7-1.7-17.3-.3-24.7l1.4-8.5h12.4l2.2 13.8c.7 4.2 1.4 8.4 1 12.3-.3 4.3-1.7 7.8-5 10.3Z"/>
        </svg> 
    );
}

export default SteeringWheel;