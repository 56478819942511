import React, { useEffect, useContext, useRef } from "react";
import CONFIG from "../../../../../../config";
import { MediatorContext } from "../../../../../../App";
import { TSubmarine } from "../../../../../../services/server/types";
import { useIsAspectRaito } from "../../../hooks";

import './Depth.scss';

const Depth: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const { UPDATE_GAME_SCENES } = mediator.getEventTypes();
    const { GET_SUBMARINE } = mediator.getTriggerTypes();
    const { getMaxDepth } = CONFIG.GAME;

    const depthWrapperRef = useRef<HTMLDivElement>(null);
    const currentDepthRef = useRef<HTMLDivElement>(null);
    const depthIndicatorRef = useRef<HTMLDivElement>(null);

    const submarine = mediator.get(GET_SUBMARINE) as TSubmarine;
    const maxDepth = getMaxDepth(submarine.level) || 600;
    let trackHeightPercent: number = 0;

    const commonColor = '#11C6D2'; // стандартный цвет глубины
    const warningColor = '#f50039'; // цвет опасности! Если юзер преодолел максимальную глубину

    useIsAspectRaito('.control-module__depthmeter', 'height', 'width');

    useEffect(() => {
        const depthTrackHeight = currentDepthRef.current?.parentElement?.getBoundingClientRect().height as number;
        trackHeightPercent = depthTrackHeight / maxDepth;

        mediator.subscribe(UPDATE_GAME_SCENES, updateDepthIndicator);

        return () => {
            mediator.unsubscribe(UPDATE_GAME_SCENES, updateDepthIndicator);
        }
    });

    const updateDepthIndicator = () => {
        const submarine = mediator.get(GET_SUBMARINE) as TSubmarine;
        const currentDepthValue = Number(submarine.z);

        const currentDepthTrack = currentDepthRef.current as HTMLDivElement;
        const depthIndicator = depthIndicatorRef.current as HTMLDivElement;
        
        if (currentDepthTrack && depthIndicator) {
            if (trackHeightPercent*currentDepthValue <= trackHeightPercent*maxDepth) {
                currentDepthTrack.style.height = (trackHeightPercent*currentDepthValue).toFixed(1)+'px';
            }
            depthIndicator.innerHTML = currentDepthValue.toFixed(0)+'м.';

            const wrapper = depthWrapperRef.current as HTMLDivElement;
            if (currentDepthValue > maxDepth) {
                wrapper.style.color = warningColor;
            } else {
                wrapper.style.color = commonColor;
            }
        }
    }

    return(
        <div className="control-module__depth">
            <span>Глубина</span>
            <div ref={depthWrapperRef} className="control-module__depthmeter-wrapper">
                <div className="control-module__depth-indicator">
                    <div
                        ref={currentDepthRef}
                        className="depth-indicator__current-depth"
                    />
                </div>
                <div 
                    ref={depthIndicatorRef}
                    className="control-module__depthmeter"
                />
            </div>
        </div>
    );
}

export default Depth;