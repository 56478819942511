import React, { useContext } from "react";
import { MediatorContext } from "../../../../App";
import { ESTATUS } from "../../../../services/server/types";

import './Frame.scss';

const Frame: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const { USER_SET_DIRECTION_STATUS } = mediator.getEventTypes();

    const menuBackHandler = () => mediator.call(USER_SET_DIRECTION_STATUS, { status: ESTATUS.USE });

    return (
        <div className="control-panel__frame">
            <div className="control-panel__frame-angle" />
            <div className="control-panel__frame-angle" />
            <div className="control-panel__frame-angle" />
            <button 
                className="control-panel__frame-angle control-panel__close-button"
                onClick={menuBackHandler}
            >
                <div className="control-panel__close-icon" />
            </button>
        </div>
    );
}

export default Frame;