import CONFIG, { TCompartment, ECOMPARTMENT_TYPE } from "../../config";
import { EDAMAGE, EDIRECTION, ESTATUS, TDamage, TSailor } from "../../services/server/types";
import { TInteriorGame } from "../InteriorGame";
import BaseInterior, { TDirectionStatus } from "../BaseInterior";

type TOpenCloseHatch = {
    index: number;
    open: boolean;
}

export default class Interior extends BaseInterior {
    STEP_SIZE: number;
    PERSON_WIDTH: number;
    PERSON_HEIGHT: number;
    DAMAGE_RADIUS: number;
    canRepair: boolean;

    compartments: TCompartment[];

    constructor(options: TInteriorGame) {
        super(options);
        const { mediator, submarine } = options;
        const { SUBMARINES, STEP_SIZE, PERSON_WIDTH, PERSON_HEIGHT, DAMAGE_RADIUS } = CONFIG.GAME;
        this.STEP_SIZE = STEP_SIZE;
        this.PERSON_WIDTH = PERSON_WIDTH;
        this.PERSON_HEIGHT = PERSON_HEIGHT;
        this.DAMAGE_RADIUS = DAMAGE_RADIUS;
        // @ts-ignore
        this.compartments = SUBMARINES[submarine.level];
        this.canRepair = false;

        const { USER_SET_DIRECTION_STATUS, SAILOR_MOVE, SAILOR_OPEN_CLOSE_HATCH } = mediator.getEventTypes();
        mediator.subscribe(USER_SET_DIRECTION_STATUS, (data: TDirectionStatus) => this.setDirectionStatus(data));
        mediator.subscribe(SAILOR_MOVE, (person: TSailor) => this.setSailorMoveHandler(person));
        mediator.subscribe(SAILOR_OPEN_CLOSE_HATCH, (data: TOpenCloseHatch) => this.setSailorOpenCloseHatchHandler(data));

        this.initCompartments();
    }

    destructor(): void {
        const { USER_SET_DIRECTION_STATUS, SAILOR_MOVE, SAILOR_OPEN_CLOSE_HATCH } = this.mediator.getEventTypes();
        this.mediator.unsubscribeAll(USER_SET_DIRECTION_STATUS);
        this.mediator.unsubscribeAll(SAILOR_MOVE);
        this.mediator.unsubscribeAll(SAILOR_OPEN_CLOSE_HATCH);
    }

    initCompartments(): void {
        this.compartments.forEach(compartment => {
            if (compartment.type === ECOMPARTMENT_TYPE.HATCH) {
                compartment.open = true; // открыть люки, если они были закрыты в прошлой игре
            } else if (compartment.type === ECOMPARTMENT_TYPE.COMPARTMENT) {
                compartment.water = 0; // удалить всю разлившуюся с прошлого раза воду
            }
        });
    }

    getHatch(x: number, y: number): number {
        let result = -1;
        this.compartments.forEach((compartment, index) => {
            if (compartment.type === ECOMPARTMENT_TYPE.HATCH) {
                const point = compartment.points[0].reduce((S, p) => {
                    S.x += p.x;
                    S.y += p.y;
                    return S;
                }, { x: 0, y: 0 });
                point.x /= 4;
                point.y /= 4;
                if (Math.sqrt(
                    Math.pow(point.x - (x + this.PERSON_WIDTH / 2), 2) +
                    Math.pow(point.y - (y + this.PERSON_HEIGHT / 2), 2)) < 0.8
                ) {
                    result = index;
                }
            }
        });
        return result;
    }

    // выставить позицию конкретному морячку
    setPersonPosition(person: TSailor, data: TSailor): void {
        const { direction, status, x, y } = data;
        person.direction = direction;
        person.status = status;
        person.x = x;
        person.y = y;
    }

    // пересечение моряка и лестницы
    getLadderCollision(preson: TSailor): null | number[] {
        const sailorCenter = preson.x + this.PERSON_WIDTH / 2; // чтобы юзерам не пришлось точно попадать в спрайт лестницы
        const points = [
            preson.y,
            preson.y + this.PERSON_HEIGHT,
            sailorCenter,
            sailorCenter
        ];

        return this.findSailorLocation(ECOMPARTMENT_TYPE.LADDER, points);
    }

    stickSailorToLadder(person: TSailor, ladderPosition: number[]): void {
        const [compartment, ladder] = ladderPosition;
        const ladderPoints = this.compartments[compartment].points[ladder];     // берем нужную лестницу
        const ladderCenter = (ladderPoints[0].x + ladderPoints[1].x) / 2;       // ее центр
        const newPosX = ladderCenter - this.PERSON_WIDTH / 2;
        person.x = newPosX;                                                     // помещаем морячка в центр лестницы(магнитим его туда)
    }

    stickSailorToCompartment(person: TSailor, compartment: number[]): void {
        const newPosY = this.compartments[compartment[0]].points[compartment[1]][0].y + 0.01; // 0.01 чтобы не застревал
        person.y = newPosY;                                                                   // ставит морячка на пол
    }

    // возвращает индексы объекта(compartment), если моряк находится внутри него
    findSailorLocation(
        type: ECOMPARTMENT_TYPE,
        points: number[] // points - крайние точки морячка
    ): null | number[] {
        let result: null | number[] = null;

        this.compartments.forEach((compartment, index) => {
            if (compartment.type === type) {
                compartment.points.forEach((compartmentPoints, compIndex) => {
                    if (
                        compartmentPoints[0].y <= points[0] &&  // нижняя граница
                        compartmentPoints[2].y >= points[1] &&  // верхняя граница
                        compartmentPoints[0].x <= points[2] &&  // левая граница
                        compartmentPoints[1].x >= points[3]     // правая гранциа
                    ) {
                        result = [index, compIndex];
                    }
                });
            }
        });

        return result;
    }

    /********************************/
    /* Обработчики сокетных событий */
    /********************************/
    // сокетное событие с обновлением позиции игрока
    setSailorMoveHandler(data: TSailor): void {
        if (this.submarine.captain.guid === data.guid) {
            this.setPersonPosition(this.submarine.captain, data);
            return;
        }
        const sailor = this.submarine.sailors.find(sailor => sailor.guid === data.guid);
        if (sailor) {
            this.setPersonPosition(sailor, data);
        }
    }

    // сокетное событие открытия/закрытия люка
    setSailorOpenCloseHatchHandler(data: TOpenCloseHatch) {
        const { index, open } = data;
        const hatch = this.compartments[index];
        hatch.open = open;
    }
    /********************************/

    /*****************************/
    /* Отправка событий в бекенд */
    /*****************************/
    setDirectionStatus(data: TDirectionStatus): void {
        const { direction, status } = data;
        if (status === ESTATUS.USE) { // за взаимодействие с оборудованием отвечает свой класс
            return;
        }
        const person = this.getPersonByGuid(this.user?.guid);
        if (person) {
            if (person.status === ESTATUS.USE) { // чтобы чувака нельзя было двигать, пока он что-то использует
                return;
            }
            if ((
                direction === EDIRECTION.UP ||
                direction === EDIRECTION.DOWN
            ) && person.direction !== direction
            ) { // взбирание по лестнице
                const isNearLadder = this.getLadderCollision(person);
                if (isNearLadder) {
                    //this.stickSailorToLadder(person, isNearLadder); // магнит к лестнице
                } else {
                    return;
                }
            }
            if ((  // слезть с лестницы
                person.direction === EDIRECTION.UP ||
                person.direction === EDIRECTION.DOWN
            ) && (
                    direction === EDIRECTION.RIGHT ||
                    direction === EDIRECTION.LEFT
                )) {
                const compartment = this.findSailorLocation(
                    ECOMPARTMENT_TYPE.COMPARTMENT,
                    [person.y, person.y + this.PERSON_HEIGHT, person.x, person.x + this.PERSON_WIDTH]
                );
                if (compartment) {
                    this.stickSailorToCompartment(person, compartment);
                } else {
                    return;
                }
            }
            let sendMessage = false;
            if (direction !== undefined && person.direction !== direction) {
                person.direction = direction;
                sendMessage = true;
            }
            if (person.status !== status) {
                person.status = status;
                sendMessage = true;
            }
            // подвинуть
            if (sendMessage) {
                const { x, y } = person;
                this.server.sailorMove({ direction, status, x, y });
            }
            // открыть/закрыть дверку
            if (status === ESTATUS.CLOSE) {
                const index = this.getHatch(person.x, person.y);
                if (index > -1) {
                    const hatch = this.compartments[index];
                    hatch.open = !hatch.open;
                    this.server.sailorOpenCloseHatch({ index, open: hatch.open });
                }
            }
        }
    }
    /*****************************/

    // передвинуть персонажа
    movePerson(person: TSailor) {
        if (
            person &&
            person.status === ESTATUS.WALK
        ) {
            const points = [
                { x: person.x, y: person.y }, // левый нижний угол
                { x: person.x, y: person.y + this.PERSON_HEIGHT }, // левый верхний угол
                { x: person.x + this.PERSON_WIDTH, y: person.y + this.PERSON_HEIGHT }, // правый верхний угол
                { x: person.x + this.PERSON_WIDTH, y: person.y }, // правый нижний угол
            ];
            let canMove = [false, false, false, false];
            let dx = person.direction === EDIRECTION.RIGHT ? this.STEP_SIZE : person.direction === EDIRECTION.LEFT ? -this.STEP_SIZE : 0;
            let dy = person.direction === EDIRECTION.UP ? this.STEP_SIZE : person.direction === EDIRECTION.DOWN ? -this.STEP_SIZE : 0;
            points.forEach((point, index) => {
                this.compartments.forEach(compartment => {
                    if (compartment.type === ECOMPARTMENT_TYPE.HATCH && !compartment.open) {
                        return;
                    }
                    compartment.points.forEach(points => {
                        if (!canMove[index] && this.collision(point.x + dx, point.y + dy, points)) {
                            canMove[index] = true;
                        }
                    });
                });
            });
            if (canMove.reduce((S, val) => S && val, true)) {
                person.x += dx;
                person.y += dy;
                // выставить позицию самому юзеру
                if (person.guid === this.user?.guid) {
                    this.user.x = person.x;
                    this.user.y = person.y;
                }
            }
        }
    }

    // коснулся ли повреждения персонаж
    isTouchDamage(x: number, y: number, RADIUS = 0): TDamage | null {
        const points = [
            { x, y }, // левый нижний угол
            { x, y: y + this.PERSON_HEIGHT }, // левый верхний угол
            { x: x + this.PERSON_WIDTH, y: y + this.PERSON_HEIGHT }, // правый верхний угол
            { x: x + this.PERSON_WIDTH, y }, // правый нижний угол
        ];
        for (let i = 0; i < this.submarine.damages.length; i++) {
            const damage = this.submarine.damages[i];
            for (let j = 0; j < points.length; j++) {
                if (Math.sqrt((points[j].x - damage.x) ** 2 + (points[j].y - damage.y) ** 2) < damage.value / this.DAMAGE_RADIUS + RADIUS) {
                    return damage;
                }
            }
        }
        return null;
    }

    isTouchRepair(x: number, y: number): TDamage | null {
        return this.isTouchDamage(x, y, this.PERSON_WIDTH);
    }

    // расчёт смерти игрока от повреждения, и возможности его починки
    calcDamages(): void {
        if (this.user) {
            const { USER_CAN_REPAIR, USER_DIE_BY_FIRE, USER_DIE_BY_WATER } = this.mediator.getEventTypes();
            const { x, y } = this.user;
            const damage = this.isTouchDamage(x, y);
            if (damage?.type === EDAMAGE.FIRE) {
                this.server.submarineCancel(); // сгорел (на работе)
                this.mediator.call(USER_DIE_BY_FIRE);
                return;
            }
            // если утонул на работе
            for (let i = 0; i < this.compartments.length; i++) {
                const compartment = this.compartments[i];
                if (compartment.water) {
                    const waterLevel = this.getWaterLevel(compartment);
                    const x1 = compartment.points[0][0].x;
                    const x2 = compartment.points[0][1].x;
                    if (x + this.PERSON_WIDTH >= x1 && x <= x2 && waterLevel > y + this.PERSON_HEIGHT) {
                        this.server.submarineCancel(); // утонул (на работе)
                        this.mediator.call(USER_DIE_BY_WATER);
                        return;
                    }
                }
            }
            // может игрок чинить повреждение или нет?
            const repair = this.isTouchRepair(x, y);
            if (repair) {
                if (this.canRepair) {
                    return;
                }
                this.canRepair = true;
                this.mediator.call(USER_CAN_REPAIR, { canRepair: this.canRepair, repair });
            } else {
                if (!this.canRepair) {
                    return;
                }
                this.canRepair = false;
                this.mediator.call(USER_CAN_REPAIR, { canRepair: this.canRepair });
            }
        }
    }

    update(): void {
        // сначала подвинуть капитана
        this.movePerson(this.submarine.captain)
        // потом подвинуть команду
        this.submarine.sailors.forEach(sailor => this.movePerson(sailor));
        this.calcDamages(); // вычислить наступил игрок на повреждение или нет
    }
}