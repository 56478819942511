import React, { useContext, useEffect } from "react";
import { TSubmarine } from "../../../services/server/types";
import CONFIG from "../../../config";
import { MediatorContext, ServerContext } from "../../../App";
import { TSubmarineType } from "../../../services/server/types";
import PurchaseCard from "./PurchaseCard/PurchaseCard";

import './ShopPage.scss';

interface IShopPage {
    submarine: TSubmarine;
    isSubmarineBuyButton?:boolean;
}

const SubmarinePurchase: React.FC = () => {
    const server = useContext(ServerContext);
    const mediator = useContext(MediatorContext);
    const { GET_STORAGE } = mediator.getTriggerTypes();
    const { SUBMARINE_TYPES } = CONFIG.STORAGE;

    const submarineTypes = mediator.get<TSubmarineType[]>(GET_STORAGE, SUBMARINE_TYPES);

    useEffect(() => {
        const card = document.querySelector('.shop-page__purchase-submarine') as HTMLDivElement;
        if (card) {
            const height = card.getBoundingClientRect().height;
            if (height) {
                card.style.setProperty('width', `${height + 10}px`);
            }
        }
    });

    if (!submarineTypes) {
        return <></>;
    }

    const submarine = submarineTypes.find(submarine => submarine.level === 2);

    const buyHandler = () => {
        if (submarine) {
            server.buySubmarine(submarine.level);
        }
    }

    return (
        <button onClick={buyHandler} className="shop-page__purchase-submarine">
            <div className="purchase-card__cost">{ submarine?.cost }</div>
            <span>Приобрести { submarine?.name.slice(0, submarine?.name.length-1) + 'у' }</span>
        </button>
    )
}

const ShopPage: React.FC<IShopPage> = (props: IShopPage) => {
    const { submarine, isSubmarineBuyButton = false } = props;

    return (
        <div className="shop__shop-page">
            <span>{ submarine.name }</span>
            { isSubmarineBuyButton ? <SubmarinePurchase /> : <></>}
            <div className="shop-page__upgrade-block">
                <span>Приобрести улучшения</span>
                <div>
                {submarine.equipments.map(equipment =>
                    Object.values(equipment.properties).map((property, index) =>
                        <PurchaseCard key={index} submarineId={submarine.id} equipmentId={equipment.id} property={property}/>
                    )
                )}
                </div>
            </div>
        </div>
    );
}

export default ShopPage;