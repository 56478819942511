import Server, { TUseServer } from './Server';

export default function useServer(options: TUseServer): Function {
    const { MESSAGES, HOST, STORAGE, mediator } = options;
    return () => new Server({
        MESSAGES,
        HOST,
        STORAGE,
        mediator,
    });
}