import * as THREE from 'three';
import WebGL from 'three/examples/jsm/capabilities/WebGL';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import Three, { TThree, type TCanvasPoint } from './Three';
import { BoomManager, Manager } from './managers/index';

interface IThreeCanvas {
    WIDTH: number;
    HEIGHT: number;
    camera: THREE.OrthographicCamera;
    scene: THREE.Scene;
    renderer: THREE.WebGLRenderer;
}

export type TThreeCanvas = {
    parentId: string;
    WIDTH: number;
    HEIGHT: number;
}

class ThreeCanvas extends Three implements IThreeCanvas {
    models: Map<string, GLTF>;

    userSubmarine: THREE.Group<THREE.Object3DEventMap>|null;
    userTorpedo: THREE.Group<THREE.Object3DEventMap>|null;
    sonarField: THREE.Mesh|null;
    weaponRangeCircle: THREE.Line|null;
    animationMixers: Map<string, THREE.AnimationMixer>;
    bases: Map<string, THREE.Group<THREE.Object3DEventMap>>;
    lineHalpers: Map<string, {line: THREE.Line, pointer:THREE.Group<THREE.Object3DEventMap>}>;
    userCourse: number;
    boomManager;
    torpedoManager;
    submarineManager;
    baseManager;
    botManager;

    constructor(options: TThree & { models: Map<string, GLTF>}) {
        super(options);
        this.models = options.models;
        const torpedoModel = this.models.get('torpedo') as GLTF;
        const submarineLevel1Model = this.models.get('submarineLevel1') as GLTF;
        const submarineLevel2Model = this.models.get('submarineLevel2') as GLTF;
        const baseModel = this.models.get('base') as GLTF;

        this.camera.position.z = this.sonarRange;
        this.camera.position.y = this.sonarRange/2;
        this.camera.lookAt(new THREE.Vector3(0,0,0));

        // все менеджеры анимаций
        this.animationMixers = new Map();
        
        // Коллекции мэшей на сцене
        this.bases = new Map();
        this.lineHalpers = new Map();

        // Про юзера (для удобства)
        this.userSubmarine = null;
        this.userTorpedo = null;
        this.sonarField = null;
        this.weaponRangeCircle = null;
        this.userCourse = 0;

        this.boomManager = new BoomManager({ 
            texture: this.textures.boom, 
            scene: this.scene 
        });
        this.torpedoManager = new Manager({
            scene: this.scene,
            gltfModels: [torpedoModel]
        });
        this.submarineManager = new Manager({
            scene: this.scene,
            gltfModels: [submarineLevel1Model, submarineLevel2Model],
        });
        this.baseManager = new Manager({
            scene: this.scene,
            gltfModels: [baseModel]
        });
        this.botManager = new Manager({
            scene: this.scene,
            gltfModels: [submarineLevel1Model, submarineLevel2Model]
        });

        // если у юзака отключена WebGL
        if (!WebGL.isWebGLAvailable()) {
            const warning = WebGL.getWebGLErrorMessage();
            document.getElementById(this.parentId)?.appendChild(warning);
        }
        
        this.createSonarFieldBackground();
        //this.drawAxisHelper();
    }

    public destructor() {
        super.destructor();
        this.renderer.setAnimationLoop(null);
    }

    // функция рендера
    public render(renderFunction: Function): void {
        this.renderer.setAnimationLoop(() => {
            renderFunction();   // calculations;
            this.updateAnimations();
            //this.checkMemory(); // check on memory leak
            this.renderer.render(this.scene, this.camera);  // update scene
        });
    }

    // обновление анимаций 3д-объектов
    private updateAnimations() {
        if (this.animationMixers.size > 0) {
            this.animationMixers.forEach((mixer, _) => mixer.update(this.clock.getDelta()));
        }
    }

    public getScreenCoordinates(guid: string, type: 'submarine') {
        const item = this[`${type}Manager`].getItem(guid);
        if (item) {
            return this.toScreenCoords(item.mesh);
        }
    }

    // убрать со сцены лишние сущности
    public removeNeedlessItems(sceneItems: Set<string>, type: 'submarine'|'torpedo'|'base'|'bot') {
        this[`${type}Manager`].removeUnnecessaryItems(sceneItems);
    }

    // Нарисовать лодку юзера
    public drawUserSubmarine(userCourse: number, level:number): void {
        if (this.userTorpedo) { // reset
            this.scene.remove(this.userTorpedo);
            this.userTorpedo = null;
            if (this.weaponRangeCircle) {
                this.weaponRangeCircle.scale.set(this.weaponRange, this.weaponRange, 1);
            }
        }

        const submarine = this.userSubmarine; // также выступает ссылкой на объект на сцене

        if (!submarine) { // если нету субмарины -> закинуть ее;
            const sumbarineMesh = this.models.get('submarineLevel'+level);
            if (sumbarineMesh) {
                const submarineMeshClone = sumbarineMesh.scene.clone();
                this.userSubmarine = submarineMeshClone;
                this.scene.add(submarineMeshClone);
            }
        } else {
            this.userCourse = userCourse;
        }
    }

    // Нарисовать торпеду юзера
    public drawUserTorpedo(userCourse: number, range: number): void {
        if (this.userSubmarine) {
            this.scene.remove(this.userSubmarine);
            this.userSubmarine = null;
        }

        const torpedo = this.userTorpedo;

        if (!torpedo) {
            const torpedoMesh = this.models.get('torpedo');
            if (torpedoMesh) {
                const torpedoMeshClone = torpedoMesh.scene.clone();
                this.userTorpedo = torpedoMeshClone;
                this.scene.add(torpedoMeshClone);
            }
        } else {
            this.userCourse = userCourse;
            if (this.weaponRangeCircle) {
                this.weaponRangeCircle.scale.set(range, range, 1);
            }
        }
    }

    // рисуем торпеду
    public drawTorpedo({ point, guid, course, pitch }: {
        point: TCanvasPoint
        guid: string
        course: number
        pitch: number
    }): void {
        const { x, y, z } = this.changeAxis(point);
        const torpedo = this.torpedoManager.getItem(guid);
        if (!torpedo) {
            this.torpedoManager.createItem({ 
                guid, 
                x, y, z, 
                scale: .75
            });
        } else {
            torpedo.updateMesh({ x, y, z, userCourse:this.userCourse, course, pitch });
        }
    }

    // рисуем субмарину
    public drawSubmarine({ guid, level, point, course, pitch, }: {
        guid: string
        level: number
        point: TCanvasPoint
        course: number
        pitch: number
    }): void {
        const { x, y, z } = this.changeAxis(point);
        const submarine = this.submarineManager.getItem(guid);
        if (!submarine) {
            this.submarineManager.createItem({ 
                guid, 
                x, y, z, 
                modelIndex: level-1, 
                scale: .75 
            });
        } else {
            submarine.updateMesh({ x, y, z, userCourse:this.userCourse, course, pitch });
        }
    }

    public drawBot({ guid, level, point, course, pitch, }: {
        guid: string
        level: number
        point: TCanvasPoint
        course: number
        pitch: number
    }): void {
        const { x, y, z } = this.changeAxis(point);
        const bot = this.botManager.getItem(guid);
        if (!bot) {
            this.botManager.createItem({ 
                guid, 
                x, y, z, 
                modelIndex: level-1, 
                scale: .75 
            });
        } else {
            bot.updateMesh({ x, y, z, userCourse:this.userCourse, course, pitch });
        }
    }

    // рисуем взрыв
    public drawBoom(guid: string, point: TCanvasPoint, timestamp:number): void {
        const { x, y, z } = this.changeAxis(point);
        const boom = this.boomManager.getBoom(guid);
        if (!boom) {
            this.boomManager.createBoom(guid, timestamp, x, y, z);
        } else {
            this.boomManager.updateBoomPosition(guid, x, y, z);
        }
    }

    // рисуем базу
    public drawBase(baseData: TCanvasPoint & {intersectionPlane: number}, guid: string): void {
        if (this.baseRadius > 0) {
            const { x, y, z } = this.changeAxis(baseData);
            const base = this.baseManager.getItem(guid);
            const { intersectionPlane } = baseData;

            if(!base) {
                const newBase = this.baseManager.createItem({ guid, x, y, z, isIntersectionCircle: true });
                if (!this.animationMixers.has(guid) && newBase) {
                    this.animationMixers.set(guid, newBase.animationMixer as THREE.AnimationMixer);
                }
            } else {
                base.updateMesh({ 
                    x, y, z, 
                    userCourse: this.userCourse, 
                    intersectionCircle: intersectionPlane
                });
            }
        }
    }

    // рисуем влажок базы
    public drawBaseFlag(basePoint: TCanvasPoint): void {
        //const { x, y, z } = this.changeAxis(basePoint);
    }

    public rotateSonarField(angle: number): void {
        if (this.sonarField) { this.sonarField.rotateZ(angle/144); }
    }

    private drawWeaponRangeCircle() {
        const weaponRangeCurve = new THREE.EllipseCurve(0, 0, 1, 1, 0,2 *Math.PI);
        const weaponRangePoints = weaponRangeCurve.getPoints(64);
        const weaponrangeGeometry = new THREE.BufferGeometry().setFromPoints(weaponRangePoints);
        const weaponRangeMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 });
        const weaponrangeCircel = new THREE.Line(weaponrangeGeometry, weaponRangeMaterial);

        weaponrangeCircel.rotateX(Math.PI/2);
        weaponrangeCircel.scale.set(this.weaponRange, this.weaponRange, 1);

        this.weaponRangeCircle = weaponrangeCircel;
        this.scene.add(weaponrangeCircel);
    }

    private createSonarFieldBackground(horizontalSegments:number = 8, verticalSegments:number = 11): void {
        const color = 0x1ab3c7;

        const innerCircleMaterial = new THREE.LineBasicMaterial({ color, transparent:true, opacity:.4 });
        const outerCircleGeometry = new THREE.RingGeometry(this.sonarRange-this.sonarRange*0.01, this.sonarRange, this.sonarRange*0.25);
        const outerCircleMaterial = new THREE.MeshBasicMaterial({ color });
        const outerCircle = new THREE.Mesh(outerCircleGeometry, outerCircleMaterial);

        // нарисовать круги
        for(let i = 0; i <= this.sonarRange; i+= this.sonarRange/horizontalSegments) {
            const segments = i < 100 ? 32 : 64;
            const curve = new THREE.EllipseCurve(0, 0, i, i, 0,2 *Math.PI);
            const points = curve.getPoints(segments);
        
            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            const circle = new THREE.Line(geometry, innerCircleMaterial);
            outerCircle.add(circle);
        }

        // нарисовать линии
        for(let i = 0; i <= Math.PI*2; i += Math.PI*2/verticalSegments) {
            const lineGeometry = new THREE.BufferGeometry().setFromPoints(
                [new THREE.Vector2(0, -this.sonarRange), new THREE.Vector2(0, this.sonarRange)]
            );
            const line = new THREE.Line(lineGeometry, innerCircleMaterial);
            line.rotateZ(i)
            outerCircle.add(line);
        }

        outerCircle.rotateX(Math.PI/2);

        this.sonarField = outerCircle;

        this.scene.add(outerCircle);
        // радиус поражения торпедой
        this.drawWeaponRangeCircle();
    }
}

export default ThreeCanvas;