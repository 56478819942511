import React, { useState, useEffect, useRef, useCallback } from "react";
import { EDIRECTION } from "../../../../../services/server/types";

interface IMoveButton {
    classes: string; 
    direction: EDIRECTION;
    moveHandler: () => void; 
    stopHandler: () => void;
}

const MoveButton: React.FC<IMoveButton> = (props: IMoveButton) => {
    const { classes, direction, moveHandler, stopHandler } = props;
    const [buttonIsDown, setButtonIsDown] = useState<boolean>(false);
    const firstUpdate = useRef(true);

    useEffect(() => {
        // проверка на первый рендер (не вызваем событие стоп при монтировании компонента)
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (buttonIsDown) {
            moveHandler();
        } else {
            stopHandler();
        }
    }, [buttonIsDown, direction])

    const buttonTouchStart = useCallback(() => setButtonIsDown(true), []);
    const buttonTouchEnd = useCallback(() => setButtonIsDown(false), []);

    return <button
        className={`${classes} ${EDIRECTION[direction].toLowerCase()}`}
        onTouchStart={buttonTouchStart}
        onMouseDown={buttonTouchStart}
        onMouseUp={buttonTouchEnd}
        onTouchEnd={buttonTouchEnd}
        onTouchCancel={buttonTouchEnd}
        onMouseLeave={buttonTouchEnd}
    />
}

export default MoveButton;