import { TWINDOW } from "../../config";
//import waterTile from '../../assets/img/water_tile.png';

enum EDIRECTION {
    UP,
    DOWN,
    LEFT,
    RIGHT,
}

interface ICanvas {
    // контексты и канвасы
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
    canvasV: HTMLCanvasElement;
    contextV: CanvasRenderingContext2D;
    // общая ширина и высота канвасов
    WIDTH: number;
    HEIGHT: number;
    WINDOW: TWINDOW,
    DIRECTION: {
        [EDIRECTION.UP]: number,
        [EDIRECTION.DOWN]: number,
        [EDIRECTION.LEFT]: number,
        [EDIRECTION.RIGHT]: number,
    }
}

export type TCanvas = {
    parentId: string;
    WINDOW: TWINDOW;
    SIZE?: number;
    WIDTH?: number;
    HEIGHT?: number;
}

class Canvas implements ICanvas {
    // контексты и канвасы
    canvas;
    context;
    canvasV;
    contextV;
    // общая ширина и высота канвасов
    WIDTH;
    HEIGHT;
    WINDOW;
    SIZE;
    //waterTile;
    //pattern;
    DIRECTION = {
        [EDIRECTION.UP]: -90 * Math.PI / 180,
        [EDIRECTION.DOWN]: 90 * Math.PI / 180,
        [EDIRECTION.LEFT]: 180 * Math.PI / 180,
        [EDIRECTION.RIGHT]: 0,
    }

    constructor(options: TCanvas) {
        const { parentId, WINDOW, SIZE, WIDTH, HEIGHT } = options;
        this.SIZE = SIZE;
        // задаём канвасы
        this.canvas = document.createElement('canvas');
        if (parentId) {
            document.getElementById(parentId)?.appendChild(this.canvas);
        } else {
            document.querySelector('body')?.appendChild(this.canvas);
        }
        this.WIDTH = WIDTH || window.innerWidth;
        this.HEIGHT = HEIGHT || window.innerHeight; // потому что иначе эта скотина добавляет вертикальный скролл
        // main canvas
        this.canvas.width = this.WIDTH;
        this.canvas.height = this.HEIGHT;
        this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D;
        // virtual canvas
        this.canvasV = document.createElement('canvas');
        this.canvasV.width = this.WIDTH;
        this.canvasV.height = this.HEIGHT;
        this.contextV = this.canvasV.getContext('2d') as CanvasRenderingContext2D;
        // задаем окошко
        this.WINDOW = WINDOW;
        if (!WIDTH && !HEIGHT && SIZE) {
            this.WINDOW.WIDTH = this.WIDTH / SIZE;
            this.WINDOW.HEIGHT = this.HEIGHT / SIZE;
        }
        // тайл воды xD
        //this.waterTile = new Image();
        //this.waterTile.src = waterTile;
        //this.pattern = this.contextV.createPattern(this.waterTile, 'repeat') as CanvasPattern;
    }

    resizeCanvas(): void {
        this.WIDTH = window.innerWidth;
        this.HEIGHT = window.innerHeight;
        this.canvas.width = this.WIDTH;
        this.canvas.height = this.HEIGHT;
        this.canvasV.width = this.WIDTH;
        this.canvasV.height = this.HEIGHT;
        if (this.SIZE) {
            this.WINDOW.WIDTH = this.WIDTH / this.SIZE;
            this.WINDOW.HEIGHT = this.HEIGHT / this.SIZE;
        }
    }

    destructor() {
        // @ts-ignore
        this.contextV = null;
        // @ts-ignore
        this.canvasV = null;
        // @ts-ignore
        this.context = null;
        // @ts-ignore
        this.canvas = null;
    }

    // перевод в экранные координаты
    xs(x: number): number {
        return (x - this.WINDOW.LEFT) / this.WINDOW.WIDTH * this.WIDTH;
    }
    ys(y: number): number {
        return this.HEIGHT - (y - this.WINDOW.BOTTOM) / this.WINDOW.HEIGHT * this.HEIGHT;
    }

    dec(x: number): number {
        return x / this.WINDOW.WIDTH * this.WIDTH;
    }

    clear(): void {
        this.contextV.fillStyle = '#102130';
        //this.contextV.fillStyle = this.pattern;
        this.contextV.fillRect(0, 0, this.WIDTH, this.HEIGHT);
        //this.contextV.drawImage(this.canvasM, 0, 0);
    }

    line(x1: number, y1: number, x2: number, y2: number, color = '#0f0', width = 2): void {
        this.contextV.beginPath();
        this.contextV.strokeStyle = color;
        this.contextV.lineWidth = width;
        this.contextV.moveTo(this.xs(x1), this.ys(y1));
        this.contextV.lineTo(this.xs(x2), this.ys(y2));
        this.contextV.stroke();
    }

    text(x: number, y: number, text: string, color = '#fff', font = 'bold 1rem Arial'): void {
        this.contextV.fillStyle = color;
        this.contextV.font = font;
        this.contextV.fillText(text, this.xs(x), this.ys(y));
    }

    point(x: number, y: number, color = '#f00', size = 2): void {
        this.contextV.beginPath();
        this.contextV.fillStyle = color;
        this.contextV.arc(this.xs(x), this.ys(y), size, 0, 2 * Math.PI);
        this.contextV.stroke();
        this.contextV.closePath();
        this.contextV.fill();
    }

    pointDec(x: number, y: number, color = '#f00', size = 1, onlyLine = false): void {
        this.contextV.beginPath();
        if (onlyLine) {
            this.contextV.strokeStyle = color;
            this.contextV.fillStyle = '#fff0';
        } else {
            this.contextV.fillStyle = color;
        }
        this.contextV.arc(this.xs(x), this.ys(y), this.dec(size), 0, 2 * Math.PI);
        this.contextV.stroke();
        this.contextV.closePath();
        this.contextV.fill();
    }

    rect(x: number, y: number, size = 64, color = '#f004'): void {
        this.contextV.fillStyle = color;
        this.contextV.fillRect(this.xs(x), this.ys(y), size, size)
    }

    // прямоугольник. НЕ квадрат
    rectangle(x: number, y: number, width = 64, height = 64, color = '#f004'): void {
        this.contextV.fillStyle = color;
        this.contextV.fillRect(this.xs(x), this.ys(y), width, height);
    }

    polygon(points: Array<{ x: number, y: number }>, color = '#080b'): void {
        this.contextV.fillStyle = color;
        this.contextV.beginPath();
        this.contextV.moveTo(this.xs(points[0].x), this.ys(points[0].y));
        for (let i = 1; i < points.length; i++) {
            this.contextV.lineTo(this.xs(points[i].x), this.ys(points[i].y));
        }
        this.contextV.closePath();
        this.contextV.fill();
    }

    sprite(image: HTMLImageElement, dx: number, dy: number, dSize: number, sx: number, sy: number, sSize: number): void {
        if (dSize && sx >= 0 && sy >= 0 && sSize) {
            this.contextV.drawImage(image, sx, sy, sSize, sSize, this.xs(dx), this.ys(dy), dSize, dSize);
            return;
        }
        if (dSize) {
            this.contextV.drawImage(image, this.xs(dx), this.ys(dy), dSize, dSize);
            return;
        }
        this.contextV.drawImage(image, this.xs(dx), this.ys(dy));
    }

    spriteFull(image: HTMLImageElement, dx: number, dy: number, sx: number, sy: number, xSize: number, ySize: number): void {
        this.contextV.drawImage(image, sx, sy, xSize, ySize, this.xs(dx), this.ys(dy), xSize, ySize);
    }

    // спрайт с поворотом
    spriteDir(image: HTMLImageElement, dx: number, dy: number, dSize: number, sx: number, sy: number, sSize: number, direction: EDIRECTION): void {
        if (this.DIRECTION[direction] !== EDIRECTION.RIGHT) {
            const xs = this.xs(dx);
            const ys = this.ys(dy);
            const transX = xs + dSize / 2;
            const transY = ys + dSize / 2;
            this.contextV.save();
            this.contextV.translate(transX, transY);
            this.contextV.rotate(this.DIRECTION[direction] || EDIRECTION.RIGHT);
            this.contextV.translate(-transX, -transY);
            this.contextV.drawImage(image, sx, sy, sSize, sSize, xs, ys, dSize, dSize);
            this.contextV.restore();
            return;
        }
        this.sprite(image, dx, dy, dSize, sx, sy, sSize);
    }

    // копируем изображение с виртуального канваса на основной
    render(): void {
        this.context.drawImage(this.canvasV, 0, 0);
    }
}

export default Canvas;