import React, { useEffect, useState, useContext } from "react";
import { MediatorContext } from "../../../../App";
import { useGetRank, useGetRating, useGetUser } from "../../../../hooks";
import DynamicRank from "./DynamicRank/DynamicRank";

import './UserInfo.scss';

const UserInfo: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const [updateInfo, setUpdateInfo] = useState<number>(1);
    const { USER_UPDATE_INFO } = mediator.getEventTypes();
    const getUser = useGetUser(mediator);

    const user = getUser();
    const getRank = useGetRank(mediator);
    const getRating = useGetRating(mediator);

    useEffect(() => {
        const userUpdateInfoHandler = () => setUpdateInfo(updateInfo + 1);

        mediator.subscribe(USER_UPDATE_INFO, userUpdateInfoHandler);

        return () => {
            mediator.unsubscribe(USER_UPDATE_INFO, userUpdateInfoHandler);
        }
    });

    if (!user) {
        return (<></>);
    }

    const { name, rank, rating, money } = user;

    const rankInfo = getRank(rank);
    const ratingInfo = getRating(rating);
    const percentageToRankUp = Math.floor(rank / rankInfo.points * 100);

    return (
        <div className="lobby__user-info">
            <div className="user-info__namespace">
                <span className="user-info__name">{name}</span>
                <div>
                    <span>{ratingInfo.name}</span>
                    <div className="user-info__money">
                        <span>{money}</span>
                    </div>
                </div>
            </div>
            <DynamicRank title={rankInfo.name} percentage={percentageToRankUp} />
        </div>
    );
}

export default UserInfo;