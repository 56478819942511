import * as THREE from 'three';

interface IBoomManager {
    texture: THREE.Texture;
    scene: THREE.Scene|null;
}

class BoomManager {
    private texture;
    private booms: Map<string, THREE.Mesh>;
    private scene;
    private boom;

    constructor(options: IBoomManager) {
        const {
            texture,
            scene
        } = options;

        this.texture = texture;
        this.booms = new Map();
        this.scene = scene;

        const boomGeometry = new THREE.PlaneGeometry(1, 1);
        const boomMaterial = new THREE.MeshBasicMaterial({ 
            side: THREE.DoubleSide, 
            map: this.texture, 
            transparent: true,
            alphaTest: .7,
        });
        this.boom = new THREE.Mesh(boomGeometry, boomMaterial);
    }

    destructor() {
        this.scene = null;
        this.booms.clear();
        this.texture.dispose();
        this.boom.material.dispose();
        this.boom.geometry.dispose();
    }

    public createBoom (
        guid: string,
        timestamp: number,
        x: number, 
        y: number, 
        z: number,
    ): void {
        if (this.scene) {
            const boom = this.boom.clone();
            boom.position.set(x, y, z);
            this.booms.set(guid, boom);
            this.scene.add(boom);
            
            // boom scaler
            const interval = setInterval(() => {
                const { x, y } = boom.scale;
                const scaler = 2;

                boom.scale.set(x+scaler, y+scaler, 1);
            }, 50);

            // self destruct if time`s up
            const timeout = setTimeout(() => {
                if (this.scene) {
                    this.scene.remove(boom);
                    this.booms.delete(guid);
                } 

                clearInterval(interval);
                clearTimeout(timeout);
            }, timestamp);
        }
    }

    public updateBoomPosition (
        guid: string,
        x: number,
        y: number,
        z: number
    ): void {
        const boom = this.booms.get(guid);
        if (boom) {
            boom.position.set(x, y, z);
        }
    }
    
    public getBoom(guid: string): THREE.Mesh|undefined {
        return this.booms.get(guid);
    }
}

export default BoomManager;