import React, { useEffect, useRef } from "react";

import './DynamicRank.scss';

interface IDynamicRank {
    title: string;
    percentage: number;
}

const DynamicRank: React.FC<IDynamicRank> = (props: IDynamicRank) => {
    const { title, percentage } = props;
    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => { // сделал адаптивным не знаю зачем
        const svg = svgRef.current;
        if (svg) {
            const radius = svg.getBoundingClientRect().width/2;
            const circle = svg.firstChild as SVGCircleElement;
            if (circle) {
                circle.setAttribute('r', `${radius-1}`);
                circle.setAttribute('cx', `${radius}`);
                circle.setAttribute('cy', `${radius}`);

                const circumference = 2 * Math.PI * radius-1;
                const offset = circumference - percentage / 100 * circumference;

                circle.setAttribute('stroke-dasharray', `${circumference}`);
                circle.setAttribute('stroke-dashoffset', `${offset}`);
            }
        }
    }, [title, percentage]);

    const [first, rest] = title.split(/(?<=^\S+)\s/); // Да, потому что существует "Главный корабельный старшина"

    return (
        <div className="user-info__user-rank">
            { rest && <span>{ first }</span> }
            <div>
                <svg ref={svgRef}>
                    <circle/>
                </svg>
                <span>{ percentage }%</span>
            </div>
            <span>{ rest ? rest : first }</span>
        </div>
    );
}

export default DynamicRank;