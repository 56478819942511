import React, { useContext } from "react";
import { MediatorContext, ServerContext } from "../../../../../../../App";
import { useGetRank, useGetRating } from "../../../../../../../hooks";
import { TCrew } from "../../../../../../../services/server/types";
import CONFIG from "../../../../../../../config";
import { TCrewStatus } from "../../../CrewManageButton/CrewManageButton";

import './Crew.scss';

interface ICrew {
    crew: TCrew;
    closeHandler: () => void;
}

const Crew: React.FC<ICrew> = (props: ICrew) => {
    const { crew, closeHandler } = props;

    const mediator = useContext(MediatorContext);
    const server = useContext(ServerContext);
    const getRank = useGetRank(mediator);
    const getRating = useGetRating(mediator);
    const { SET_STORAGE } = mediator.getTriggerTypes();
    const { CREW_STATUS } = CONFIG.STORAGE;

    const joinToCrewHandler = () => {
        const crewId = crew.guid;
        server.joinToCrew({ crewId });
        // обновить значение в сторажде по новой команде
        mediator.get<TCrewStatus>(SET_STORAGE, { NAME: CREW_STATUS, value: { isInCrew: true, crewId } });
        closeHandler();
    }

    const captainRank = getRank(crew.captain.rank);

    const rankName = [1, 2].includes(captainRank.no) ? 'sailor' :
        [3, 4, 5, 6].includes(captainRank.no) ? 'foreman' :
        [7, 8].includes(captainRank.no) ? 'midshipman' :
        [9, 10, 11].includes(captainRank.no) ? 'lieutenant' :
        [12, 13, 14, 15].includes(captainRank.no) ? 'captain' :
        [16, 17, 18, 19, 20].includes(captainRank.no) ? 'admiral' : ''
    ;
    
    return (
        <div className="search-window__crew">
            <div className={'detailed-sailor__rank-info rank-info_' + rankName } />
            <div className="detailed-sailor__name-info">
                <span>{ crew.captain.name }</span>
                <span className="name-info__rating">({ getRating(crew.captain.rating).name })</span>
            </div>
            <div className="crew__sailors-amount">{crew.sailors.length+1}<span>чел.</span></div>
            <button onClick={joinToCrewHandler} className="crew__join-button" />
        </div>
    );
}

export default Crew;
