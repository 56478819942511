import React, { useEffect, useContext, useRef } from "react";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
import { useCalculateCoordinates, useRotatePoint, useCalculateSphericalIntersection } from "../../../../hooks";
import { MediatorContext } from "../../../../App";
import { TSceneUpdate, TGameSubmarine, TTorpedo } from "../../../../services/server/types";
import { TSubmarineControlPanel } from "../ControlPanel";
import { ThreeCanvas, TCanvasPoint } from "../../../../services/three";
import CONFIG from "../../../../config";

import './Sonar.scss';

export type TSubmarineSonar = TSubmarineControlPanel & {
    width?:number,
    height?:number,
    isTorpedoModifiedSonar?: boolean;
}

const CANVAS_FIELD = 'sonar-field';

const Sonar: React.FC<TSubmarineSonar> = (props: TSubmarineSonar) => {
    const { submarine, equipment, width, height, isTorpedoModifiedSonar = false } = props;
    const { BASE } = CONFIG.GAME;
    const { WEAPON_RANGE, OBJECTS_3D } = CONFIG.STORAGE;
    const mediator = useContext(MediatorContext);
    const sonarWrapperRef = useRef<HTMLDivElement>(null);
    const sonarOverlayRef = useRef<HTMLDivElement>(null);
    const rotatePoint = useRotatePoint();
    const calculateCoords = useCalculateCoordinates();
    const calculateSphericalIntersection = useCalculateSphericalIntersection();

    const { GET_SCENE, GET_STORAGE } = mediator.getTriggerTypes();
    let canvasScene: ThreeCanvas|null = null;
    let sceneSubmarines: string[] = [];
    let sceneTorpedoes: string[] = [];
    let sceneBots: string[] = [];

    // берём или дальность радара или дальность торпед. Им всё равно бОльшее значение ни к чему
    const locatorRange = equipment.properties?.['locator range']?.value || equipment.properties?.['weapon range']?.value;
    const weaponRange = Number(mediator.get<number>(GET_STORAGE, WEAPON_RANGE));
    const objects3d = mediator.get(GET_STORAGE, OBJECTS_3D) as Map<string, GLTF>;

    useEffect(() => {
        const sonarRange = Number(locatorRange);
        let sonarWidth = 300;
        let sonarHeight = 300;

        if (width && height) { // если не задали ширину и высоту сонара, подстроится сам
            sonarHeight = height;
            sonarWidth = width;
        } else { // немного адаптива у сонара
            const sonarWrapper = sonarWrapperRef.current;
            const sonarOvelay = sonarOverlayRef.current;
            if (sonarWrapper && sonarOvelay) {
                const rect = sonarWrapper.getBoundingClientRect();
                const width = Math.floor(rect.width);
                const height = Math.floor(rect.height);
                if (height < width) { // под высоту
                    sonarHeight = height;
                    sonarWidth = height;
                    sonarOvelay.style.width = `${height}px`;
                    sonarOvelay.style.height = `${height}px`;
                } else {                // под ширину
                    sonarHeight = width;
                    sonarWidth = width;
                    sonarOvelay.style.width = `${width}px`;
                    sonarOvelay.style.height = `${width}px`;
                }
            }
        }

        canvasScene = new ThreeCanvas({
            parentId: CANVAS_FIELD,
            WIDTH: sonarWidth,
            HEIGHT: sonarHeight,
            sonarRange,
            weaponRange,
            baseRadius: BASE.r,
            models: objects3d
        });

        canvasScene.render(render); // запуск рендера

        return () => {
            canvasScene?.destructor();
        }
    }, []);

    function render(): void {
        const sonarOverlay = sonarOverlayRef.current;

        if (canvasScene && sonarOverlay) {
            const scene = mediator.get<TSceneUpdate>(GET_SCENE);
            if (scene) {
                let trackedTarget: TTorpedo|TGameSubmarine|undefined;
                let isTorpedoExists: boolean = false;

                /** выбор таргета, за которым следим(лодка/торпеда) **/
                if (isTorpedoModifiedSonar) {
                    // ставим за точку отсчета торпеду
                    trackedTarget = scene.torpedoes.find(torpedoes => torpedoes.submarineGuid === submarine.guid) as TTorpedo;
                    if (trackedTarget) {
                        isTorpedoExists = true;
                    } else {
                        // если торпеды нет, то субмарину юзера
                        trackedTarget = scene.submarines.find(_submarine => _submarine.guid === submarine.guid) as TGameSubmarine;
                        submarine.course = trackedTarget?.course||0;
                    }
                } else { // если играем за водилу
                    trackedTarget = scene.submarines.find(_submarine => _submarine.guid === submarine.guid) as TGameSubmarine;
                    submarine.course = trackedTarget?.course||0;
                }
                /*****************************/
                
                if (trackedTarget) {
                    // рисуем лодку/торпеду юзера
                    isTorpedoModifiedSonar && isTorpedoExists ?
                        canvasScene.drawUserTorpedo(trackedTarget.course, (trackedTarget as TTorpedo).range) // торпеда
                    :   canvasScene.drawUserSubmarine(trackedTarget.course, trackedTarget.level); // лодка

                    //// Рисуем базу
                    const base = calculateSphericalIntersection(BASE, trackedTarget, locatorRange);
                    if (base && canvasScene) {
                        canvasScene.drawBase(base, BASE.guid);
                    } else if (canvasScene) {
                        const rotationAngel = -trackedTarget.course / 180 * Math.PI;
                        const dx = BASE.x - trackedTarget.x;
                        const dy = BASE.y - trackedTarget.y;

                        const rotatedPoint = rotatePoint({ x: dx, y: dy, z: 0 }, { angleZ: rotationAngel });

                        canvasScene.drawBaseFlag(rotatedPoint);
                        // пока так, когда на сцене будет больше одной базы, тогда изменю
                        canvasScene.removeNeedlessItems(new Set(), 'base');
                    }

                    // Рисуем вражескую
                    //const enemyBase = calculateSphericalIntersection(ENEMY_BASE, trackedTarget, locatorRange);
                    //if (enemyBase && canvasScene) {
                    //    canvasScene.drawBase(enemyBase, ENEMY_BASE.r, true);
                    //}

                    // Рисуем взрывы
                    scene.booms.forEach(boom => {
                        const rotatedPoint = trackedTarget ? // потому что TS срал на проверку trackedTarget
                            calculateCoords(boom, trackedTarget, locatorRange) 
                        : null;

                        if (rotatedPoint && canvasScene) {
                            const { timestamp, guid } = boom;
                            canvasScene.drawBoom(guid, rotatedPoint, timestamp);
                        }
                    });

                    //// рисуем лодки
                    scene.submarines.forEach(_submarine => {
                        if (submarine.guid === _submarine.guid && !isTorpedoExists) { // поворачиваем поле при развороте субмарины юзера
                            if (canvasScene && Math.abs(_submarine.speed) > 0) { 
                                canvasScene.rotateSonarField(_submarine.courseSteer/180*Math.PI);
                            }
                            return; // не происходит отрисовка самой субмарины юзера
                        }

                        if (trackedTarget) {
                            // точка поворота
                            const rotatedPoint = calculateCoords(_submarine, trackedTarget, locatorRange) as TCanvasPoint;

                            if (rotatedPoint && canvasScene) {
                                const { course, pitch, level, guid } = _submarine;

                                canvasScene.drawSubmarine({
                                    point: rotatedPoint,
                                    course,
                                    pitch,
                                    level,
                                    guid,
                                });

                                const screenCoordinates = canvasScene.getScreenCoordinates(guid, 'submarine');

                                // добавление имени пользователя на экран сонара
                                const tagName = sonarOverlay.querySelector('.user_'+guid) as HTMLSpanElement;
                                if (screenCoordinates && guid !== submarine.guid) {
                                    if (tagName) {
                                        const halfWidth = tagName.getBoundingClientRect().width / 2;
                                        const xCoord = Number(screenCoordinates.x) - halfWidth;
                                        tagName.style.setProperty('transform', `translate(${xCoord}px, ${screenCoordinates.y}px)`);
                                    } else {
                                        const newTagName = document.createElement('span');
                                        newTagName.classList.add('sonar__user-name');
                                        newTagName.classList.add('user_'+guid);
                                        newTagName.innerHTML = _submarine.captain.name;
                                        sonarOverlay.appendChild(newTagName);
                                    }

                                    sceneSubmarines.push(guid);
                                }
                                if (guid === submarine.guid && isTorpedoExists) {
                                    sceneSubmarines.push(guid);
                                }
                            }
                        }
                    });

                    const submarinesSet = new Set(sceneSubmarines);
                    // удаление имен пользователей с экарана сонара
                    sonarOverlay.querySelectorAll('.sonar__user-name').forEach(child => {
                        const guid = child.classList[1].split('_')[1];
                        if (!submarinesSet.has(guid)) {
                            child.remove();
                        }
                    });

                    canvasScene.removeNeedlessItems(submarinesSet, 'submarine');
                    sceneSubmarines = [];

                    //// рисуем торпеды
                    scene.torpedoes.forEach(torpedo => {
                        if (submarine.guid === torpedo.submarineGuid && isTorpedoExists) { // поворачиваем поле при развороте торпеды юзера
                            if (canvasScene) { 
                                canvasScene.rotateSonarField(torpedo.courseSteer/180*Math.PI);
                            }
                            return; // не происходит отрисовка самой торпеды юзера
                        }

                        if (trackedTarget) {
                            const rotatedPoint = calculateCoords(torpedo, trackedTarget, locatorRange);

                            if (rotatedPoint && canvasScene) {
                                const { course, pitch, guid } = torpedo;
                                
                                canvasScene.drawTorpedo({
                                    point: rotatedPoint,
                                    guid,
                                    course,
                                    pitch,
                                });

                                sceneTorpedoes.push(guid);
                            }
                        }
                    });

                    canvasScene.removeNeedlessItems(new Set(sceneTorpedoes), 'torpedo');
                    sceneTorpedoes = [];

                    //// рисуем ботов
                    scene.bots.forEach(bot => {
                        if (trackedTarget) {
                            const rotatedPoint = calculateCoords(bot, trackedTarget, locatorRange) as TCanvasPoint;

                            if (rotatedPoint && canvasScene) {
                                const { course, pitch, level } = bot;
                                const guid = bot.guid.toString();

                                canvasScene.drawBot({
                                    guid: guid,
                                    point: rotatedPoint,
                                    level,
                                    pitch,
                                    course
                                });

                                sceneBots.push(guid);
                            }
                        }
                    });

                    canvasScene.removeNeedlessItems(new Set(sceneBots), 'bot');
                    sceneBots = [];
                }
            }
        }
    }
    
    return (
        <div 
            className="control-panel__sonar"
            ref={sonarWrapperRef}
        >
            <div id={CANVAS_FIELD} />
            <div ref={sonarOverlayRef} className="sonar__canvas-overlay">
                <div className="canvas-overlay__sonar-range">{ locatorRange }</div>
            </div>
        </div>
    );
}

export default Sonar;