import React, { useEffect, useContext } from "react";
import { ServerContext, MediatorContext } from "../../App";
import { TGameSubmarine, TSailor } from "../../services/server/types";
import CONFIG from "../../config";
import { useGetUser } from "../../hooks";
import { IBasePage, PAGES } from "../PageManager";
import InteriorGame from '../../game/InteriorGame';
import InteriorCanvas from "./InteriorCanvas/InteriorCanvas";
import WorldCanvas from "./WorldCanvas/WorldCanvas";
import ControlPanel from "./ControlPanel/ControlPanel";

import './Game.scss';

const Game: React.FC<IBasePage> = (props: IBasePage) => {
    const { setPage } = props;
    const mediator = useContext(MediatorContext);
    const server = useContext(ServerContext);
    const { AMMO_AMOUNT, WEAPON_RANGE } = CONFIG.STORAGE;
    const { GET_SUBMARINE, SET_STORAGE } = mediator.getTriggerTypes();
    const { SUBMARINE_INIT, SAILOR_CANCEL, SUBMARINE_CANCEL, LEAVE_SAILOR_POPUP } = mediator.getEventTypes();
    const getUser = useGetUser(mediator);
    let game: InteriorGame | null = null;

    useEffect(() => {
        const user = getUser();

        const submarineInitHandler = (submarine: TGameSubmarine) => {

            //console.log('submarine', submarine);

            // получить изначальное кол-во торпед и их дальность
            // TODO нужен более надежный способ нахождения вооружения
            const ammoAmount = submarine.equipments.find(equipment => Number(equipment.slot) === 1)?.properties['ammo'].value;
            const weaponRange = submarine.equipments.find(equipment => Number(equipment.slot) === 1)?.properties['weapon range'].value;
            
            mediator.get(SET_STORAGE, { NAME: AMMO_AMOUNT, value: ammoAmount });
            mediator.get(SET_STORAGE, { NAME: WEAPON_RANGE, value: weaponRange });

            game = new InteriorGame({ server, mediator, submarine, user });
        }
        const submarineCancelHandler = () => setPage(PAGES.LOBBY);
        const sailorCancelHandler = (data: TSailor) => {
            const { guid } = data;
            if (guid === user?.guid) { // я сам вышел из команды, или меня вышли
                setPage(PAGES.LOBBY);
                return;
            }
            mediator.call(LEAVE_SAILOR_POPUP, data); // показать грустный попапчик, что морячок ушёл из команды
            // выпилить его из команды субмарины, чтобы не отображался более
            const submarine = mediator.get<TGameSubmarine>(GET_SUBMARINE);
            if (submarine) {
                const index = submarine.sailors.findIndex(sailor => sailor.guid === guid)
                if (index > -1) {
                    submarine.sailors.splice(index, 1);
                }
            }
        }

        mediator.subscribe(SUBMARINE_INIT, submarineInitHandler);
        mediator.subscribe(SUBMARINE_CANCEL, submarineCancelHandler);
        mediator.subscribe(SAILOR_CANCEL, sailorCancelHandler);

        return () => {
            game?.destructor();
            mediator.unsubscribe(SUBMARINE_INIT, submarineInitHandler);
            mediator.unsubscribe(SUBMARINE_CANCEL, submarineCancelHandler);
            mediator.unsubscribe(SAILOR_CANCEL, sailorCancelHandler);
        }
    });

    return (<div className="game">
        <ControlPanel />
        <InteriorCanvas />
        <WorldCanvas />
    </div>);
}

export default Game;