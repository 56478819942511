import { useEffect } from 'react';

type size = 'width'|'height';

const useIsAspectRaito = (classname: string, heir: size, inheritor: size): void => {
    useEffect(() => {
        if (!('aspectRatio' in document.body.style)) {
            document.querySelectorAll<HTMLDivElement>(classname).forEach(item => {
                console.log(item)
                item.style[heir] = item.getBoundingClientRect()[inheritor]+'px';
            });
        }
    }, []);
}

export default useIsAspectRaito;