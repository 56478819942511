import React from "react";
import { PAGES, IBasePage } from "../../PageManager";

import './ExtraButtons.scss';

interface IExtraButton {
    routeHandler: () => void;
    title?: string;
    classes?: string;
}

const ExtraButton: React.FC<IExtraButton> = (props: IExtraButton) => {
    const { routeHandler, title = '', classes = '' } = props;

    return(
        <button 
            className={'lobby__extra-button ' + classes}
            onClick={routeHandler}
        >
            <div />
            <span>{ title }</span>
        </button>
    )
}

const ExtraButtons: React.FC<IBasePage> = (props: IBasePage) => {
    const { setPage } = props;

    return(
        <div className="lobby__extra-buttons-wrapper">
            <ExtraButton
                routeHandler={() => setPage(PAGES.SHOP)} 
                title="Магазин"
                classes="extra-button_shop"
            />
            <ExtraButton 
                routeHandler={() => setPage(PAGES.TRAINING)}
                title="Обучение"
                classes="extra-button_training"
            />
            <ExtraButton 
                routeHandler={() => setPage(PAGES.SETTINGS)}
                title="Настройки"
                classes="extra-button_settings"
            />
        </div>
    )
}

export default ExtraButtons;