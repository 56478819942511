import React, { useContext, useMemo } from "react";
import { MediatorContext } from "../../../../../../App";
import ModuleButton from "../../ControlPanel/ModuleButton/ModuleButton";
import CourseIndicator from "./CourseIndicator/CourseIndicator";

interface ICourse {
    course: number;
    maxCourseLevel: number;
    isFired?:boolean;
    isFireingPanel?:boolean;
}

const Course: React.FC<ICourse> = (props: ICourse) => {
    const { 
        course, 
        maxCourseLevel, 
        isFired, 
        isFireingPanel 
    } = props;

    const mediator = useContext(MediatorContext);
    const { USER_SET_COURSE_STEER } = mediator.getEventTypes();

    const courseIndicator = useMemo(() => <CourseIndicator />, []);

    const changeSubmarineCourse = (step: number) => {
        mediator.call(USER_SET_COURSE_STEER, { value: step, target:'submarine' });
    }

    const changeTorpedoCourse = (step: number) => {
        mediator.call(USER_SET_COURSE_STEER, { value: step, target:'torpedo' });
    }

    return (
        <div className={"control-module__course" + (isFireingPanel ? !isFired ? ' disabled' : '' : '')}>
            <ModuleButton
                changeValueFunction={
                    isFireingPanel ?
                        isFired ? changeTorpedoCourse : () => {} 
                    : changeSubmarineCourse
                }
                type="course"
                value={course}
                sign={1}
                limitValue={maxCourseLevel}
            />
            <ModuleButton
                changeValueFunction={
                    isFireingPanel ? 
                        isFired ? changeTorpedoCourse : () => {}
                    : changeSubmarineCourse
                }
                type="course"
                value={course}
                sign={-1}
                limitValue={maxCourseLevel}
            />
            { courseIndicator }
        </div>
    );
}

export default Course;