import React from "react";
import { TSubmarineControlPanel } from '../ControlPanel';
import Sonar from "../Sonar/Sonar";
//import SonarOld from "../SonarOld/SonarOld";
import ControlModule from "../ControlModule/ControlModule";
import FiringModule from "../FiringModule/FiringModule";
import Frame from "../Frame/Frame";

const Submarine2FirePanel: React.FC<TSubmarineControlPanel> = (props: TSubmarineControlPanel) => {
    const { submarine, equipment } = props;

    return (
        <div className="control-panel control-panel_fire-panel">
            <ControlModule submarine={submarine} isFireingPanel={true}/>
            <Sonar isTorpedoModifiedSonar={true} submarine={submarine} equipment={equipment}/>
            <div className="control-panel__action-module">
                <FiringModule 
                    isFireModule={true}
                    initAmmo={equipment.properties.ammo.value}
                />
            </div>
            <Frame />
        </div>
    );
}

export default Submarine2FirePanel;