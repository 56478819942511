import React, { useContext } from "react";
import { ServerContext } from "../../../../../App";
import UserInfoOld from "../../../../../components/UserInfoOld/UserInfoOld";
import InteractiveButton from "../../../../../components/InteractiveButton/InteractiveButton";
import { Exit } from "../../../../../components/svg";

import './Menu.scss';

const Menu: React.FC = () => {
    const server = useContext(ServerContext);

    const backHandler = () => server.submarineCancel();

    return (<div className="game-menu">
        <InteractiveButton classes="interactive-button_exit" svg={<Exit/>} onClick={backHandler}/>
        <UserInfoOld />
    </div>)
}

export default Menu;