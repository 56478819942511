import React from "react";
import { IControlModuleStats } from "../ControlModule";
import Course from "./Course/Course";
import Pitch from "./Pitch/Pitch";

import './ControlPanel.scss';

type IControlPanel = IControlModuleStats & { maxSteeringLevel: number; }

const ControlPanel: React.FC<IControlPanel> = (props: IControlPanel) => {
    const { 
        maxSteeringLevel, 
        pitch, 
        course, 
        isFireingPanel, 
        isFired 
    } = props;

    return (
        <div className="control-module__control-wrapper">
            <Course 
                course={course}
                maxCourseLevel={maxSteeringLevel}
                isFired={isFired}
                isFireingPanel={isFireingPanel}
            />
            <Pitch 
                pitch={pitch}
                maxPitchLevel={maxSteeringLevel}
                isFired={isFired}
                isFireingPanel={isFireingPanel}
            />
        </div>
    )
}

export default ControlPanel;