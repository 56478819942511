declare global {
    interface Document { 
        webkitFullscreenElement: Element | null; 
        mozFullScreenElement: Element | null;
        msFullscreenElement: Element | null;
        webkitExitFullscreen: () => Promise<void>;
        mozCancelFullScreen: () => Promise<void>;
        msExitFullscreen: () => Promise<void>;
    }

    interface HTMLElement {
        mozRequestFullScreen: () => Promise<void>;
        webkitRequestFullScreen: () => Promise<void>;
        msRequestFullscreen: () => Promise<void>;
    }
}

const useChangeScreenDisplay = (): Function => {
    function getFullScreenStatus() {
        return document?.fullscreenElement ||
            document?.webkitFullscreenElement ||
            document?.mozFullScreenElement ||
            document?.msFullscreenElement;
    }

    return (displayStatus: boolean): void => {
        try {
            const docElm = document.documentElement;
            const isInFullScreen = getFullScreenStatus();
            if (displayStatus) {
                if (!isInFullScreen) {
                    docElm?.requestFullscreen?.();
                    docElm?.mozRequestFullScreen?.();
                    docElm?.webkitRequestFullScreen?.();
                    docElm?.msRequestFullscreen?.();
                }
            } else {
                if (isInFullScreen) {
                    document?.exitFullscreen?.();
                    document?.webkitExitFullscreen?.();
                    document?.mozCancelFullScreen?.();
                    document?.msExitFullscreen?.();
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
}

export default useChangeScreenDisplay;