import React from 'react';

import Preloader from "./Preloader/Preloader";
import Lobby from "./Lobby/Lobby";
import Game from './Game/Game';
import NotFound from "./NotFound/NotFound";
import Shop from './Shop/Shop';
import Training from './Training/Training';
import Settings from './Settings/Settings';

export enum PAGES {
    PRELOADER,
    LOBBY,
    GAME,
    SHOP,
    TRAINING,
    SETTINGS,
    NOT_FOUND,
}

export interface IBasePage {
    setPage: (name: PAGES) => void
}

interface IPageManager extends IBasePage {
    page: PAGES;
}

const PageManager: React.FC<IPageManager> = (props: IPageManager) => {
    const { page, setPage } = props;

    return (
        <>
            {page === PAGES.PRELOADER && <Preloader setPage={setPage} />}
            {page === PAGES.LOBBY && <Lobby setPage={setPage} />}
            {page === PAGES.GAME && <Game setPage={setPage} />}
            {page === PAGES.SHOP && <Shop setPage={setPage} />}
            {page === PAGES.TRAINING && <Training setPage={setPage} />}
            {page === PAGES.SETTINGS && <Settings setPage={setPage} />}
            {page === PAGES.NOT_FOUND && <NotFound setPage={setPage} />}
        </>
    );
}


export default PageManager;