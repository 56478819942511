import React, { useEffect, useContext, useRef } from "react";
import { MediatorContext, ServerContext } from "../../App";
import { TCrew } from "../../services/server/types";
import { IBasePage, PAGES } from "../PageManager";
import { useGetUser } from "../../hooks";
import BoatList, { IBoatListHandle } from "./BoatList/BoatList";
import ExtraButtons from "./ExtraButtons/ExtraButtons";
import CrewInfo from "./CrewInfo/CrewInfo";

import './Lobby.scss';

const Lobby: React.FC<IBasePage> = (props: IBasePage) => {
    const { setPage } = props;
    const mediator = useContext(MediatorContext);
    const boatsRef = useRef<IBoatListHandle>(null);
    const server = useContext(ServerContext);
    const { START_GAME } = mediator.getEventTypes();
    const getUser = useGetUser(mediator);
    const user = getUser();

    useEffect(() => {
        server.crewsList();

        const startGameHandler = (data: TCrew) => {
            if (data.guid && data.captain) {
                setPage(PAGES.GAME);
            }
        }

        mediator.subscribe(START_GAME, startGameHandler);

        return () => {
            mediator.unsubscribe(START_GAME, startGameHandler);
        }
    });

    // начать игру
    const startGameHandler = (button: HTMLButtonElement|null): void => {
        if (boatsRef.current && button) {
            const boatList = boatsRef.current.getBoatListRef();
            if (boatList) {
                const boats = Array.from(boatList.childNodes as NodeListOf<HTMLDivElement>);

                // выбрана ли хотя бы одна лодка
                const isCheked = boats.some(submarineWrapper => {
                    const submarineCheckBox = submarineWrapper.querySelector('input') as HTMLInputElement;

                    if (submarineCheckBox && submarineCheckBox.checked) {
                        server.startGame(Number(submarineCheckBox.value));
                        return true;
                    }
                    return false;
                });

                // моргание
                if (!isCheked) {
                    button.disabled = true; // чтоб не спамили
                    boats.forEach(submarineWrapper => {
                        const interval = setInterval(() => {
                            submarineWrapper.classList.contains('selector-wrapper_animated') ?
                                submarineWrapper.classList.remove('selector-wrapper_animated') :
                                submarineWrapper.classList.add('selector-wrapper_animated')
                            ;
                        }, 100);

                        setTimeout(() => {
                            clearInterval(interval);
                            submarineWrapper.classList.remove('selector-wrapper_animated');
                        }, 500);
                    });

                    setTimeout(() => button.disabled = false, 500);
                }
            }
        }
    }

    return (
        <div className="lobby">
            <ExtraButtons setPage={setPage}/>
            <CrewInfo />
            <BoatList startHandler={startGameHandler} user={user} ref={boatsRef}/>
        </div>
    );
}

export default Lobby;