import React, { useState, useContext, useEffect } from "react";
import { MediatorContext } from "../../../../../../../App";
import { TSubmarine } from "../../../../../../../services/server/types";

import './CourseIndicator.scss';

const CourseIndicator: React.FC = () => {
    const mediator = useContext(MediatorContext);
    const [angle, setAngle] = useState<number>(0);
    const { UPDATE_GAME_SCENES } = mediator.getEventTypes();
    const { GET_SUBMARINE } = mediator.getTriggerTypes();

    useEffect(() => {
        mediator.subscribe(UPDATE_GAME_SCENES, updateCourseIndicator);

        return () => {
            mediator.unsubscribe(UPDATE_GAME_SCENES, updateCourseIndicator);
        }
    });

    const updateCourseIndicator = () => {
        const submarine = mediator.get(GET_SUBMARINE) as TSubmarine;
        setAngle(Number(submarine.course));
    }

    return (
        <div 
            className="control-module__course-indicator"
            style={{ transform: `rotateZ(${-angle}deg)` }}
        />
    );
}

export default CourseIndicator;