import Storage from './Storage';
import { TSTORAGE } from '../../config';
import { Mediator } from '../mediator';

type TUseStorage = {
    STORAGE: TSTORAGE,
    mediator: Mediator,
}

const useStorage = (options: TUseStorage) => {
    const { mediator, STORAGE } = options;
    const storage = new Storage(STORAGE);
    const { GET_STORAGE, SET_STORAGE, CLEAR_STORAGE } = mediator.getTriggerTypes();

    mediator.set(GET_STORAGE, (NAME) => NAME?.name ? storage.get(NAME.name) : null);
    mediator.set(SET_STORAGE, ({ NAME, value }) => NAME?.name && storage.set(NAME.name, value));
    mediator.set(CLEAR_STORAGE, (NAME) => NAME?.name && storage.clear(NAME.name));
}

export default useStorage;
