import React, { useEffect, useContext } from "react";
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MediatorContext } from "../../App";
import CONFIG from "../../config";
import { IBasePage, PAGES } from "../PageManager";

import './Preloader.scss';

const submarineLevel1 = require('../../assets/3dObjects/submarineLevel1.glb');
const submarineLevel2 = require('../../assets/3dObjects/submarineLevel2.glb');
const torpedo = require('../../assets/3dObjects/torpedo.glb');
const base = require('../../assets/3dObjects/base.glb');
const pointer = require('../../assets/3dObjects/pointer.glb');

const Preloader: React.FC<IBasePage> = (props: IBasePage) => {
    const { setPage } = props;
    const mediator = useContext(MediatorContext);
    const { GET_STORAGE, SET_STORAGE } = mediator.getTriggerTypes();
    const { IS_TRAINING_COMPLETED, OBJECTS_3D } = CONFIG.STORAGE;

    useEffect(() => {
        const isTrainingCompleted = mediator.get(GET_STORAGE, IS_TRAINING_COMPLETED);
        const loader = new GLTFLoader();

        const objects3d = [
            { name: 'submarineLevel1', model: submarineLevel1 },
            { name: 'submarineLevel2', model: submarineLevel2 },
            { name: 'torpedo', model: torpedo },
            { name: 'base', model: base },
            { name: 'pointer', model: pointer }
        ];

        const loadModels = objects3d.map(submarine => {
            const { name, model } = submarine;
            return loader.loadAsync(model).then(model => ({ name, model }));
        });

        // Подгрузка всех 3д моделей, потом переход с прелодера
        Promise.all(loadModels)
            .then(models => {
                const objects3d = new Map<string, GLTF>();
                models.forEach(({ name, model }) => {
                    switch(name) {
                        case 'pointer':
                            model.scene.scale.set(.25, .25, .25);
                        break;
                    }

                    objects3d.set(name, model);
                });
                // отправить все моделки в storage
                mediator.get(SET_STORAGE, { NAME: OBJECTS_3D, value: objects3d });

                //setPage(isTrainingCompleted ? PAGES.LOBBY : PAGES.TRAINING);
            })
            .catch(error => {
                console.log('Error: ', error);
            });
        setTimeout(() => setPage(isTrainingCompleted ? PAGES.LOBBY : PAGES.TRAINING), 3000);
    });

    return (
        <div className="preloader">
            <div>
                <div className="preloader__submarine" />
                <div className="preloader__dots" />
            </div>
            <span>Загрузка...</span>
            <section className="preloader__authors">
                <h1>Авторы:</h1>
                <div className="authors_name alex"><span>Трусов Алексей</span></div>
                <div className="authors_name maks"><span>Матвеев Максим</span></div>
                <div className="authors_name nekit"><span>Антонов Никита</span></div>
            </section>
        </div>
    );
}

export default Preloader;