import React, { useContext } from "react";
import { MediatorContext, ServerContext } from "../../../../../../../App";
import { useGetRank, useGetRating } from "../../../../../../../hooks";
import { TSailor, TUser } from "../../../../../../../services/server/types";

import './SailorDetailed.scss';

interface ISailorDetailed {
    sailor: TSailor;
    isCaptain?: boolean;
    user?: TUser|null;
    isKickButton?: boolean;
}

const SailorDetailed: React.FC<ISailorDetailed> = (props: ISailorDetailed) => {
    const { sailor, isCaptain, user, isKickButton = false } = props;
    const mediator = useContext(MediatorContext);
    const server = useContext(ServerContext);
    const getRank = useGetRank(mediator);
    const getRating = useGetRating(mediator);

    const dropFromCrewHandler = () => server.dropFromCrew({ dropGuid: sailor.guid });

    const rank = getRank(sailor.rank);

    const rankName = [1, 2].includes(rank.no) ? 'sailor' :
        [3, 4, 5, 6].includes(rank.no) ? 'foreman' :
        [7, 8].includes(rank.no) ? 'midshipman' :
        [9, 10, 11].includes(rank.no) ? 'lieutenant' :
        [12, 13, 14, 15].includes(rank.no) ? 'captain' :
        [16, 17, 18, 19, 20].includes(rank.no) ? 'admiral' : ''
    ;

    const captainClass = isCaptain ? 'captain' : '';
    const userClass = user?.guid === sailor.guid ? 'user' : '';

    return (
        <div className={`wide-crew-list__detailed-sailor detailed-sailor_${captainClass} detailed-sailor_${userClass}`}>
            <div className={'detailed-sailor__rank-info rank-info_' + rankName } />
            <div className="detailed-sailor__name-info">
                <span>{ sailor.name }</span>
                <span className="name-info__rating">({ getRating(sailor.rating).name })</span>
            </div>
            { 
                isKickButton &&
                !isCaptain &&
                <button className="detailed-sailor__kick-button" onClick={dropFromCrewHandler}/>
            }
        </div>
    );
}

export default SailorDetailed;