import * as THREE from 'three';

interface IVisualHelper {
    subject: THREE.Group<THREE.Object3DEventMap>;
    isIntersectionCircle?: boolean;
}

class VisualHelper {
    private subject;
    private line;
    private pointer;
    private intersectionCircle;

    constructor(options: IVisualHelper) {
        const { subject, isIntersectionCircle } = options;

        this.subject = subject;

        const color = 0xffffff;
        const pointerRadius = 2;
        
        const lineMaterial = new THREE.LineBasicMaterial({ color });
        const lineGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 0)]);
        this.line = new THREE.Line(lineGeometry, lineMaterial);

        const pointerMaterial = new THREE.MeshBasicMaterial({ color, side: THREE.DoubleSide });
        const pointerGeometry = new THREE.RingGeometry(pointerRadius, pointerRadius+3, 16);
        this.pointer = new THREE.Mesh(pointerGeometry, pointerMaterial);
        this.pointer.rotateX(Math.PI/2);

        this.intersectionCircle = null;
        if (isIntersectionCircle) {
            const intersectionCircleMaterial = new THREE.MeshBasicMaterial({ color:0xffffff, side: THREE.DoubleSide });
            const intersectionCircleGeometry = new THREE.RingGeometry(1, 1.015, 24);
            this.intersectionCircle = new THREE.Mesh(intersectionCircleGeometry, intersectionCircleMaterial);
            this.intersectionCircle.rotateX(Math.PI/2);
            this.subject.add(this.intersectionCircle);
        }

        this.subject.add(this.line);
        this.subject.add(this.pointer);
    }

    destructor() {
        this.subject.remove(this.line);
        this.subject.remove(this.pointer);
        this.line.geometry.dispose();
        this.line.material.dispose();
        this.pointer.geometry.dispose();
        this.pointer.material.dispose();
        if (this.intersectionCircle) {
            this.subject.remove(this.intersectionCircle);
            this.intersectionCircle.geometry.dispose();
            this.intersectionCircle.material.dispose();
        }
    }

    public update(y: number, intersectionRadius?: number): void {
        this.pointer.position.setY(-y);
        this.line.geometry.attributes.position.array[4] = -y;
        this.line.geometry.attributes.position.needsUpdate = true;

        if (this.intersectionCircle && intersectionRadius) {
            this.intersectionCircle.scale.set(intersectionRadius, intersectionRadius, 1);
            this.intersectionCircle.position.setY(-y);
        }
    }
}

export default VisualHelper;