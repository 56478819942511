import React, { useContext } from "react";
import { MediatorContext } from "../../../../../../App";
import { useGetRank } from "../../../../../../hooks";
import { TSailor, TUser } from "../../../../../../services/server/types";
import './Sailor.scss';

export interface ISailor {
    sailor: TSailor;
    isCaptain?: boolean;
    user: TUser | null;
}

const Sailor: React.FC<ISailor> = (props: ISailor) => {
    const { sailor, isCaptain = false, user } = props;
    const mediator = useContext(MediatorContext);
    const getRank = useGetRank(mediator);

    return (
        <div className={`crew-list__sailor ${isCaptain ? 'sailor__captain' : ''} ${user?.guid === sailor.guid ? 'sailor_user' : ''}`}>
            {getRank(sailor.rank).name}
        </div>
    );
}

export default Sailor;