import React, { useMemo } from "react";
import { IControlModuleStats } from "../ControlModule";
import Depth from "./Depth/Depth";
import Angles from "./Angles/Angles";

import './InfoPanel.scss';

const InfoPanel: React.FC<IControlModuleStats> = (props: IControlModuleStats) => {
    const { pitch, course, isFired, isFireingPanel } = props;

    const depthMeter = useMemo(() => <Depth />, []);

    return(
        <div className="control-module__info">
            <Angles
                pitch={pitch}
                course={course}
                isFired={isFired}
                isFireingPanel={isFireingPanel}
            />
            { depthMeter }
        </div>
    );
}

export default InfoPanel;