import React, { useState, useEffect, useContext } from 'react';
// @ts-ignore
import useScreenOrientation from 'react-hook-screen-orientation';

import CONFIG from './config';

import useServer, { type Server } from './services/server';
import useMediator, { type Mediator } from './services/mediator';
import useStorage from './services/storage/useStorage';
import useChangeScreenDisplay from './hooks/useChangeScreenDisplay';
import PageManager, { PAGES } from './pages/PageManager';
import { ErrorPopup, LeaveSailorPopup, GameMessagePopup } from './components/popups';

import './App.scss';

export const MediatorContext = React.createContext<Mediator>(null!);
export const ServerContext = React.createContext<Server>(null!);

const MainApp: React.FC = () => {
    const server = useContext(ServerContext);
    const mediator = useContext(MediatorContext);
    const { GET_STORAGE } = mediator.getTriggerTypes();
    const { IS_TRAINING_COMPLETED } = CONFIG.STORAGE;
    const screenOrientation = useScreenOrientation();
    const changeScreenDisplay = useChangeScreenDisplay();
    const [page, setPage] = useState<PAGES>(PAGES.PRELOADER);
    const [orientation, setOrientation] = useState(screenOrientation);

    useEffect(() => {
        if (orientation === 'portrait-primary') {
            server.submarineCancel();
            const isTrainingCompleted = mediator.get(GET_STORAGE, IS_TRAINING_COMPLETED);
            setPage(isTrainingCompleted ? PAGES.LOBBY : PAGES.TRAINING);
        }

        const setFullHeight = () => {
            if (
                document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement
            ) {
                const fullHeight = window.innerHeight + 'px';
                document.body.style.height = fullHeight;
                document.documentElement.style.height = fullHeight;
                const app = document.querySelector('.app') as HTMLDivElement;
                app.style.height = fullHeight;
            }
        }

        const changeOrientation = () => {
            setOrientation(window.innerWidth / window.innerHeight > 1 ? 'landscape-primary' : 'portrait-primary');
            setFullHeight();
        }
        const fullScreenChange = () => document.fullscreenElement && setFullHeight();
        const webkitFullScreenChange = () => document.webkitFullscreenElement && setFullHeight();
        const mozFullScreenChange = () => document.mozFullScreenElement && setFullHeight();
        const MSFullScreenChange = () => document.msFullscreenElement && setFullHeight();

        window.addEventListener('resize', changeOrientation, false);
        window.addEventListener('fullscreenchange', fullScreenChange, false);
        window.addEventListener('webkitfullscreenchange', webkitFullScreenChange, false);
        window.addEventListener('mozfullscreenchange', mozFullScreenChange, false);
        window.addEventListener('MSFullscreenChange', MSFullScreenChange, false);

        return () => {
            window.removeEventListener('resize', changeOrientation);
            window.removeEventListener('fullscreenchange', fullScreenChange);
            window.removeEventListener('webkitfullscreenchange', webkitFullScreenChange);
            window.removeEventListener('mozfullscreenchange', mozFullScreenChange);
            window.removeEventListener('MSFullscreenChange', MSFullScreenChange);
        }
    });

    return (
        <div className='app'>
            {orientation === 'landscape-primary' ?
                <div onClick={() => changeScreenDisplay(true)}>
                    {/* заменить на true, чтобы вылетало в полноэкранный режим */}
                    <PageManager
                        page={page}
                        setPage={setPage}
                    ></PageManager>
                </div> :
                <div className="rotateContainer" onClick={() => changeScreenDisplay(true)}>
                    <img alt="Поверните экран" />
                </div>
            }
            <ErrorPopup />
            <LeaveSailorPopup />
            <GameMessagePopup />
        </div>
    );
}

const App: React.FC = () => {
    const { MESSAGES, MEDIATOR, HOST, STORAGE } = CONFIG;
    const mediator = useMediator(MESSAGES, MEDIATOR)();
    useStorage({ mediator, STORAGE }); // потому что сервер будет из локал стора токены подсасывать
    const server = useServer({ MESSAGES, HOST, STORAGE, mediator })();

    return (
        <MediatorContext.Provider value={mediator}>
            <ServerContext.Provider value={server}>
                <MainApp />
            </ServerContext.Provider>
        </MediatorContext.Provider>
    );
}

export default App;
