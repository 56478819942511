import React, { useContext } from "react";
import { MediatorContext } from "../../../../../../App";
import ModuleButton from "../ModuleButton/ModuleButton";

interface IPitch {
    pitch: number;
    maxPitchLevel: number;
    isFired?:boolean;
    isFireingPanel?:boolean;
}

const Pitch: React.FC<IPitch> = (props: IPitch) => {
    const { 
        pitch, 
        maxPitchLevel, 
        isFired, 
        isFireingPanel 
    } = props;

    const mediator = useContext(MediatorContext);
    const { USER_SET_PITCH_STEER } = mediator.getEventTypes();

    const changeSubmarinePitch = (step: number) => {
        mediator.call(USER_SET_PITCH_STEER, { value: step, target:'submarine' });
    }

    const changeTorpedoPitch = (step: number) => {
        mediator.call(USER_SET_PITCH_STEER, { value: step, target:'torpedo' });
    }

    return (
        <div className={"control-module__pitch" + (isFireingPanel ? !isFired ? ' disabled' : '' : '')}>
            <ModuleButton
                changeValueFunction={
                    isFireingPanel ? 
                        isFired ? changeTorpedoPitch : () => {} 
                    : changeSubmarinePitch
                }
                type="pitch"
                value={pitch}
                sign={-1}
                limitValue={maxPitchLevel}
            />
            <ModuleButton
                changeValueFunction={
                    isFireingPanel ? 
                        isFired ? changeTorpedoPitch : () => {} 
                    : changeSubmarinePitch
                }
                type="pitch"
                value={pitch}
                sign={1}
                limitValue={maxPitchLevel}
            />
        </div>
    )
}

export default Pitch;