const getPersonFrames = (SIZE = 64, offset = 0): number[][][][] => {
    return [
        // EDIRECTION.LEFT
        [
            // ESTATUS.WALK
            [
                [SIZE * 1 + offset, SIZE * 0, SIZE],
                [SIZE * 2 + offset, SIZE * 0, SIZE],
                [SIZE * 3 + offset, SIZE * 0, SIZE],
                [SIZE * 4 + offset, SIZE * 0, SIZE],
                [SIZE * 5 + offset, SIZE * 0, SIZE],
                [SIZE * 6 + offset, SIZE * 0, SIZE],
                [SIZE * 7 + offset, SIZE * 0, SIZE],
                [SIZE * 8 + offset, SIZE * 0, SIZE],
            ],
            // ESTATUS.CLOSE
            [
                [SIZE * 0 + offset, SIZE * 3, SIZE],
                [SIZE * 1 + offset, SIZE * 3, SIZE],
                [SIZE * 2 + offset, SIZE * 3, SIZE],
                [SIZE * 3 + offset, SIZE * 3, SIZE],
            ],
            // ESTATUS.OPEN
            [
                [SIZE * 0 + offset, SIZE * 5, SIZE],
                [SIZE * 1 + offset, SIZE * 5, SIZE],
                [SIZE * 2 + offset, SIZE * 5, SIZE],
                [SIZE * 3 + offset, SIZE * 5, SIZE],
            ],
            // ESTATUS.USE
            [
                [SIZE * 0 + offset, SIZE * 7, SIZE],
                [SIZE * 1 + offset, SIZE * 7, SIZE],
                [SIZE * 2 + offset, SIZE * 7, SIZE],
                [SIZE * 3 + offset, SIZE * 7, SIZE],
                [SIZE * 4 + offset, SIZE * 7, SIZE],
                [SIZE * 5 + offset, SIZE * 7, SIZE],
            ],
        ],
        // EDIRECTION.RIGHT
        [
            // ESTATUS.WALK
            [
                [SIZE * 1 + offset, SIZE * 1, SIZE],
                [SIZE * 2 + offset, SIZE * 1, SIZE],
                [SIZE * 3 + offset, SIZE * 1, SIZE],
                [SIZE * 4 + offset, SIZE * 1, SIZE],
                [SIZE * 5 + offset, SIZE * 1, SIZE],
                [SIZE * 6 + offset, SIZE * 1, SIZE],
                [SIZE * 7 + offset, SIZE * 1, SIZE],
                [SIZE * 8 + offset, SIZE * 1, SIZE],
            ],
            // ESTATUS.CLOSE
            [
                [SIZE * 0 + offset, SIZE * 4, SIZE],
                [SIZE * 1 + offset, SIZE * 4, SIZE],
                [SIZE * 2 + offset, SIZE * 4, SIZE],
                [SIZE * 3 + offset, SIZE * 4, SIZE],
            ],
            // ESTATUS.OPEN
            [
                [SIZE * 0 + offset, SIZE * 6, SIZE],
                [SIZE * 1 + offset, SIZE * 6, SIZE],
                [SIZE * 2 + offset, SIZE * 6, SIZE],
                [SIZE * 3 + offset, SIZE * 6, SIZE],
            ],
            // ESTATUS.USE
            [
                [SIZE * 0 + offset, SIZE * 8, SIZE],
                [SIZE * 1 + offset, SIZE * 8, SIZE],
                [SIZE * 2 + offset, SIZE * 8, SIZE],
                [SIZE * 3 + offset, SIZE * 8, SIZE],
                [SIZE * 4 + offset, SIZE * 8, SIZE],
                [SIZE * 5 + offset, SIZE * 8, SIZE],
            ],
        ],
        // EDIRECTION.UP
        [
            [
                [SIZE * 0 + offset, SIZE * 2, SIZE],
                [SIZE * 1 + offset, SIZE * 2, SIZE],
                [SIZE * 2 + offset, SIZE * 2, SIZE],
                [SIZE * 3 + offset, SIZE * 2, SIZE],
            ]
        ],
        // EDIRECTION.DOWN
        [
            [
                [SIZE * 0 + offset, SIZE * 2, SIZE],
                [SIZE * 1 + offset, SIZE * 2, SIZE],
                [SIZE * 2 + offset, SIZE * 2, SIZE],
                [SIZE * 3 + offset, SIZE * 2, SIZE],
            ]
        ],
    ];
}

export default getPersonFrames;