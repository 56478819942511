import CONFIG from "../../config";
import { Mediator } from "../../services/mediator";
import { TSceneUpdate, TSubmarineShort, TTorpedo, TGameSubmarine } from "../../services/server/types";
import { TInteriorGame } from "../InteriorGame";

type TExteriorGame = Omit<TInteriorGame, 'user'>;

export default class Exterior {
    mediator: Mediator;
    submarine: TGameSubmarine;
    scene: TSceneUpdate;
    INTERIOR_TIMESTAMP: number;

    constructor(options: TExteriorGame) {
        const { mediator, submarine } = options;
        this.mediator = mediator;
        this.submarine = submarine;
        this.scene = {
            booms: [],
            snowflakes: [],
            submarines: [],
            torpedoes: [],
            bots: [],
        };
        const { INTERIOR_TIMESTAMP } = CONFIG.GAME;
        this.INTERIOR_TIMESTAMP = INTERIOR_TIMESTAMP;

        const { SCENE_UPDATE } = mediator.getEventTypes();
        mediator.subscribe(SCENE_UPDATE, (data: TSceneUpdate) => this.sceneUpdateHandler(data));
        const { GET_SCENE } = mediator.getTriggerTypes();
        mediator.set(GET_SCENE, () => this.get());
    }

    destructor(): void {
        const { SCENE_UPDATE } = this.mediator.getEventTypes();
        this.mediator.unsubscribeAll(SCENE_UPDATE);
    }

    /********************************/
    /* Обработчики сокетных событий */
    /********************************/
    sceneUpdateHandler(data: TSceneUpdate): void {
        this.scene.booms = this.scene.booms.concat(data.booms.map(boom => {
            boom.timestamp = 1000 + 500 * (boom.level - 1);
            return boom;
        }));
        this.scene.snowflakes = data.snowflakes;
        this.scene.submarines = data.submarines;
        this.scene.torpedoes = data.torpedoes;
        this.scene.bots = data.bots;
    }
    /********************************/

    /*****************************/
    /* Отправка событий в бекенд */
    /*****************************/
    //...
    /*****************************/

    // переместить лодку или торпеду
    // TODO дубликат с server\application\modules\game\BaseMoving.js
    _move(boat: TSubmarineShort | TTorpedo): number {
        // узлы перевести в км/ч, перевести в метры в миллисекунды и получить смещение лодки
        const r = boat.speed * 1.85 / 3600 * this.INTERIOR_TIMESTAMP; // метры
        // получить вектор движения лодки через сферические координаты
        const teta = boat.pitch / 180 * Math.PI; // θ
        const phi = boat.course / 180 * Math.PI; // φ
        // переместить лодку по вектору её движения, зная её текущее положение
        boat.x += r * Math.sin(teta) * Math.cos(phi);
        boat.y += r * Math.sin(teta) * Math.sin(phi);
        boat.z += r * Math.cos(teta);
        boat.z = boat.z < 0 ? 0 : boat.z; // потому что выше поверхности всплыть нельзя, ибо там - лёд
        return r;
    }

    // замедление изменение курса лодки, чтобы она совершала поворот на 180 градусов за 6 секунд
    // TODO дубликат с server\application\modules\game\BaseMoving.js
    changeCourseLag(courseSteer: number): number {
        return courseSteer * (this.INTERIOR_TIMESTAMP * 4) / 8000;
    }

    moveSubmarine(submarine: TSubmarineShort): void {
        if (submarine.speed === 0) {
            return;
        }
        submarine.course -= this.changeCourseLag(submarine.courseSteer);
        this._move(submarine);
        if (submarine.guid === this.submarine.guid) {
            this.submarine.z = submarine.z; // пока интересует только это
        }
    }

    moveTorpedo(torpedo: TTorpedo): void {
        torpedo.course -= this.changeCourseLag(torpedo.courseSteer);
        torpedo.range -= this._move(torpedo);
    }

    // передвинуть элементы сцены
    update(): void {
        // бумкнуть бумки из торпед
        for (let i = 0; i < this.scene.booms.length; i++) {
            this.scene.booms[i].timestamp -= this.INTERIOR_TIMESTAMP;
            if (this.scene.booms[i].timestamp <= 0) {
                this.scene.booms.splice(i, 1);
            }
        }
        // передвинуть торпеды
        this.scene.torpedoes.forEach(torpedo => this.moveTorpedo(torpedo));
        // передвинуть лодки
        this.scene.submarines.forEach(submarine => this.moveSubmarine(submarine));
    }

    get(): TSceneUpdate {
        return this.scene;
    }
}